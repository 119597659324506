import React from 'react';
import PropTypes from 'prop-types';

import { getCellRendererProps } from './getCellRendererProps';

const LinkRenderer = ({ value, ...other }) => {
  const linkProps = getCellRendererProps(other);
  const { href, label } = value;

  const cellLinkClass = 'ag-cell__link';

  return (
    <a className={cellLinkClass} href={href} {...linkProps}>
      {label}
    </a>
  );
};

LinkRenderer.propTypes = {
  /** Link href and label to render in a table cell */
  value: PropTypes.shape({
    href: PropTypes.string.isRequired,
    label: PropTypes.node.isRequired,
  }).isRequired,
};

export { LinkRenderer };
