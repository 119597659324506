import React from 'react';

import { Button, BUTTON_VARIANTS, IconButton } from 'lib/button';
import { IconToggle } from 'lib/icon-toggle';
import { removeObjectProperties } from 'lib/utilities';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { CARD_FOOTER_MAX_ALLOWED_BUTTONS } from '../constants';
import { CardGroup, CardRow } from '../elements';

const StyledCardFooter = styled(CardRow)`
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: auto;

  &:nth-child(1) {
    margin-top: ${({ theme }) => `-${theme.size.spacing.large.value}`};
  }
`;

const StyledCardFooterGroupLeft = styled(CardGroup)`
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  margin-right: auto;
  margin-top: auto;
  min-width: 0;
  padding-top: ${({ theme }) => theme.size.spacing.large.value};
`;

const StyledCardFooterGroupRight = styled(CardGroup)`
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: auto;
  margin-top: auto;
  min-width: 0;
  padding-top: ${({ theme }) => theme.size.spacing.large.value};
`;

const StyledIconToggle = styled(IconToggle)`
  margin-right: ${({ theme }) => theme.size.spacing.medium.value};
`;

const StyledIconButton = styled(IconButton)`
  margin-right: ${({ theme }) => theme.size.spacing.medium.value};
`;

const StyledButton = styled(Button)`
  margin-left: ${({ theme }) => theme.size.spacing.medium.value};
`;

const CardFooter = ({ buttonsProps, customFooterTag, iconButtonProps, iconToggleProps, ...other }) => {
  const showButtons = buttonsProps && buttonsProps.length > 0;
  const showIconToggle = !!iconToggleProps;
  const showIconButton = !!iconButtonProps;
  const showIconActionElement = showIconToggle || showIconButton;

  return (
    <StyledCardFooter as={customFooterTag} {...other}>
      {showIconActionElement && (
        <StyledCardFooterGroupLeft>
          {showIconToggle && <StyledIconToggle {...iconToggleProps} />}
          {showIconButton && <StyledIconButton {...iconButtonProps} />}
        </StyledCardFooterGroupLeft>
      )}
      {showButtons && (
        <StyledCardFooterGroupRight>
          {buttonsProps.slice(0, CARD_FOOTER_MAX_ALLOWED_BUTTONS).map((buttonProps, index) => {
            const { variant } = buttonProps;
            const buttonVariant = variant === BUTTON_VARIANTS.PRIMARY ? variant : BUTTON_VARIANTS.TEXT;
            const buttonPropsWithoutVariant = removeObjectProperties(buttonProps, 'variant');

            return (
              <StyledButton key={buttonProps.text || index} variant={buttonVariant} {...buttonPropsWithoutVariant} />
            );
          })}
        </StyledCardFooterGroupRight>
      )}
    </StyledCardFooter>
  );
};

const buttonPropsWithoutExcludedProperties = removeObjectProperties(Button.propTypes, 'variant');

CardFooter.propTypes = {
  /** Array of object of properties, which is applied to button component */
  buttonsProps: PropTypes.arrayOf(
    PropTypes.shape({
      ...buttonPropsWithoutExcludedProperties,
      /** Changes button style depending on variant */
      variant: PropTypes.oneOf([BUTTON_VARIANTS.TEXT, BUTTON_VARIANTS.PRIMARY]),
    })
  ),
  /** Ability to supply an optional custom footer element */
  customFooterTag: PropTypes.elementType,
  /** Object of properties, which is applied to iconButton component */
  iconButtonProps: PropTypes.shape(IconButton.propTypes),
  /** Object of properties, which is applied to iconToggle component  */
  iconToggleProps: PropTypes.shape(IconToggle.propTypes),
};

CardFooter.defaultProps = {
  buttonsProps: undefined,
  customFooterTag: undefined,
  iconButtonProps: undefined,
  iconToggleProps: undefined,
};

export { CardFooter };
