import React, { forwardRef, useCallback, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import Select from 'react-select';
import { ThemeContext } from 'styled-components';

import { InputErrorText, InputHelperText, INPUT_SIZES } from '../../input';
import { flatMap, useOnError } from '../../utilities';

import { DROPDOWN_VARIANTS, MULTI_OPTIONS_TYPE } from '../constants';
import {
  ClearIndicatorComponent,
  ControlComponent,
  DropdownIndicatorComponent,
  DropdownLabel,
  GroupComponent,
  GroupHeadingComponent,
  InputComponent,
  MenuComponent,
  MenuListComponent,
  MultiValueComponent,
  MultiValueRemoveComponent,
  NoOptionsMessageComponent,
  OptionComponent,
  PlaceholderComponent,
  SelectContainerComponent,
  SelectWrapper,
  SingleValueComponent,
  ValueContainerComponent,
} from '../elements';
import { DROPDOWN_MENU_ERRORS } from '../errors';

const BaseDropdownMenu = forwardRef(
  (
    {
      allOptionsGroupLabel,
      allSelectedLabel,
      className,
      clearButtonText,
      dataTestId,
      enableCustomValidation,
      errorMessage,
      fewSelectedLabel,
      hasError,
      helperText,
      hideGroupLabel,
      hideLabel,
      inputId,
      isDisabled,
      isMulti,
      isReadOnly,
      isRequired,
      isSearchable,
      label,
      maxMenuHeight,
      multiOptionsType,
      multiValueRemoveText,
      noResultsMessage,
      onBlur,
      onChange,
      onError,
      onFocus,
      options,
      selectAllLabel,
      showSelectedItemCheckmark,
      size,
      styles,
      value,
      variant,
      ...other
    },
    ref
  ) => {
    const themeContext = useContext(ThemeContext);
    const SELECT_ALL_VALUE = 'all';
    const SELECT_ALL_OPTION = {
      label: selectAllLabel,
      value: SELECT_ALL_VALUE,
    };

    const SELECT_ALL_GROUP = {
      label: selectAllLabel,
      options: [SELECT_ALL_OPTION],
    };

    const [hasBeenBlurred, setHasBeenBlurred] = useState(false);
    const [isFocused, setIsFocused] = useState(false);

    const isEmpty = !value || !value.toString().length;

    const isRequiredError = hasBeenBlurred && isRequired && isEmpty;

    const errors = [isRequiredError && DROPDOWN_MENU_ERRORS.REQUIRED].filter(Boolean);

    useOnError({
      errors,
      onError,
    });

    const updatedHasError = enableCustomValidation ? hasError : errors.length > 0;

    const isInvalid = !isDisabled && !isReadOnly && updatedHasError;

    const hasGroups = options.filter((option) => option.options).length > 0;
    const allOptions = hasGroups ? flatMap(options, (o) => o.options) : options;
    const allOptionsLength = allOptions.length;
    const isAllOptionsSelected = !isEmpty && value.length === allOptionsLength;
    const isSelectAllIndeterminate = !isEmpty && value.length > 0;
    const isMultiOptionsTypeCheckbox = multiOptionsType === MULTI_OPTIONS_TYPE.CHECKBOX;
    const isMultiOptionsTypeToken = multiOptionsType === MULTI_OPTIONS_TYPE.TOKEN;
    const isInlineVariant = variant === DROPDOWN_VARIANTS.INLINE;
    const showHelperText = !!helperText && !isInvalid && !isInlineVariant;
    const showErrorMessage = !!errorMessage && isInvalid && !isInlineVariant;
    const hasExtraPadding = variant === DROPDOWN_VARIANTS.DEFAULT && isSearchable && !isEmpty;

    const handleBlur = (event) => {
      setIsFocused(false);
      if (!hasBeenBlurred) {
        setHasBeenBlurred(true);
      }

      if (onBlur) {
        onBlur(event);
      }
    };

    const handleFocus = (event) => {
      setIsFocused(true);

      if (onFocus) {
        onFocus(event);
      }

      if (!isInvalid) {
        setHasBeenBlurred(false);
      }
    };

    const getMultiOptions = () => {
      if (hasGroups) {
        return [SELECT_ALL_GROUP, ...options];
      }
      const grouplessOptions = {
        label: allOptionsGroupLabel,
        options,
      };
      return [SELECT_ALL_GROUP, grouplessOptions];
    };

    const SelectContainer = useCallback((props) => <SelectContainerComponent selectContainerProps={props} />, []);

    const Control = useCallback(
      (props) => {
        const otherProps = {
          dataTestId,
          hasError: updatedHasError,
          hideLabel,
          inputId,
          isDisabled,
          isInvalid,
          isReadOnly,
          isRequired,
          label,
          size,
          variant,
        };

        return <ControlComponent {...otherProps} controlProps={props} />;
      },
      [
        dataTestId,
        updatedHasError,
        hideLabel,
        inputId,
        isInvalid,
        isDisabled,
        isReadOnly,
        isRequired,
        label,
        size,
        variant,
      ]
    );

    const DropdownIndicator = useCallback(
      (props) => (
        <DropdownIndicatorComponent dataTestId={dataTestId} dropdownIndicatorProps={props} variant={variant} />
      ),
      [dataTestId, variant]
    );

    const Menu = useCallback((props) => <MenuComponent dataTestId={dataTestId} {...props} />, [dataTestId]);

    const MenuList = useCallback(
      (props) => <MenuListComponent maxMenuHeight={maxMenuHeight} size={size} menuListProps={props} />,
      [maxMenuHeight, size]
    );

    const NoOptionsMessage = useCallback((props) => <NoOptionsMessageComponent {...props} />, []);

    const Option = useCallback(
      (props) => {
        const otherProps = {
          dataTestId,
          isAllOptionsSelected,
          isMulti,
          isMultiOptionsTypeCheckbox,
          isSelectAllIndeterminate,
          selectAllLabel,
          showSelectedItemCheckmark,
          size,
        };

        return <OptionComponent {...otherProps} optionProps={props} />;
      },
      [
        dataTestId,
        isAllOptionsSelected,
        isMulti,
        isMultiOptionsTypeCheckbox,
        isSelectAllIndeterminate,
        selectAllLabel,
        showSelectedItemCheckmark,
        size,
      ]
    );

    const ClearIndicator = useCallback(
      (props) => (
        <ClearIndicatorComponent
          clearButtonText={clearButtonText}
          clearIndicatorProps={props}
          dataTestId={dataTestId}
          isDisabled={isDisabled}
          isReadOnly={isReadOnly}
        />
      ),
      [clearButtonText, dataTestId, isDisabled, isReadOnly]
    );

    const handleOnChange = (selectedOptionsOrOption, event) => {
      if (!isMulti || !isMultiOptionsTypeCheckbox) {
        onChange(selectedOptionsOrOption);
        return;
      }

      if (event.option && event.option.value === SELECT_ALL_VALUE) {
        const items = isSelectAllIndeterminate ? [] : [...allOptions].filter((option) => !option.isDisabled);
        onChange(items);
        return;
      }

      const filteredItems = selectedOptionsOrOption.filter((option) => !option.isDisabled);

      onChange(filteredItems);
    };

    const handleIsOptionDisabled = (option) => {
      return option.isDisabled;
    };

    const GroupHeading = useCallback(
      (props) => {
        const otherProps = { hasGroups, hideGroupLabel, selectAllLabel };

        return <GroupHeadingComponent {...otherProps} groupHeadingProps={props} />;
      },
      [hasGroups, hideGroupLabel, selectAllLabel]
    );

    const Group = useCallback((props) => <GroupComponent groupProps={props} />, []);

    const Input = useCallback(
      (props) => {
        const otherProps = {
          dataTestId,
          inputId,
          isMulti,
          isRequired,
          isSearchable,
          showErrorMessage,
          showHelperText,
          size,
          variant,
        };

        return <InputComponent {...otherProps} inputProps={props} />;
      },
      [dataTestId, inputId, isMulti, isRequired, isSearchable, showErrorMessage, showHelperText, size, variant]
    );

    const Placeholder = useCallback(
      (props) => <PlaceholderComponent placeholderProps={props} size={size} variant={variant} />,
      [size, variant]
    );

    const SingleValue = useCallback(
      (props) => (
        <SingleValueComponent
          dataTestId={dataTestId}
          isSearchable={isSearchable}
          singleValueProps={props}
          size={size}
          variant={variant}
        />
      ),
      [dataTestId, isSearchable, size, variant]
    );

    const MultiValue = useCallback(
      (props) => {
        const otherProps = { isDisabled, isMulti, isMultiOptionsTypeCheckbox, isReadOnly, size };

        return <MultiValueComponent {...otherProps} multiValueProps={props} />;
      },
      [isDisabled, isMulti, isMultiOptionsTypeCheckbox, isReadOnly, size]
    );

    const MultiValueRemove = useCallback(
      (props) => (
        <MultiValueRemoveComponent
          dataTestId={dataTestId}
          multiValueRemoveText={multiValueRemoveText}
          multiValueRemoveProps={props}
        />
      ),
      [dataTestId, multiValueRemoveText]
    );

    const ValueContainer = useCallback(
      (props) => {
        const otherProps = {
          allSelectedLabel,
          dataTestId,
          fewSelectedLabel,
          isMulti,
          isMultiOptionsTypeCheckbox,
          isSearchable,
          size,
          variant,
        };

        return <ValueContainerComponent {...otherProps} valueContainerProps={props} />;
      },
      [allSelectedLabel, dataTestId, fewSelectedLabel, isMulti, isMultiOptionsTypeCheckbox, isSearchable, size, variant]
    );

    const selectInputHelperTextId = `input-${inputId}-help-text`;
    const selectInputErrorTextId = `input-${inputId}-error-text`;

    return (
      <SelectWrapper
        className={className}
        data-testid={dataTestId ? `${dataTestId}-wrapper` : undefined}
        variant={variant}
      >
        <DropdownLabel
          hasError={isInvalid && !isInlineVariant}
          hasExtraPadding={hasExtraPadding}
          hideLabel={isInlineVariant && hideLabel}
          htmlFor={inputId}
          isDisabled={isDisabled}
          isFocused={isFocused && !isInlineVariant}
          isReadOnly={isReadOnly}
          isRequired={isRequired}
          size={size}
          variant={variant}
        >
          {label}
        </DropdownLabel>
        <Select
          blurInputOnSelect={isMobile && !isMulti}
          closeMenuOnSelect={!isMulti}
          components={{
            ClearIndicator,
            Control,
            DropdownIndicator,
            Group,
            GroupHeading,
            Input,
            Menu,
            MenuList,
            MultiValue,
            MultiValueRemove,
            NoOptionsMessage,
            Option,
            Placeholder,
            SelectContainer,
            SingleValue,
            ValueContainer,
          }}
          hideSelectedOptions={isMulti && isMultiOptionsTypeToken}
          inputId={inputId}
          isClearable={isSearchable}
          isDisabled={isDisabled}
          isMulti={isMulti}
          isOptionDisabled={handleIsOptionDisabled}
          isReadOnly={isReadOnly}
          isRequired={isRequired}
          isSearchable={!isReadOnly && isSearchable}
          menuPlacement="auto"
          multiOptionsType={multiOptionsType}
          noOptionsMessage={() => noResultsMessage}
          onBlur={handleBlur}
          onChange={!isReadOnly && handleOnChange}
          onFocus={handleFocus}
          options={isMulti && isMultiOptionsTypeCheckbox ? getMultiOptions() : options}
          ref={ref}
          styles={{
            control: () => {},
            dropdownIndicator: () => {},
            group: () => {},
            groupHeading: () => {},
            indicatorSeparator: () => {},
            input: () => {},
            menu: () => {},
            menuList: () => {},
            multiValue: () => {},
            multiValueContainer: () => {},
            multiValueLabel: () => {},
            multiValueRemove: () => {},
            noOptionsMessage: () => {},
            option: () => {},
            placeholder: () => {},
            singleValue: () => {},
            valueContainer: () => {},
            ...styles,
          }}
          theme={(theme) => ({
            ...theme,
            ...themeContext,
          })}
          value={value}
          {...other}
        />
        {showHelperText && <InputHelperText id={selectInputHelperTextId} text={helperText} />}
        {showErrorMessage && !isDisabled && !isReadOnly && (
          <InputErrorText id={selectInputErrorTextId} text={errorMessage} />
        )}
      </SelectWrapper>
    );
  }
);

BaseDropdownMenu.propTypes = {
  /** Represents all options group label in multi select mode when options are not grouped */
  allOptionsGroupLabel: PropTypes.node,
  /** Label to be set to Input field if all options are selected */
  allSelectedLabel: PropTypes.node,
  /** Adds className to main wrapper */
  className: PropTypes.string,
  /** Text which is read for screen reader users */
  clearButtonText: PropTypes.node,
  /** Id value used for testing */
  dataTestId: PropTypes.string,
  /** If true, custom validation is being enabled instead of built in component validation */
  enableCustomValidation: PropTypes.bool,
  /** Message to be displayed when input is in error state */
  errorMessage: PropTypes.node,
  /** Label to be set to Input field if more than one but not all options selected */
  fewSelectedLabel: PropTypes.node,
  /** When true, input is in error state */
  hasError: PropTypes.bool,
  /** Text to be displayed as a helper text below the input field */
  helperText: PropTypes.node,
  /** If true, visually hides options group label */
  hideGroupLabel: PropTypes.bool,
  /** If true, visually hides label */
  hideLabel: PropTypes.bool,
  /** Unique identifier for input */
  inputId: PropTypes.string.isRequired,
  /** If true, component is disabled and value of it cannot be edited */
  isDisabled: PropTypes.bool,
  /** If true, enables multi select functionality */
  isMulti: PropTypes.bool,
  /** If true, component is in read only state, value cannot be edited */
  isReadOnly: PropTypes.bool,
  /** If true, asterisk will be shown on the input top right corner and input will become must to fill in the forms */
  isRequired: PropTypes.bool,
  /** If true, enables to do options search */
  isSearchable: PropTypes.bool,
  /** Label of the dropdown select */
  label: PropTypes.node,
  /** Sets maximum height of the menu before scrolling */
  maxMenuHeight: PropTypes.string,
  /** Sets the type of options display in multi select mode */
  multiOptionsType: PropTypes.oneOf(Object.values(MULTI_OPTIONS_TYPE)),
  /** Text which is read for screen reader users when button is focused */
  multiValueRemoveText: PropTypes.node,
  /** Text which is displayed when there are no available options */
  noResultsMessage: PropTypes.node,
  /** Callback to be called when dropdown component is being blurred */
  onBlur: PropTypes.func,
  /** Callback to be called on dropdown's option is being changed by user interaction */
  onChange: PropTypes.func.isRequired,
  /** Callback to be called when input validation fails */
  onError: PropTypes.func,
  /** Callback to be called when dropdown component is being focused */
  onFocus: PropTypes.func,
  /** Dropdown options. For correct data structure look into story examples or react-select documentation */
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  /** Text which is displayed instead Select all label */
  selectAllLabel: PropTypes.node,
  /** If true, shows selected item checkmark */
  showSelectedItemCheckmark: PropTypes.bool,
  /** Sets the size of the select input */
  size: PropTypes.oneOf(Object.values(INPUT_SIZES)),
  /** React-select styles object. Can be used to override certain dropdown parts styles */
  styles: PropTypes.shape({}),
  /** Current value of the dropdown */
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.shape({}),
    PropTypes.arrayOf(PropTypes.shape({})),
  ]),
  /** Changes dropdown style depending on variant */
  variant: PropTypes.oneOf(Object.values(DROPDOWN_VARIANTS)),
};

BaseDropdownMenu.defaultProps = {
  allOptionsGroupLabel: 'Dropdown Options',
  allSelectedLabel: 'All Selected',
  className: '',
  clearButtonText: 'Clear input',
  dataTestId: undefined,
  enableCustomValidation: false,
  errorMessage: '',
  fewSelectedLabel: 'Selected',
  hasError: false,
  helperText: '',
  hideGroupLabel: false,
  hideLabel: false,
  isDisabled: false,
  isMulti: false,
  isReadOnly: false,
  isRequired: false,
  isSearchable: false,
  label: '',
  maxMenuHeight: undefined,
  multiOptionsType: MULTI_OPTIONS_TYPE.CHECKBOX,
  multiValueRemoveText: 'Remove',
  noResultsMessage: 'No results',
  onBlur: () => {},
  onError: () => {},
  onFocus: () => {},
  selectAllLabel: 'Select All',
  showSelectedItemCheckmark: true,
  size: INPUT_SIZES.STANDARD,
  styles: {},
  value: undefined,
  variant: DROPDOWN_VARIANTS.DEFAULT,
};

export { BaseDropdownMenu };
