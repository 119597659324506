import React, { useEffect } from 'react';
import { getRem } from 'lib/core';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { STRENGTH_RULES, REGEX_EXPRESSIONS, MIN_ALLOWED_LENGTH } from '../constants';
import { StrengthLabel } from '../elements/StrengthLabel';

const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-direction: row;
  max-width: ${getRem(400)};
  flex-wrap: wrap;
`;
const StyledLeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: ${({ theme }) => theme.size.spacing.large.value};
`;
const StyledRightContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StrengthMeter = ({ onValueChange, value, ...others }) => {
  const hasGreaterThanSevenCharacters = value.length > MIN_ALLOWED_LENGTH;
  const hasUppercaseCharacter = !!value.match(REGEX_EXPRESSIONS.UPPERCASE);
  const hasLowercaseCharacter = !!value.match(REGEX_EXPRESSIONS.LOWERCASE);
  const hasSymbol = !!value.match(REGEX_EXPRESSIONS.SYMBOL);
  const hasNumber = !!value.match(REGEX_EXPRESSIONS.NUMBER);
  const isValueValid =
    hasGreaterThanSevenCharacters && hasUppercaseCharacter && hasLowercaseCharacter && hasSymbol && hasNumber;

  useEffect(() => {
    onValueChange(isValueValid);
  }, [isValueValid]);

  return (
    <StyledContainer {...others}>
      <StyledLeftContainer>
        <StrengthLabel isValid={hasGreaterThanSevenCharacters} label={STRENGTH_RULES[0]} {...others} />
        <StrengthLabel isValid={hasUppercaseCharacter} label={STRENGTH_RULES[1]} {...others} />
        <StrengthLabel isValid={hasLowercaseCharacter} label={STRENGTH_RULES[2]} {...others} />
      </StyledLeftContainer>
      <StyledRightContainer>
        <StrengthLabel isValid={hasNumber} label={STRENGTH_RULES[3]} {...others} />
        <StrengthLabel isValid={hasSymbol} label={STRENGTH_RULES[4]} {...others} />
      </StyledRightContainer>
    </StyledContainer>
  );
};

StrengthMeter.propTypes = {
  /** Input value for strength meter */
  value: PropTypes.string.isRequired,
  /** callback function which returns the strength of the given value */
  onValueChange: PropTypes.func.isRequired,
};
