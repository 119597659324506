import styled, { createGlobalStyle, css } from 'styled-components';

import {
  BREAKPOINTS,
  centerContent,
  getHexToRgb,
  getRem,
  transitionAnimation,
  typographyBody2,
  typographyCaption,
  truncateText,
  wordBreak,
} from '../../core';

import { TABLE_LAYOUT_OPTIONS } from '../constants';

const agDndGhostClass = '.ag-dnd-ghost';
const tableClass = '.cdk-rds-table';

/* eslint-disable max-len */
const checkboxUncheckedIcon =
  'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBmaWxsPSIjNWI1ZjY2IiBkPSJNMTkuMzk4IDV2MTRoLTE0VjVoMTR6bTAtMmgtMTRjLTEuMSAwLTIgLjktMiAydjE0YzAgMS4xLjkgMiAyIDJoMTRjMS4xIDAgMi0uOSAyLTJWNWMwLTEuMS0uOS0yLTItMnoiLz48L3N2Zz4=';
const checkboxCheckedIcon =
  'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBmaWxsPSIjMDA2Y2ZhIiBkPSJNMTkuMjM2IDNoLTE0Yy0xLjEgMC0yIC45LTIgMnYxNGMwIDEuMS45IDIgMiAyaDE0YzEuMSAwIDItLjkgMi0yVjVjMC0xLjEtLjktMi0yLTJ6Ii8+PHBhdGggZmlsbD0iI2ZmZiIgZD0iTTEwLjIzNiAxN2wtNS00LjgwOCAxLjQtMS4zNDYgMy42IDMuNDYyTDE3LjgzNiA3bDEuNCAxLjM0NnoiLz48L3N2Zz4=';
const checkboxIndeterminateIcon =
  'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBmaWxsPSIjMDA2Y2ZhIiBkPSJNMTkuMDc1IDNhMiAyIDAgMDEyIDJ2MTRhMiAyIDAgMDEtMiAyaC0xNGEyIDIgMCAwMS0yLTJWNWEyIDIgMCAwMTItMmgxNHptLTEgOGgtMTJ2MmgxMnYtMnoiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPjwvc3ZnPg==';
/* eslint-enable */

const checkboxIndicatorBackgroundStyles = (state) => css`
  .ag-checkbox-input-wrapper {
    &::after {
      ${state === 'hover' &&
      css`
        background-color: ${({ theme }) => theme.color.state.default.hover.value};
      `}

      ${state === 'pressed' &&
      css`
        background-color: ${({ theme }) => theme.color.state.default.pressed.value};
      `}
    }

    &.ag-checked,
    &.ag-indeterminate {
      &::after {
        ${state === 'hover' &&
        css`
          background-color: ${({ theme }) => theme.color.state.primary.hover.value};
        `}

        ${state === 'pressed' &&
        css`
          background-color: ${({ theme }) => theme.color.state.primary.pressed.value};
        `}
      }
    }
  }
`;

export const TableColumnDndContainer = createGlobalStyle`
    .ag-theme-balham${agDndGhostClass} {
        border: 0;
        border-radius: ${getRem(3)};
        box-shadow: ${({ theme }) => theme.elevation[4].value};
        flex-direction: row-reverse;
        padding: 0 0 0 ${getRem(11)};
        ${/* sc-selector */ agDndGhostClass}-icon {
            height: ${getRem(32)};
            margin-right: ${getRem(5)};
            opacity: 1;
        }

        ${/* sc-selector */ agDndGhostClass}-label {
            color: ${({ theme }) => theme.color.text.light.disabled.value};
            font-family: ${({ theme }) => theme.font.family.main.value};
            font-size: ${getRem(12)};
            font-weight: 400;
            padding-right: ${getRem(40)};
        }

        .ag-icon-arrows,
        .ag-icon-eye-slash,
        .ag-icon-left,
        .ag-icon-right {
            display: none;
        }
    }
`;

export const TableContainer = styled.div.withConfig({
  shouldForwardProp: (prop) => !['isMobile', 'tableLayout', 'hideBorder'].includes(prop),
})`
    && {
        background-color: ${({ theme }) => theme.color.additional.light.value};
        color: ${({ theme }) => theme.color.text.light.primary.value};
        display: flex;
        flex-direction: column;
        font-family: ${({ theme }) => theme.font.family.main.value};
        font-size: ${getRem(14)};
        width: 100%;

        ${tableClass}__checkbox {
            &--select-all {
                margin-left: ${getRem(1)};
            }
        }

        ${({ isMobile, theme }) =>
          isMobile &&
          css`
            ${typographyBody2(theme)};
          `};
        ${({ tableLayout }) =>
          tableLayout === TABLE_LAYOUT_OPTIONS.NORMAL &&
          css`
            height: 100%;
          `};

        @media ${BREAKPOINTS.L_MAX} {
            ${({ theme }) => typographyBody2(theme)};
        }

        .ag-layout-auto-height {
            min-height: ${getRem(100)};
        }

        .ag-root-wrapper {
            ${({ hideBorder }) =>
              !hideBorder
                ? css`
                    border-bottom: none;
                    border-color: ${({ theme }) => theme.color.gray[200].value};
                    border-top: none;
                  `
                : css`
                    border: none;
                  `}

        .ag-header {
            background-color: ${({ theme }) => theme.color.additional.light.value};
            border-bottom: 2px solid ${({ theme }) => theme.color.text.light.primary.value};

            .ag-pinned-left-header {
                overflow: visible;
            }
        }

        .ag-header-row {
            color: ${({ theme }) => theme.color.text.light.primary.value};
            font-size: ${getRem(12)};
            font-weight: 500;
        }

        .ag-pinned-left-header {
            border-right: 1px solid;
            border-right-color: ${({ theme }) => theme.color.gray[200].value};
        }

        .ag-pinned-right-header {
            border-left: 1px solid;
            border-left-color: ${({ theme }) => theme.color.gray[200].value};
        }

        .ag-row {
            border-color: ${({ theme }) => theme.color.gray[200].value};
            color: ${({ theme }) => theme.color.text.light.primary.value};
            min-height: ${getRem(32)};
            z-index: ${({ theme }) => theme.zIndex.tableRow.value};

            &:last-child {
                border-bottom-width: 0;
            }

            &.ag-row-focus {
                z-index: ${({ theme }) => theme.zIndex.tableRowFocused.value};
            }
        }

        .ag-row-odd {
            background-color: rgba(${({ theme }) => getHexToRgb(theme.color.additional.dark.value)}, 0.03);
        }

        .ag-row-hover {
            &::before {
                background-color: ${({ theme }) => theme.color.gray[600].value};
                content: '';
                height: 100%;
                left: 0;
                position: absolute;
                top: 0;
                width: ${({ theme }) => theme.size.spacing.small.value};
            }

            &:not(.ag-row-selected) {
                background-color: ${({ theme }) => theme.color.state.default.hover.value};
            }
        }

        .ag-row-selected {
            background-color: ${({ theme }) => theme.color.primary[50].value};

            &:not(.ag-row-first) {
                border-color: ${({ theme }) => theme.color.primary[200].value};

                & + .ag-row:not(.ag-row-first) {
                    border-top-color: ${({ theme }) => theme.color.primary[200].value};
                }
            }

            &:hover,
            &:focus {
                background-color: ${({ theme }) => theme.color.primary[200].value};

                &::before {
                    background-color: ${({ theme }) => theme.color.primary[500].value};
                }
            }
        }

        .ag-cell {
            align-items: center;
            display: inline-flex;
            font-family: ${({ theme }) => theme.typography.table.fontFamily.value};
            font-weight: ${({ theme }) => theme.font.weight.regular.value};
            overflow: visible;
            padding-left: ${({ theme }) => theme.size.spacing.large.value};
            padding-right: ${({ theme }) => theme.size.spacing.large.value};

            &__link {
                color: ${({ theme }) => theme.color.primary[500].value};
                font-weight: 500;
                text-decoration: none;
            }

            &__text {
                &--highlighted {
                    background-color: rgba(${({ theme }) => getHexToRgb(theme.color.additional.brand.value)}, 0.3);
                    font-weight: 700;
                    line-height: ${({ theme }) => theme.size.spacing.large.value};
                }

                &--overlay-text {
                    border: 1px dashed ${({ theme }) => theme.color.gray[400].value};
                    padding: ${({ theme }) => theme.size.spacing.large.value};
                    white-space: pre-wrap;
                }

                &--truncate {
                    ${truncateText}
                }

                &--truncate-wrap {
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

            &--checkbox {
                &:hover {
                    ${checkboxIndicatorBackgroundStyles('hover')};
                }

                &:active {
                    ${checkboxIndicatorBackgroundStyles('pressed')};
                }
            }

            &--numeric {
                font-family: ${({ theme }) => theme.font.family.alternate.value};
            }

            &--no-wrap {
                white-space: normal;
            }

            &.ag-cell-last-left-pinned:not(.ag-cell-range-right):not(.ag-cell-range-single-cell) {
                border-right: 1px solid;
                border-right-color: ${({ theme }) => theme.color.gray[200].value};
            }

            &.ag-cell-first-right-pinned:not(.ag-cell-range-left):not(.ag-cell-range-single-cell) {
                border-left: 1px solid;
                border-left-color: ${({ theme }) => theme.color.gray[200].value};
            }
        }

        .ag-numeric-cell {
            &--align-right {
                justify-content: flex-end;
            }
        }

        .ag-header-cell {
            font-weight: ${({ theme }) => theme.typography.table.fontWeight.value};
            font-family: ${({ theme }) => theme.typography.table.fontFamily.value};
            padding-bottom: ${({ theme }) => theme.size.spacing.medium.value};
            padding-left: ${({ theme }) => theme.size.spacing.large.value};
            padding-right: ${({ theme }) => theme.size.spacing.large.value};
            padding-top: ${getRem(10)};

            &.ag-header-cell-moving {
                background-color: ${({ theme }) => theme.color.state.default.disabled.value};
                color: ${({ theme }) => theme.color.text.light.disabled.value};
            }

            &:first-of-type:not(.ag-header-cell-moving),
            &:not(.ag-column-resizing) + .ag-header-cell:not(.ag-header-cell-moving) {
                &:hover {
                    background-color: ${({ theme }) => theme.color.state.default.hover.value};
                }
            }

            &::after {
                background-color: ${({ theme }) => theme.color.gray[200].value};
                height: ${({ theme }) => theme.size.spacing.large.value};
            }
        }

        .ag-header-cell-sortable {
            .ag-sort-none-icon {
                display: none;
            }

            &:hover {
                .ag-sort-none-icon {
                    display: flex;
                }
            }
        }

        .ag-keyboard-focus .ag-header-cell:focus::after {
            height: calc(100% - 1px);
            left: 0;
            top: 0;
            width: 100%;
        }

        .ag-ltr .ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected) {
            border: 1px solid ${({ theme }) => theme.color.primary[500].value};
        }

        .ag-react-container {
            display: flex;
            min-width: 0;
        }

        .ag-overlay-panel {
            display: flex;
            justify-content: center;
        }

        .ag-overlay-no-rows-wrapper {
            width: 90%;
        }

        .ag-menu,
        .ag-picker-field-wrapper {
            border-color: ${({ theme }) => theme.color.gray[200].value};
        }

        .ag-filter-body-wrapper {
            color: ${({ theme }) => theme.color.text.light.primary.value};
            font-family: ${({ theme }) => theme.font.family.main.value};
            font-size: ${getRem(14)};
        }

        .ag-icon-filter {
            color: rgba(${({ theme }) => getHexToRgb(theme.color.additional.black.value)}, 0.9);
        }

        .ag-checkbox-input-wrapper {
            background-color: transparent;
            background-image: url('${checkboxUncheckedIcon}');
            content: '';
            cursor: pointer;
            height: ${getRem(24)};
            width: ${getRem(24)};

            &:focus-within {
                box-shadow: none;
            }

            &::after {
                ${centerContent}
                ${transitionAnimation('background-color')}

                border-radius: 50%;
                content: '';
                display: inline-block;
                height: ${getRem(32)};
                width: ${getRem(32)};
            }
        }

        .ag-checkbox-input-wrapper.ag-checked {
            background-image: url('${checkboxCheckedIcon}');

            &::after {
                ${centerContent}

                content: '';
            }
        }

        .ag-checkbox-input-wrapper.ag-indeterminate {
            background-image: url('${checkboxIndeterminateIcon}');

            &::after {
                ${centerContent}

                content: '';
            }
        }

        .ag-checkbox-input {
            cursor: pointer;
        }

        .ag-body-viewport {
            overflow-y: scroll;
        }

        .ag-tooltip {
            ${({ theme }) => typographyCaption(theme)};
            ${transitionAnimation('opacity')}
            ${wordBreak}

            background-color: ${({ theme }) => theme.color.additional.dark.value};
            border: none;
            border-radius: ${({ theme }) => theme.size.borderRadius.large.value};
            box-sizing: border-box;
            color: ${({ theme }) => theme.color.additional.light.value};
            max-width: ${getRem(192)};
            padding: ${({ theme }) => theme.size.spacing.medium.value} ${getRem(12)};
            user-select: none;
        }

        .ag-popup > div:not(.ag-tooltip-custom) {
            box-shadow: none;
        }

        @media print {
            .ag-root {
                border: 1px solid;
                border-color: ${({ theme }) => theme.color.gray[200].value};
                border-radius: ${({ theme }) => theme.size.borderRadius.large.value};
            }

            .ag-cell {
                padding-left: ${getRem(10)};
                padding-right: ${getRem(10)};
            }

            .ag-header-cell {
                padding-left: ${getRem(10)};
                padding-right: ${getRem(10)};

                &::after {
                    margin-bottom: ${({ theme }) => theme.size.spacing.medium.value};
                    margin-top: ${({ theme }) => theme.size.spacing.medium.value};
                }
            }
        }
    }
`;
