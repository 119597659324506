import React from 'react';

import { Loader, StyledLoaderSpinner } from 'lib/loader';
import { removeObjectProperties } from 'lib/utilities';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { iconElementBasePositioning } from '../iconElementBasePositioning';
import { getRem } from './../../core';
import { ChipContent } from './ChipContent';

const StyledChipContent = styled(ChipContent)`
  cursor: progress;
  padding-left: ${getRem('30px')};
`;

export const StyledChipLoader = styled(Loader)`
  ${iconElementBasePositioning};
  left: ${getRem('7px')};

  ${StyledLoaderSpinner} {
    height: ${getRem('15px')};
    width: ${getRem('15px')};
  }

  && {
    position: absolute;
  }
`;

const ChipContentWithLoader = React.forwardRef(({ isLoading, loaderText, ...other }, ref) => {
  const renderLoader = () => !!isLoading && <StyledChipLoader hideLabel label={loaderText} />;

  const propsWithoutExcludedOptions = removeObjectProperties(other, 'renderBeforeContent');

  return <StyledChipContent ref={ref} renderBeforeContent={renderLoader} {...propsWithoutExcludedOptions} />;
});

ChipContentWithLoader.propTypes = {
  /** If true, chips won't be focusable via keyboard */
  disableFocus: PropTypes.bool,
  /** If true, changes button content into loader icon with loader text */
  isLoading: PropTypes.bool,
  /** Chip label */
  label: PropTypes.node.isRequired,
  /** Provided information is rendered as loader text */
  loaderText: PropTypes.node,
  /** Callback function which is triggered on chip click */
  onClick: PropTypes.func,
  /** Value of the chip */
  value: PropTypes.node,
};

ChipContentWithLoader.defaultProps = {
  disableFocus: false,
  isLoading: false,
  loaderText: 'Loading...',
  onClick: undefined,
  value: null,
};

export { ChipContentWithLoader };
