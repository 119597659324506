import React, { useEffect, useRef, Fragment } from 'react';

import { LIST_SIZES } from 'lib/list';
import { KEY_CODES, useEventListener } from 'lib/utilities';
import PropTypes from 'prop-types';
import { useSpring } from 'react-spring/web.cjs';

import { ContextMenuListItem, ContextMenuListGroup } from '../../blocks';
import { MENU_HORIZONTAL_POSITIONS, MENU_VERTICAL_POSITIONS } from '../../constants';
import { ContextMenuContentContainer } from '../../elements';
import {
  getFlattenedOptions,
  scrollElementIntoView,
  useIsMenuIntersecting,
  useIsScrollVisible,
  useMenuOffset,
  useNavigableContextMenu,
} from '../../utilities';

const ContextMenuContent = ({
  ariaLabel,
  customRenderOption,
  dataTestId,
  hideGroupLabel,
  isKeyboardEventLatest,
  isMulti,
  onClose,
  onSelect,
  options,
  positionHorizontal,
  positionRightAligned,
  positionStandard,
  positionVertical,
  selectedOptionIndex,
  size,
  style,
  wrappedComponentRef,
  ...other
}) => {
  const contextMenuRef = useRef();
  const focusedItemRef = useRef();

  const allOptions = getFlattenedOptions(options);
  const allOptionsLength = allOptions.length;

  const isMenuIntersecting = useIsMenuIntersecting(contextMenuRef);
  const isScrollVisible = useIsScrollVisible(contextMenuRef, allOptionsLength);
  const menuOffsetStyles = useMenuOffset(wrappedComponentRef, positionVertical);

  const disabledOptionIndexes = [];
  options.forEach((option, index) => option.isDisabled && disabledOptionIndexes.push(index));

  const [focusedItemIndex, setFocusedItemIndex] = useNavigableContextMenu(
    selectedOptionIndex,
    focusedItemRef,
    allOptionsLength,
    disabledOptionIndexes,
    onClose
  );

  const handleKeydown = (event) => {
    const keyCode = event.keyCode || event.which || 0;
    const isTabClicked = keyCode === KEY_CODES.TAB;

    if (isTabClicked) {
      event.preventDefault();
      onClose();
    }
  };

  useEffect(() => {
    if (focusedItemRef.current) {
      setTimeout(() => {
        scrollElementIntoView(focusedItemRef, contextMenuRef);
      }, 0);
    }
  }, [contextMenuRef, focusedItemRef]);

  useEventListener('keydown', handleKeydown);

  const renderOption = (option, ref, index) => {
    const handleListItemClick = () => {
      if (!option.isDisabled) {
        if (option.onClick) {
          option.onClick();
        }

        onSelect(option, index);
        setFocusedItemIndex(index);
      }
    };

    if (customRenderOption) {
      return customRenderOption(option, ref, index, handleListItemClick);
    }

    const isSelected = selectedOptionIndex === index;
    const isFocused = focusedItemIndex === index;
    const isKeyboardFocused = isFocused && isKeyboardEventLatest;

    return (
      <ContextMenuListItem
        isKeyboardFocused={isKeyboardFocused}
        dataTestId={dataTestId && `${dataTestId}-item-${index}`}
        isMulti={isMulti}
        isSelected={isSelected}
        onClick={handleListItemClick}
        option={option}
        ref={ref}
        size={size}
      />
    );
  };

  let groupedOptionIndex = 0;

  const animationProps = useSpring({ config: { duration: 200 }, delay: 100, from: { opacity: 0 }, opacity: 1 });

  return (
    <ContextMenuContentContainer
      aria-label={ariaLabel}
      isMenuIntersecting={isMenuIntersecting}
      isPositionRightAligned={positionRightAligned}
      isPositionStandard={positionStandard}
      isScrollable={isScrollVisible}
      positionHorizontal={positionHorizontal}
      positionVertical={positionVertical}
      ref={contextMenuRef}
      style={isMenuIntersecting ? animationProps : { ...animationProps, ...menuOffsetStyles, ...style }}
      data-testid={dataTestId}
      {...other}
    >
      {options.map((option, index) => {
        const optionRef = index === focusedItemIndex ? focusedItemRef : undefined;

        if (option.options) {
          return (
            <ContextMenuListGroup
              key={option.id ? option.id : index}
              id={option.id || option.label}
              groupLabel={option.label}
              hideGroupLabel={hideGroupLabel}
            >
              {option.options.map((groupedOption, idx) => {
                const groupedOptionRef = groupedOptionIndex === focusedItemIndex ? focusedItemRef : undefined;

                if (groupedOptionIndex < allOptionsLength) {
                  groupedOptionIndex += 1;
                }

                return (
                  <Fragment key={groupedOption.id ? groupedOption.id : idx}>
                    {renderOption(groupedOption, groupedOptionRef, groupedOptionIndex - 1)}
                  </Fragment>
                );
              })}
            </ContextMenuListGroup>
          );
        }
        return <Fragment key={option.id || index}>{renderOption(option, optionRef, index)}</Fragment>;
      })}
    </ContextMenuContentContainer>
  );
};

ContextMenuContent.propTypes = {
  /** Informs screen reader users what actions they should take */
  ariaLabel: PropTypes.node,
  /** Accepts a customised option render function */
  customRenderOption: PropTypes.func,
  /** Id value used for testing */
  dataTestId: PropTypes.string,
  /** If true, visually hides group label */
  hideGroupLabel: PropTypes.bool,
  /** Tells if keyboard event happened later than mouse event */
  isKeyboardEventLatest: PropTypes.bool,
  /** Sets the Menu to select Multiple Options */
  isMulti: PropTypes.bool,
  /** Callback that is called when context menu is being closed */
  onClose: PropTypes.func.isRequired,
  /** Callback that is called when an item is being selected */
  onSelect: PropTypes.func.isRequired,
  /** List options provided for context menu */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string,
      icon: PropTypes.node,
      id: PropTypes.string,
      isDisabled: PropTypes.bool,
      isSelected: PropTypes.bool,
      label: PropTypes.node.isRequired,
      onClick: PropTypes.func,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          href: PropTypes.string,
          icon: PropTypes.node,
          id: PropTypes.string,
          isDisabled: PropTypes.bool,
          isSelected: PropTypes.bool,
          label: PropTypes.node.isRequired,
          onClick: PropTypes.func,
        })
      ),
    })
  ).isRequired,
  /** Specifies horizontal context menu position relative to wrapper component */
  positionHorizontal: PropTypes.oneOf(Object.values(MENU_HORIZONTAL_POSITIONS)),
  /** Aligns the context menu to bottom right of parent component */
  positionRightAligned: PropTypes.bool,
  /** Sets the context menu to standard bottom left position */
  positionStandard: PropTypes.bool,
  /** Specifies vertical context menu position relative to wrapper component */
  positionVertical: PropTypes.oneOf(Object.values(MENU_VERTICAL_POSITIONS)),
  /** Index of the currently selected option. Option with given index will get focused */
  selectedOptionIndex: PropTypes.number,
  /** Changes list item height */
  size: PropTypes.oneOf(Object.values(LIST_SIZES)),
  /** Custom inline style applied for the main wrapper */
  style: PropTypes.shape({}),
  /** A reference of the component which opens or closes context menu */
  wrappedComponentRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]),
};

ContextMenuContent.defaultProps = {
  ariaLabel: 'Select an option:',
  customRenderOption: undefined,
  dataTestId: undefined,
  hideGroupLabel: false,
  isKeyboardEventLatest: false,
  isMulti: false,
  positionHorizontal: MENU_HORIZONTAL_POSITIONS.RIGHT,
  positionRightAligned: false,
  positionStandard: false,
  positionVertical: MENU_VERTICAL_POSITIONS.TOP,
  selectedOptionIndex: -1,
  size: LIST_SIZES.STANDARD,
  style: {},
  wrappedComponentRef: null,
};

export { ContextMenuContent };
