import React from 'react';
import { getHexToRgb } from 'lib/core';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledSimpleTableRow = styled.tr`
  border-bottom: 1px solid rgba(${({ theme }) => getHexToRgb(theme.color.gray[200].value)});
  &:last-child {
    border-bottom: none;
  }
`;

const SimpleTableRow = ({ children, className, ...other }) => {
  return (
    <StyledSimpleTableRow className={className} {...other}>
      {children}
    </StyledSimpleTableRow>
  );
};

SimpleTableRow.propTypes = {
  /** Allows you to pass semantic HTML table elements, common to `tr`, as children components */
  children: PropTypes.node.isRequired,
  /** Adds additional className prop to override styling */
  className: PropTypes.string,
};

SimpleTableRow.defaultProps = {
  className: '',
};

export { SimpleTableRow };
