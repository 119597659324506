import React from 'react';

import { BUTTON_BACKGROUND_APPEARANCES, BUTTON_ICON_COLORS, BUTTON_SIZES, IconButton } from 'lib/button';
import { BaseCheckbox } from 'lib/checkbox';
import { KEY_CODES } from 'lib/utilities';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { ICON_TOGGLE_ICON_TYPE, ICON_TOGGLE_SIZES } from '../../constants';
import { IconToggleIcon } from '../../elements';
import { getStateStyles } from '../iconToggleStateStyles';
import { BREAKPOINTS, ElementStateWrapper, getRem } from './../../../core';

const triggerKeys = [KEY_CODES.SPACE];

const ICON_TOGGLE_INDICATOR_SIZES = {
  [ICON_TOGGLE_SIZES.LARGE]: '40px',
  [ICON_TOGGLE_SIZES.SMALL]: '32px',
  [ICON_TOGGLE_SIZES.STANDARD]: '36px',
};

const StyledIndicator = styled(IconButton)`
  && {
    ${({ backgroundAppearance }) =>
      backgroundAppearance !== BUTTON_BACKGROUND_APPEARANCES.PRIMARY &&
      css`
        &:hover,
        &:focus,
        &:active {
          background-color: transparent;
        }
      `}

    height: ${getRem(ICON_TOGGLE_INDICATOR_SIZES[ICON_TOGGLE_SIZES.LARGE])};
    width: ${getRem(ICON_TOGGLE_INDICATOR_SIZES[ICON_TOGGLE_SIZES.LARGE])};

    @media ${BREAKPOINTS.L} {
      height: ${({ size }) => getRem(ICON_TOGGLE_INDICATOR_SIZES[size])};
      width: ${({ size }) => getRem(ICON_TOGGLE_INDICATOR_SIZES[size])};
    }
  }
`;

const StyledBaseCheckbox = styled(BaseCheckbox)`
  display: inline-flex;
  height: auto;
  min-height: auto;
  width: auto;
`;

const IconToggle = React.forwardRef(
  (
    {
      backgroundAppearance,
      checked,
      dataTestId,
      iconColor,
      iconType,
      isDisabled,
      label,
      onChange,
      preserveClickableArea,
      size,
      ...other
    },
    ref
  ) => {
    const renderIndicator = () => (
      <StyledIndicator
        backgroundAppearance={backgroundAppearance}
        customTagElement="span"
        data-testid={dataTestId ? `${dataTestId}-button` : undefined}
        icon={<IconToggleIcon checked={checked} iconType={iconType} />}
        iconColor={iconColor}
        preserveClickableArea={preserveClickableArea}
        size={size}
        tabIndex={undefined}
        text={label}
      />
    );

    return (
      <ElementStateWrapper
        stateStyles={getStateStyles(iconColor, isDisabled, StyledIndicator, backgroundAppearance)}
        triggerClickEventAsKeyDown
        triggerKeys={triggerKeys}
        {...other}
      >
        <StyledBaseCheckbox
          backgroundAppearance={backgroundAppearance}
          checked={checked}
          dataTestId={dataTestId}
          hideLabel
          iconColor={iconColor}
          isDisabled={isDisabled}
          onChange={onChange}
          ref={ref}
          renderIndicator={renderIndicator}
          renderLabel={() => {}}
          size={size}
          {...other}
        />
      </ElementStateWrapper>
    );
  }
);

IconToggle.propTypes = {
  /** Changes style depending on theme */
  backgroundAppearance: PropTypes.oneOf(Object.values(BUTTON_BACKGROUND_APPEARANCES)),
  /** If true, sets toggle value to checked */
  checked: PropTypes.bool.isRequired,
  /** Id value used for testing */
  dataTestId: PropTypes.string,
  /** Changes icon color */
  iconColor: PropTypes.oneOf(Object.values(BUTTON_ICON_COLORS)),
  /** Changes icon which is being displayed. Default value: 'favorite' */
  iconType: PropTypes.oneOf(Object.values(ICON_TOGGLE_ICON_TYPE)),
  /** If true, changes visual appearance to indicate that element is non-interactive */
  isDisabled: PropTypes.bool,
  /** Label, which is visually hidden, but necessary for accessibility */
  label: PropTypes.node.isRequired,
  /** Toggle input name */
  name: PropTypes.string.isRequired,
  /** Callback that is called when interacting with toggle */
  onChange: PropTypes.func,
  /** If true, adds vertical margin to the component */
  preserveClickableArea: PropTypes.bool,
  /** Changes button height. Sizes: ['small', 'standard', 'large']. Default 'standard' */
  size: PropTypes.oneOf(Object.values(BUTTON_SIZES)),
};

IconToggle.defaultProps = {
  backgroundAppearance: BUTTON_BACKGROUND_APPEARANCES.LIGHT,
  dataTestId: undefined,
  iconColor: BUTTON_ICON_COLORS.PRIMARY,
  iconType: ICON_TOGGLE_ICON_TYPE.FAVORITE,
  isDisabled: false,
  onChange: undefined,
  preserveClickableArea: false,
  size: BUTTON_SIZES.STANDARD,
};

export { IconToggle };
