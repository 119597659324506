import React, { useState } from 'react';

import { ContextMenu } from 'lib/context-menu';
import { removeObjectProperties } from 'lib/utilities';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { VIEW_SWITCHER_SIZES, defaultOptions } from '../constants';
import { ViewSwitcherButton } from '../elements';

const StyledContextMenu = styled(ContextMenu)`
  ${({ theme }) => css`
    margin-top: ${theme.size.spacing.small.value};
    padding-bottom: ${theme.size.spacing.small.value};
  `}
`;

export const ViewSwitcher = ({
  ariaLabel,
  dataTestId,
  defaultSelectedLabel,
  defaultSelectedValue,
  hideIcon,
  hideLabel,
  onClose,
  onOpen,
  onSelect,
  options,
  size,
  ...other
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(defaultSelectedValue);
  const selectedView = options && options.find((option) => option.value === selectedValue);
  const optionsWithoutIcons = options.map((option) => removeObjectProperties(option, 'icon'));

  const handleClose = () => {
    onClose();
    setIsOpen(false);
  };
  const handleOpen = () => {
    onOpen();
    setIsOpen(true);
  };
  const handleSelect = (option) => {
    onSelect(option.value);
    setSelectedValue(option.value);
    setIsOpen(false);
  };

  const wrappedComponentContent = (
    <ViewSwitcherButton
      dataTestId={dataTestId ? `${dataTestId}-button` : undefined}
      hideIcon={hideIcon}
      hideLabel={hideLabel}
      icon={selectedView.icon}
      isOpen={isOpen}
      label={selectedView.label}
      onClick={isOpen ? handleClose : handleOpen}
      size={size}
    />
  );

  return (
    <StyledContextMenu
      ariaLabel={ariaLabel}
      dataTestId={dataTestId}
      defaultSelectedValue={defaultSelectedValue}
      hideGroupLabel={false}
      isOpen={isOpen}
      onClose={handleClose}
      onOpen={handleOpen}
      onSelect={handleSelect}
      options={hideIcon ? optionsWithoutIcons : options}
      positionStandard
      size="standard"
      wrappedComponentContent={wrappedComponentContent}
      {...other}
    />
  );
};

ViewSwitcher.propTypes = {
  /** Informs screen reader users what actions they should take */
  ariaLabel: PropTypes.node,
  /** Id value used for testing */
  dataTestId: PropTypes.string,
  /** Value of pre-selected label */
  defaultSelectedLabel: PropTypes.string,
  /** Value of pre-selected option */
  defaultSelectedValue: PropTypes.node,
  /** If true, visually hides icon */
  hideIcon: PropTypes.bool,
  /** If true, visually hides button label */
  hideLabel: PropTypes.bool,
  /** Callback that is called when ViewSwitcher is being closed */
  onClose: PropTypes.func,
  /** Callback that is called when ViewSwitcher is getting opened */
  onOpen: PropTypes.func,
  /** Callback that is called when an item is clicked in the ViewSwitcher */
  onSelect: PropTypes.func,
  /** ViewSwitcher options. For correct data structure refer to component documentation */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      /** Renders icon before label */
      icon: PropTypes.node,
      /** Unique identifier for option */
      id: PropTypes.string,
      /** Option label */
      label: PropTypes.string,
      /** Option value */
      value: PropTypes.string,
    })
  ),
  /** Changes button container height */
  size: PropTypes.oneOf(Object.values(VIEW_SWITCHER_SIZES)),
};

ViewSwitcher.defaultProps = {
  ariaLabel: 'Select a view',
  dataTestId: '',
  defaultSelectedLabel: defaultOptions[0].label,
  defaultSelectedValue: defaultOptions[0].value,
  hideIcon: false,
  hideLabel: false,
  onClose: () => {},
  onOpen: () => {},
  onSelect: () => {},
  options: defaultOptions,
  size: VIEW_SWITCHER_SIZES.STANDARD,
};
