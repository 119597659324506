import React from 'react';
import { getRem } from 'lib/core';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const StyledSimpleTableWrapper = styled.div`
  ${({ theme }) => css`
    border: 1px solid ${theme.color.gray[200].value};
    border-color: ${theme.color.gray[200].value};
    border-radius: ${getRem(theme.size.borderRadius.large.value)};
    width: 100%;
  `};
`;

const SimpleTableWrapper = ({ children, className, ...other }) => {
  return (
    <StyledSimpleTableWrapper className={className} {...other}>
      {children}
    </StyledSimpleTableWrapper>
  );
};

SimpleTableWrapper.propTypes = {
  /** Displays any kind of content included between opening and closing tags  */
  children: PropTypes.node.isRequired,
  /** Adds additional className prop to override styling */
  className: PropTypes.string,
};

SimpleTableWrapper.defaultProps = {
  className: '',
};

export { SimpleTableWrapper };
