import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { ContextMenuListGroupLabel } from '../elements';
import { getHexToRgb } from './../../core';

const StyledContextMenuListGroup = styled.div`
  &:not(:first-child) {
    border: 0;
    padding-top: 0;
  }

  &:not(:last-child) {
    border-bottom: 1px solid rgba(${({ theme }) => getHexToRgb(theme.color.additional.dark.value)}, 0.3);
  }
`;

const ContextMenuListGroup = ({ children, groupLabel, hideGroupLabel, id }) => (
  <StyledContextMenuListGroup>
    {groupLabel && (
      <ContextMenuListGroupLabel isVisuallyHidden={hideGroupLabel} id={id}>
        {groupLabel}
      </ContextMenuListGroupLabel>
    )}
    <div role="group" aria-label={hideGroupLabel && groupLabel} aria-labelledby={!hideGroupLabel && id}>
      {children}
    </div>
  </StyledContextMenuListGroup>
);

ContextMenuListGroup.propTypes = {
  /** Rendering of context menu options */
  children: PropTypes.node.isRequired,
  /** Specifies the label for grouped options */
  groupLabel: PropTypes.node.isRequired,
  /** If true, visually hides group label */
  hideGroupLabel: PropTypes.bool,
  /** Specifies the group id */
  id: PropTypes.string.isRequired,
};

ContextMenuListGroup.defaultProps = {
  hideGroupLabel: false,
};

export { ContextMenuListGroup };
