// This is a generated file. DO NOT modify directly.

export const Add = [
  { d: "M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6z", fillRule: "evenodd" },
];
export const AddCircle = [
  {
    d:
      "M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 11h-4v4h-2v-4H7v-2h4V7h2v4h4",
    fillRule: "evenodd",
  },
];
export const Bookmark = [
  {
    d: "M17 3H7c-1.1 0-1.99.9-1.99 2L5 21l7-3 7 3V5c0-1.1-.9-2-2-2z",
    fillRule: "evenodd",
  },
];
export const BookmarkBorder = [
  {
    d:
      "M17 3H7c-1.1 0-1.99.9-1.99 2L5 21l7-3 7 3V5c0-1.1-.9-2-2-2zm0 15l-5-2.18L7 18V5h10v13z",
    fillRule: "evenodd",
  },
];
export const CancelOutlined = [
  {
    d:
      "M17 15.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12z",
    fillRule: "evenodd",
  },
];
export const Cancel = [
  {
    d:
      "M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z",
    fillRule: "evenodd",
  },
];
export const CheckCircle = [
  {
    d:
      "M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z",
    fillRule: "evenodd",
  },
];
export const Check = [
  {
    d: "M8.795 15.875l-4.17-4.17-1.42 1.41 5.59 5.59 12-12-1.41-1.41z",
    fillRule: "evenodd",
  },
];
export const Close = [
  {
    d:
      "M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z",
    fillRule: "evenodd",
  },
];
export const Declined = [
  {
    d:
      "M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8 0-1.85.63-3.55 1.69-4.9L16.9 18.31A7.902 7.902 0 0112 20zm6.31-3.1L7.1 5.69A7.902 7.902 0 0112 4c4.42 0 8 3.58 8 8 0 1.85-.63 3.55-1.69 4.9z",
    fillRule: "evenodd",
  },
];
export const Delete = [
  {
    d:
      "M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z",
    fillRule: "evenodd",
  },
];
export const DragHandle = [
  { d: "M3 15h18v-2H3v2zm0-4h18V9H3v2z", fillRule: "evenodd" },
];
export const Edit = [
  {
    d:
      "M2.999 17.251v3.75h3.75l11.06-11.06-3.75-3.75-11.06 11.06zm17.71-10.21a.996.996 0 000-1.41l-2.34-2.34a.996.996 0 00-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z",
    fillRule: "evenodd",
  },
];
export const ErrorOutlined = [
  {
    d:
      "M11 15h2v2h-2v-2zm0-8h2v6h-2V7zm.99-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z",
    fillRule: "evenodd",
  },
];
export const Error = [
  {
    d:
      "M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z",
    fillRule: "evenodd",
  },
];
export const Favorite = [
  {
    d:
      "M12 21.175l-1.45-1.32C5.4 15.185 2 12.105 2 8.325c0-3.08 2.42-5.5 5.5-5.5 1.74 0 3.41.81 4.5 2.09 1.09-1.28 2.76-2.09 4.5-2.09 3.08 0 5.5 2.42 5.5 5.5 0 3.78-3.4 6.86-8.55 11.54L12 21.175z",
    fillRule: "evenodd",
  },
];
export const FavoriteBorder = [
  {
    d:
      "M16.5 2.825c-1.74 0-3.41.81-4.5 2.09-1.09-1.28-2.76-2.09-4.5-2.09-3.08 0-5.5 2.42-5.5 5.5 0 3.78 3.4 6.86 8.55 11.54l1.45 1.31 1.45-1.32c5.15-4.67 8.55-7.75 8.55-11.53 0-3.08-2.42-5.5-5.5-5.5zm-4.4 15.55l-.1.1-.1-.1C7.14 14.065 4 11.215 4 8.325c0-2 1.5-3.5 3.5-3.5 1.54 0 3.04.99 3.57 2.36h1.87c.52-1.37 2.02-2.36 3.56-2.36 2 0 3.5 1.5 3.5 3.5 0 2.89-3.14 5.74-7.9 10.05z",
    fillRule: "evenodd",
  },
];
export const Flag = [
  { d: "M13.9 5.5l-.4-2h-9v17h2v-7h5.6l.4 2h7v-10z", fillRule: "evenodd" },
];
export const Label = [
  {
    d:
      "M17.13 5.84c-.36-.51-.96-.84-1.63-.84l-11 .01c-1.1 0-2 .89-2 1.99v10c0 1.1.9 1.99 2 1.99l11 .01c.67 0 1.27-.33 1.63-.84L21.5 12l-4.37-6.16z",
    fillRule: "evenodd",
  },
];
export const LabelOutline = [
  {
    d:
      "M17.13 5.84c-.36-.51-.96-.84-1.63-.84l-11 .01c-1.1 0-2 .89-2 1.99v10c0 1.1.9 1.99 2 1.99l11 .01c.67 0 1.27-.33 1.63-.84L21.5 12l-4.37-6.16zM15.5 17h-11V7h11l3.55 5-3.55 5z",
    fillRule: "evenodd",
  },
];
export const LockOpen = [
  {
    d:
      "M12 17.5c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm6-9h-1v-2c0-2.76-2.24-5-5-5s-5 2.24-5 5h1.9c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2v-10c0-1.1-.9-2-2-2zm0 12H6v-10h12v10z",
    fillRule: "evenodd",
  },
];
export const LockOutline = [
  {
    d:
      "M12 17.5c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm6-9h-1v-2c0-2.76-2.24-5-5-5s-5 2.24-5 5v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2v-10c0-1.1-.9-2-2-2zm-9.1-2c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2H8.9v-2zm9.1 14H6v-10h12v10z",
    fillRule: "evenodd",
  },
];
export const Logout = [
  {
    d:
      "M15.275 8.41L16.685 7l5 5-5 5-1.41-1.41 2.58-2.59H8v-2h9.855l-2.58-2.59zM4 5h7.97V3H4a2 2 0 00-2 2v14a2 2 0 002 2h7.97v-2H4V5z",
    fillRule: "evenodd",
  },
];
export const Print = [
  {
    d:
      "M19 8H5c-1.66 0-3 1.34-3 3v6h4v4h12v-4h4v-6c0-1.66-1.34-3-3-3zm-3 11H8v-5h8v5zm3-7c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm-1-9H6v4h12V3z",
    fillRule: "evenodd",
  },
];
export const Redo = [
  {
    d:
      "M18.63 11.1c-1.85-1.61-4.25-2.6-6.9-2.6-4.65 0-8.58 3.03-9.96 7.22l2.36.78a8.002 8.002 0 017.6-5.5c1.95 0 3.73.72 5.12 1.88l-3.62 3.62h9v-9l-3.6 3.6z",
    fillRule: "evenodd",
  },
];
export const Remove = [{ d: "M19 13H5v-2h14z", fillRule: "evenodd" }];
export const Settings = [
  {
    d:
      "M19.432 12.98c.04-.32.07-.64.07-.98 0-.34-.03-.66-.07-.98l2.11-1.65c.19-.15.24-.42.12-.64l-2-3.46c-.12-.22-.39-.3-.61-.22l-2.49 1c-.52-.4-1.08-.73-1.69-.98l-.38-2.65a.488.488 0 00-.49-.42h-4c-.25 0-.46.18-.49.42l-.38 2.65c-.61.25-1.17.59-1.69.98l-2.49-1c-.23-.09-.49 0-.61.22l-2 3.46c-.13.22-.07.49.12.64l2.11 1.65c-.04.32-.07.65-.07.98 0 .33.03.66.07.98l-2.11 1.65c-.19.15-.24.42-.12.64l2 3.46c.12.22.39.3.61.22l2.49-1c.52.4 1.08.73 1.69.98l.38 2.65c.03.24.24.42.49.42h4c.25 0 .46-.18.49-.42l.38-2.65c.61-.25 1.17-.59 1.69-.98l2.49 1c.23.09.49 0 .61-.22l2-3.46c.12-.22.07-.49-.12-.64l-2.11-1.65zm-7.43 2.52c-1.93 0-3.5-1.57-3.5-3.5s1.57-3.5 3.5-3.5 3.5 1.57 3.5 3.5-1.57 3.5-3.5 3.5z",
    fillRule: "evenodd",
  },
];
export const Star = [
  {
    d:
      "M12 17.77l6.18 3.73-1.64-7.03L22 9.74l-7.19-.61L12 2.5 9.19 9.13 2 9.74l5.46 4.73-1.64 7.03z",
    fillRule: "evenodd",
  },
];
export const StarBorder = [
  {
    d:
      "M22 9.74l-7.19-.62L12 2.5 9.19 9.13 2 9.74l5.46 4.73-1.64 7.03L12 17.77l6.18 3.73-1.63-7.03L22 9.74zM12 15.9l-3.76 2.27 1-4.28-3.32-2.88 4.38-.38L12 6.6l1.71 4.04 4.38.38-3.32 2.88 1 4.28L12 15.9z",
    fillRule: "evenodd",
  },
];
export const StarHalf = [
  {
    d:
      "M22 9.74l-7.19-.62L12 2.5 9.19 9.13 2 9.74l5.46 4.73-1.64 7.03L12 17.77l6.18 3.73-1.63-7.03L22 9.74zM12 15.9V6.6l1.71 4.04 4.38.38-3.32 2.88 1 4.28L12 15.9z",
    fillRule: "evenodd",
  },
];
export const Sync = [
  {
    d:
      "M12 6v3l4-4-4-4v3c-4.42 0-8 3.58-8 8 0 1.57.46 3.03 1.24 4.26L6.7 14.8A5.87 5.87 0 016 12c0-3.31 2.69-6 6-6zm6.76 1.74L17.3 9.2c.44.84.7 1.79.7 2.8 0 3.31-2.69 6-6 6v-3l-4 4 4 4v-3c4.42 0 8-3.58 8-8 0-1.57-.46-3.03-1.24-4.26z",
    fillRule: "evenodd",
  },
];
export const ThumbDown = [
  {
    d:
      "M15 2H6c-.83 0-1.54.5-1.84 1.22l-3.02 7.05c-.09.23-.14.47-.14.73v1.91l.01.01L1 13c0 1.1.9 2 2 2h6.31l-.95 4.57-.03.32c0 .41.17.79.44 1.06L9.83 22l6.59-6.59c.36-.36.58-.86.58-1.41V4c0-1.1-.9-2-2-2zm4 0v12h4V2h-4z",
    fillRule: "evenodd",
  },
];
export const ThumbUp = [
  {
    d:
      "M1 22h4V10H1v12zm22-11c0-1.1-.9-2-2-2h-6.31l.95-4.57.03-.32c0-.41-.17-.79-.44-1.06L14.17 2 7.59 8.59C7.22 8.95 7 9.45 7 10v10c0 1.1.9 2 2 2h9c.83 0 1.54-.5 1.84-1.22l3.02-7.05c.09-.23.14-.47.14-.73v-1.91l-.01-.01L23 11z",
    fillRule: "evenodd",
  },
];
export const Undo = [
  {
    d:
      "M12.265 8.5c-2.65 0-5.05.99-6.9 2.6l-3.6-3.6v9h9l-3.62-3.62c1.39-1.16 3.16-1.88 5.12-1.88 3.54 0 6.55 2.31 7.6 5.5l2.37-.78c-1.39-4.19-5.32-7.22-9.97-7.22z",
    fillRule: "evenodd",
  },
];
export const StatusDefault = [
  {
    d:
      "M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2z",
    fillRule: "evenodd",
  },
];
export const StatusInformation = [
  {
    d:
      "M2.042 13.414A2.095 2.095 0 011.5 12c0-.528.18-.997.542-1.409l8.51-8.514c.425-.385.908-.578 1.448-.577.54 0 1.023.194 1.447.581l8.504 8.5c.366.417.549.896.549 1.44-.003.515-.186.98-.55 1.393l-8.498 8.505c-.428.387-.912.58-1.452.58-.54 0-1.023-.193-1.449-.58l-8.509-8.505z",
    fillRule: "evenodd",
  },
];
export const StatusInformationI = [
  {
    d:
      "M12 1.5c.54 0 1.023.194 1.447.581l8.504 8.5c.366.417.549.896.549 1.44-.003.515-.186.98-.55 1.393l-8.498 8.505c-.428.387-.912.58-1.452.58-.54 0-1.023-.193-1.449-.58l-8.509-8.505A2.095 2.095 0 011.5 12c0-.528.18-.997.542-1.409l8.51-8.514c.425-.385.908-.578 1.448-.577zm1.05 9.45h-2.1v6.3h2.1v-6.3zm0-4.2h-2.1v2.1h2.1v-2.1z",
    fillRule: "evenodd",
  },
];
export const StatusInformationTrendDown = [
  {
    d:
      "M12 1.5c.54 0 1.023.194 1.447.581l8.504 8.5c.366.417.549.896.549 1.44-.003.515-.186.98-.55 1.393l-8.498 8.505c-.428.387-.912.58-1.452.58-.54 0-1.023-.193-1.449-.58l-8.509-8.505A2.095 2.095 0 011.5 12c0-.528.18-.997.542-1.409l8.51-8.514c.425-.385.908-.578 1.448-.577zm1.326 5.25h-2.652v5.966l-2.32-2.32L6.75 12 12 17.25 17.25 12l-1.604-1.604-2.32 2.32V6.75z",
    fillRule: "evenodd",
  },
];
export const StatusInformationTrendUp = [
  {
    d:
      "M12 1.5c.54 0 1.023.194 1.447.581l8.504 8.5c.366.417.549.896.549 1.44-.003.515-.186.98-.55 1.393l-8.498 8.505c-.428.387-.912.58-1.452.58-.54 0-1.023-.193-1.449-.58l-8.509-8.505A2.095 2.095 0 011.5 12c0-.528.18-.997.542-1.409l8.51-8.514c.425-.385.908-.578 1.448-.577zm0 5.25L6.75 12l1.604 1.604 2.32-2.32v5.966h2.652v-5.966l2.32 2.32L17.25 12 12 6.75z",
    fillRule: "evenodd",
  },
];
export const StatusNegative = [
  {
    d: "M8.111 2L2 8.111v7.778L8.111 22h7.778L22 15.889V8.111L15.889 2z",
    fillRule: "evenodd",
  },
];
export const StatusNegativeTrendDown = [
  {
    d:
      "M15.889 2L22 8.111v7.778L15.889 22H8.11L2 15.889V8.11L8.111 2h7.778zm-2.563 4.75h-2.652v5.966l-2.32-2.32L6.75 12 12 17.25 17.25 12l-1.604-1.604-2.32 2.32V6.75z",
    fillRule: "evenodd",
  },
];
export const StatusNegativeTrendUp = [
  {
    d:
      "M15.889 2L22 8.111v7.778L15.889 22H8.11L2 15.889V8.11L8.111 2h7.778zM12 6.75L6.75 12l1.604 1.604 2.32-2.32v5.966h2.652v-5.966l2.32 2.32L17.25 12 12 6.75z",
    fillRule: "evenodd",
  },
];
export const StatusNegativeX = [
  {
    d:
      "M15.889 2L22 8.111v7.778L15.889 22H8.11L2 15.889V8.11L8.111 2h7.778zm.04 4.5L12 10.428 8.072 6.5 6.5 8.072l3.929 3.927L6.5 15.93l1.572 1.57L12 13.57l3.928 3.93 1.572-1.572L13.57 12l3.93-3.928L15.928 6.5z",
    fillRule: "evenodd",
  },
];
export const StatusPositive = [
  {
    d:
      "M19.778 2H4.222C2.99 2 2 3 2 4.222v15.556C2 21 2.989 22 4.222 22h15.556C21.01 22 22 21 22 19.778V4.222A2.222 2.222 0 0019.778 2z",
    fillRule: "evenodd",
  },
];
export const StatusPositiveSuccess = [
  {
    d:
      "M19.778 2C21.01 2 22 3 22 4.222v15.556A2.222 2.222 0 0119.778 22H4.222C2.99 22 2 21 2 19.778V4.222C2 3 2.989 2 4.222 2h15.556zM18.21 5.978L9.778 14.41l-3.99-3.978L4.223 12l5.556 5.556 10-10-1.567-1.578z",
    fillRule: "evenodd",
  },
];
export const StatusPositiveTrendDown = [
  {
    d:
      "M19.778 2C21.01 2 22 3 22 4.222v15.556A2.222 2.222 0 0119.778 22H4.222C2.99 22 2 21 2 19.778V4.222C2 3 2.989 2 4.222 2h15.556zm-6.452 4.75h-2.652v5.966l-2.32-2.32L6.75 12 12 17.25 17.25 12l-1.604-1.604-2.32 2.32V6.75z",
    fillRule: "evenodd",
  },
];
export const StatusPositiveTrendUp = [
  {
    d:
      "M19.778 2C21.01 2 22 3 22 4.222v15.556A2.222 2.222 0 0119.778 22H4.222C2.99 22 2 21 2 19.778V4.222C2 3 2.989 2 4.222 2h15.556zM12 6.75L6.75 12l1.604 1.604 2.32-2.32v5.966h2.652v-5.966l2.32 2.32L17.25 12 12 6.75z",
    fillRule: "evenodd",
  },
];
export const StatusWarning = [
  {
    d:
      "M1.953 21.084c.329.275.702.414 1.12.416h17.846c.445 0 .827-.145 1.145-.436.463-.46.557-1.008.282-1.645L12.911 2.973c-.215-.308-.519-.466-.912-.473-.412.01-.722.179-.93.505L1.664 19.399c-.291.64-.195 1.201.29 1.685z",
    fillRule: "evenodd",
  },
];
export const StatusWarningExclamation = [
  {
    d:
      "M12 2.5c.392.007.696.165.911.473l9.435 16.446c.275.637.181 1.185-.282 1.645-.318.29-.7.436-1.145.436H3.073a1.712 1.712 0 01-1.12-.416c-.484-.484-.58-1.045-.289-1.685l9.405-16.394c.208-.326.518-.495.93-.505zm1.1 13.9H11v2.1h2.1v-2.1zm0-8.4H11v6.3h2.1V8z",
    fillRule: "evenodd",
  },
];
export const StatusWarningTrendDown = [
  {
    d:
      "M12 2.5c.392.007.696.165.911.473l9.435 16.446c.275.637.181 1.185-.282 1.645-.318.29-.7.436-1.145.436H3.073a1.712 1.712 0 01-1.12-.416c-.484-.484-.58-1.045-.289-1.685l9.405-16.394c.208-.326.518-.495.93-.505zm1.326 5.75h-2.652v5.966l-2.32-2.32L6.75 13.5 12 18.75l5.25-5.25-1.604-1.604-2.32 2.32V8.25z",
    fillRule: "evenodd",
  },
];
export const StatusWarningTrendUp = [
  {
    d:
      "M12 2.5c.392.007.696.165.911.473l9.435 16.446c.275.637.181 1.185-.282 1.645-.318.29-.7.436-1.145.436H3.073a1.712 1.712 0 01-1.12-.416c-.484-.484-.58-1.045-.289-1.685l9.405-16.394c.208-.326.518-.495.93-.505zm0 5.75L6.75 13.5l1.604 1.604 2.32-2.32v5.966h2.652v-5.966l2.32 2.32L17.25 13.5 12 8.25z",
    fillRule: "evenodd",
  },
];
export const Accounting = [
  {
    fillRule: "evenodd",
    d:
      "M12.48 10.9c-2.27-.59-3-1.2-3-2.15 0-1.09 1.01-1.85 2.7-1.85 1.78 0 2.44.85 2.5 2.1h2.21c-.07-1.72-1.12-3.3-3.21-3.81V3h-3v2.16c-1.94.42-3.5 1.68-3.5 3.61 0 2.31 1.91 3.46 4.7 4.13 2.5.6 3 1.48 3 2.41 0 .69-.49 1.79-2.7 1.79-2.06 0-2.87-.92-2.98-2.1H7c.12 2.19 1.76 3.42 3.68 3.83V21h3v-2.15c1.95-.37 3.5-1.5 3.5-3.55 0-2.84-2.43-3.81-4.7-4.4z",
  },
];
export const CashRegister = [
  {
    d:
      "M21.5 17c.75 0 1.5.605 1.5 1.5v2c0 .662-.45 1.5-1.5 1.5h-19c-.974 0-1.5-.772-1.5-1.5v-2c0-1.05.87-1.5 1.5-1.5h19zM12 18.5a1 1 0 100 2 1 1 0 000-2zM17 2c1.1 0 2 .9 2 2v10c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V4c0-1.1.9-2 2-2h13zm0 2H4v10h13V4zm4 2a.98.98 0 01.734.323.935.935 0 01.266.683V13c-.005.255-.091.48-.26.675-.183.208-.43.316-.74.325a1.036 1.036 0 01-.691-.277c-.193-.177-.296-.418-.309-.723V7.006c0-.262.092-.493.277-.694A.971.971 0 0121 6zm-8 5v2h-2v-2h2zm3 0v2h-2v-2h2zm-3-3v2h-2V8h2zm3 0v2h-2V8h2zm0-3v2h-5V5h5z",
    fillRule: "evenodd",
  },
];
export const Payroll = [
  {
    d:
      "M23 5v14H1V5h22zm-2 2H3v10h18V7zm-4 6v2h-4v-2h4zm-4-4h6v2h-6V9zm-6 8h2v-1h1c.55 0 1-.45 1-1v-3c0-.55-.45-1-1-1H7v-1h4V8H9V7H7v1H6c-.55 0-1 .45-1 1v3c0 .55.45 1 1 1h3v1H5v2h2v1z",
    fillRule: "evenodd",
  },
];
export const CertifiedPreowned = [
  {
    d:
      "M15.71 0l1.408 2.493 2.307-.202.437 1.854 1.847.442-.202 2.294L24 8.291l-1.086 2.403 1.086 1.3-1.089 1.304L24 15.709l-2.492 1.408.201 2.285-1.851.441-.445 1.866-2.301-.202-1.403 2.482-2.395-1.082L12.006 24l-1.31-1.093L8.291 24l-1.409-2.492-2.284.201-.442-1.851-1.865-.445.201-2.302-2.48-1.402 1.083-2.403L0 11.994l1.088-1.308L0 8.291l2.493-1.41-.202-2.306 1.854-.438.442-1.846 2.295.2L8.291 0l2.41 1.088L12.006 0l1.302 1.084L15.709 0zM7.555 9H4.889A.885.885 0 004 9.875v5.25c0 .481.4.875.889.875h2.667a.885.885 0 00.888-.875v-.875H7.111v.438H5.333v-4.376h1.778v.438h1.333v-.875c0-.481-.4-.875-.888-.875zm10.666 0h-.889l-.145.006a1.761 1.761 0 00-1.632 1.744v3.5l.005.144c.075.899.84 1.606 1.772 1.606h.89l.145-.006A1.761 1.761 0 0020 14.25v-3.5l-.006-.144A1.767 1.767 0 0018.222 9zM12.89 9H9.778v7h1.333v-2.625h1.778c.755 0 1.333-.569 1.333-1.313v-1.75C14.222 9.57 13.644 9 12.89 9zm5.778 1.313v4.374H16.89v-4.374h1.778zm-5.778 0v1.75h-1.78v-1.75h1.778z",
    fillRule: "evenodd",
  },
];
export const Dealer = [
  {
    d:
      "M18.156 12a.84.84 0 01.799.568l1.17 3.37v4.5a.564.564 0 01-.563.562H19a.564.564 0 01-.563-.563v-.562h-6.75v.563a.564.564 0 01-.562.562h-.563a.564.564 0 01-.562-.563v-4.5l1.17-3.369a.846.846 0 01.799-.568zM22 3l2 3v3h-1v12h-.895V9H2.008v12H1V9H0V6l2-3h20zM10.069 13l-.259.75H5.75L5 16h4.035l-.031.093L9.001 20H5.5v.5a.503.503 0 01-.41.492L5 21h-.5a.502.502 0 01-.492-.41L4 20.5v-4l1.04-2.995a.754.754 0 01.602-.497L5.75 13h4.319zm-4.32 4a.749.749 0 100 1.5.749.749 0 100-1.5zm6.22-.5a.843.843 0 100 1.688.843.843 0 100-1.688zm6.187 0a.843.843 0 100 1.688.843.843 0 100-1.688zm0-3.656H11.97l-.844 2.531H19l-.844-2.531z",
    fillRule: "evenodd",
  },
];
export const DealerPay = [
  {
    d:
      "M17.995 14A5.003 5.003 0 0123 19c0 2.76-2.24 5-5.005 5A4.998 4.998 0 0113 19c0-2.76 2.235-5 4.995-5zm.672 1.7h-1.334v.667h-.666c-.367 0-.667.3-.667.666v2c0 .367.3.667.667.667h2v.667H16V21.7h1.333v.667h1.334V21.7h.666c.367 0 .667-.3.667-.667v-2c0-.366-.3-.666-.667-.666h-2V17.7H20v-1.333h-1.333V15.7zM17.157 9a.84.84 0 01.753.461l.045.107.862 2.48A7.074 7.074 0 0018 12l-.125.002-.719-2.158H10.97l-.844 2.531h5.609a7.018 7.018 0 00-4.406 4.5h-.64v.563a.565.565 0 01-.472.555l-.091.007h-.563a.565.565 0 01-.555-.472L9 17.438v-4.5l1.17-3.37a.848.848 0 01.677-.56L10.97 9h6.187zM21 0l2 3v3h-1l.001 7.256a6.996 6.996 0 00-.896-.532V6H1.008v12H0V6h-1V3l2-3h20zM9.069 10l-.259.75H4.75L4 13h4.035l-.031.093L8.001 17H4.5v.5a.503.503 0 01-.41.492L4 18h-.5a.502.502 0 01-.492-.41L3 17.5v-4l1.04-2.995a.754.754 0 01.602-.497L4.75 10h4.319zm-4.32 4a.749.749 0 100 1.5.749.749 0 100-1.5zm6.22-.5a.843.843 0 100 1.688.843.843 0 100-1.688z",
    fillRule: "evenodd",
  },
];
export const OEMCommunications = [
  {
    d:
      "M5 5v4l6 4V9l6 4V9l6 4v10H1V5h4zm3.163 10h-.08c-.143.002-.315.021-.444.13-.166.152-.894.957-2.182 2.416H4.382l-.077.003a.34.34 0 00-.194.1.366.366 0 00-.111.268v2.174h2.224a1.82 1.82 0 001.781 1.455c.878 0 1.613-.625 1.782-1.454h5.892a1.82 1.82 0 001.781 1.454c.878 0 1.613-.625 1.782-1.454h.761v-2.194c-.004-.059-.036-.353-.364-.353h-2.545l-.25-.173c-2.128-1.467-3.256-2.234-3.383-2.303a.577.577 0 00-.237-.07L9.439 15H8.163zm-.157 3.637a1.092 1.092 0 010 2.182 1.092 1.092 0 010-2.182zm9.455 0a1.092 1.092 0 010 2.182 1.092 1.092 0 010-2.182zm-7.277-2.546v1.455H6.911l1.273-1.455h2zm3.091 0l2 1.455h-4V16.09h2zM12 1c-.3 1.266-.967 2.066-2 2.4-1.55.5-4.526 0-6 0-.982 0-1.65.2-2 .6.383-2 1.73-2.8 4.038-2.4C8.346 2 10.333 1.8 12 1z",
    fillRule: "evenodd",
  },
];
export const Order = [
  {
    d:
      "M6.995 18a2 2 0 000 4C8.098 22 9 21.1 9 20s-.902-2-2.005-2zm-.341-6.523L5.257 14.04c-.16.28-.25.61-.25.96 0 1.1.9 2 2 2H19v-2H7.426c-.14 0-.25-.11-.25-.25l.03-.12.9-1.63h7.447c.75 0 1.409-.41 1.749-1.03l3.578-6.49A1.003 1.003 0 0020 4H5.217l-.94-2H2l4.654 9.477zM16.995 18a2 2 0 000 4C18.098 22 19 21.1 19 20s-.902-2-2.005-2z",
    fillRule: "evenodd",
  },
];
export const Parts = [
  {
    d: "M17 3l5 9-5 9H7l-5-9 5-9h10zm-5 5a4 4 0 100 8 4 4 0 000-8z",
    fillRule: "evenodd",
  },
];
export const PartsInStock = [
  {
    d:
      "M18.995 14A5.003 5.003 0 0124 19c0 2.76-2.24 5-5.005 5A4.998 4.998 0 0114 19c0-2.76 2.235-5 4.995-5zm1.701 3l-2.353 2.378-1.046-1.066-1.047 1.058 2.093 2.13 3.407-3.435L20.696 17zM15 0l5 9-1.685 3.033A7.003 7.003 0 0012.071 18H5L0 9l5-9h10zm-5 5a4 4 0 100 8 4 4 0 000-8z",
    fillRule: "evenodd",
  },
];
export const PartsOrdered = [
  {
    d:
      "M13.977 21.6c.66 0 1.2.54 1.2 1.2 0 .66-.54 1.2-1.2 1.2-.66 0-1.194-.54-1.194-1.2 0-.66.534-1.2 1.194-1.2zm6 0c.66 0 1.2.54 1.2 1.2 0 .66-.54 1.2-1.2 1.2-.66 0-1.194-.54-1.194-1.2 0-.66.534-1.2 1.194-1.2zM12.339 12l.564 1.2h8.874a.602.602 0 01.528.888l-2.148 3.894c-.204.372-.6.618-1.05.618h-4.47l-.54.978-.018.072c0 .084.066.15.15.15h6.948V21h-7.2c-.66 0-1.2-.54-1.2-1.2 0-.21.054-.408.15-.576l.81-1.47L11 12h1.339zM15 0l5 9-1.518 2.732-4.594-.013-.381-.794a4 4 0 10-3.705 2.07L12.17 18H5L0 9l5-9h10z",
    fillRule: "evenodd",
  },
];
export const PartsOutOfStock = [
  {
    d:
      "M18.995 14A5.003 5.003 0 0124 19c0 2.76-2.24 5-5.005 5A4.998 4.998 0 0114 19c0-2.76 2.235-5 4.995-5zm1.596 2.348L19 17.94l-1.591-1.59-1.06 1.06L17.938 19l-1.59 1.591 1.06 1.06L19 20.062l1.591 1.59 1.06-1.06L20.062 19l1.59-1.591-1.06-1.06zM15 0l5 9-1.685 3.033A7.003 7.003 0 0012.071 18H5L0 9l5-9h10zm-5 5a4 4 0 100 8 4 4 0 000-8z",
    fillRule: "evenodd",
  },
];
export const StockIn = [
  {
    d:
      "M4 10v10h16V10h2v10c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V10h2zm13.59-6L19 5.42l-8 8-4-4 1.41-1.41L11 10.59 17.59 4z",
    fillRule: "evenodd",
  },
];
export const StockOut = [
  {
    d:
      "M4 10v10h16V10h2v10c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V10h2zm11.536-4.95l1.414 1.414L13.414 10l3.536 3.536-1.414 1.414L12 11.414 8.464 14.95 7.05 13.536 10.586 10 7.05 6.464 8.464 5.05 12 8.586l3.536-3.536z",
    fillRule: "evenodd",
  },
];
export const WaitingParts = [
  {
    d:
      "M18.995 14A5.003 5.003 0 0124 19c0 2.76-2.24 5-5.005 5A4.998 4.998 0 0114 19c0-2.76 2.235-5 4.995-5zm.505 2H18v3.375l3.257 2 .767-1.227-2.524-1.422V16zM15 0l5 9-1.685 3.033A7.003 7.003 0 0012.071 18H5L0 9l5-9h10zm-5 5a4 4 0 100 8 4 4 0 000-8z",
    fillRule: "evenodd",
  },
];
export const Campaigns = [
  {
    d:
      "M8.346 6.883v12.623l.074.027c.403.17.631.611.526 1.047l-.09.375a.907.907 0 01-.99.688l-.127-.025-3.1-.922a.907.907 0 01-.622-1.082l.09-.375a.907.907 0 01.99-.688l.076.014v-4.94C3.058 13.688 2 12.564 2 10.256c0-2.248 1.058-3.372 3.173-3.372h3.173zm12.486-3.36c.104.844.169 2.608.194 4.59a2.264 2.264 0 01.002 3.722c-.024 2.038-.09 3.853-.196 4.69-.175 1.367-.998 1.268-1.239 1.208l-.048-.014-.018-.005c-3.463-2.198-6.633-3.73-9.51-4.596V6.872c2.87-.782 6.04-2.263 9.51-4.444 1.058-.433 1.305 1.095 1.305 1.095z",
    fillRule: "evenodd",
  },
];
export const Concierge = [
  {
    d:
      "M20.678 17.333A1.32 1.32 0 0122 18.652 1.322 1.322 0 0120.707 20h-17.4A1.32 1.32 0 012 18.667a1.32 1.32 0 011.307-1.334h17.37zM10.675 9.035C8.55 10.17 7.043 12.848 7.043 16h13.261v-.13c-.064-4.263-3.223-7.814-7.377-8.293V5.905h.866a.931.931 0 00.802-.488.969.969 0 00-.013-.952.93.93 0 00-.815-.465h-3.549a.943.943 0 00-.934.953c0 .526.419.952.934.952h.84v1.672c-4.202.485-7.377 4.11-7.377 8.423h1.588c0-3.455 2.241-6.332 5.406-6.965z",
    fillRule: "evenodd",
  },
];
export const ConciergePremium = [
  {
    d:
      "M20.75 17.375A1.313 1.313 0 0120.78 20H3.311a1.313 1.313 0 010-2.625H20.75zM11.907 4.25l1.518 1.313.613.534a.938.938 0 01-.2.028h-.87v1.646c.753.083 1.49.269 2.192.551l-.35 1.5-.54 2.309 2.029-1.225 1.481-.89a8.32 8.32 0 012.595 5.918v.129H7.062c0-3.104 1.514-5.74 3.646-6.856-3.177.622-5.427 3.454-5.427 6.855H3.688a8.344 8.344 0 017.406-8.29V6.124h-.844a.938.938 0 010-1.875h1.657zm6.631-2.67l1.255 2.958 3.203.272-2.433 2.11.732 3.133-2.757-1.662-2.756 1.662.732-3.134-2.434-2.11 3.205-.271 1.253-2.957z",
    fillRule: "evenodd",
  },
];
export const DropOff = [
  {
    d:
      "M10.343 5.231l3.252.56a1.916 1.916 0 00-.423.898c-.094.455-.069.888.076 1.299L8.846 10.41l-.203.115c-.355.203-.495.48-.42.83.103.473.531.644.913.607.255-.025.445-.07.569-.134l5.28-3.03c.512-.306 1.682.66.512 1.736-.196.18-1.81 1.797-4.841 4.85l-3.666 3.7c-.524.61-1.497.915-2.918.915C2.648 20 2 20 2 18.96v-6.997L6.003 6.02c.256-.486.585-.8.987-.942.402-.143 1.52-.092 3.353.152zM18.463 4C20.207 4 22 5.389 22 7.483c0 1.396-.667 2.44-2.002 3.132v2.374l-1.009.988L20 15.021l-1.01.97 1.01.975L17.973 19h-.996v-8.385c.462-1.115.389-1.693 0-2.341-.357-.596-1.098-.791-1.978-.791C15 5.353 16.718 4 18.463 4zM14 14v1.776c0 .724-.216 1.492-1.055 1.492h-.892c-.125.488-.447.732-.966.732H10l4-4zm4.42-8.646c-.666 0-1.206.535-1.206 1.195s.54 1.196 1.207 1.196c.666 0 1.206-.536 1.206-1.196a1.2 1.2 0 00-1.206-1.195z",
    fillRule: "evenodd",
  },
];
export const DropOffEarly = [
  {
    d:
      "M18 2a4.998 4.998 0 012 9.578v1.915l-1.5 1.499 1.5 1.5-1.5 1.498 1.5 1.5-2 2.498L16 22V11.578A4.998 4.998 0 0118 2zm-3 17v1H2v-1h13zm-6.5-6c1.93 0 3.5 1.57 3.5 3.5 0 .468-.1.897-.27 1.3l-.09.2H5.339A3.503 3.503 0 018.5 13zM4 16v1H2v-1h2zm11 0v1h-2v-1h2zm-1.945-4.44l.721.82-1.521 1.26-.72-.827 1.52-1.253zm-8.887-.16l1.362 1.43-.774.777-1.356-1.43.768-.777zM9 10v2H8v-2h1zm9-6.001a1.5 1.5 0 100 2.999 1.5 1.5 0 000-3z",
    fillRule: "evenodd",
  },
];
export const DropOffLate = [
  {
    d:
      "M17 2a4.998 4.998 0 012 9.578v1.915l-1.5 1.499 1.5 1.5-1.5 1.498 1.5 1.5-2 2.498L15 22V11.578A4.998 4.998 0 0117 2zM6.667 3c.849 0 1.647.2 2.333.54-1.526.369-2.873 1.226-2.873 3.293S7.474 10.053 9 10.46a5.26 5.26 0 01-2.333.54C4.09 11 2 9.208 2 7s2.09-4 4.667-4zM17 3.999a1.5 1.5 0 100 2.999 1.5 1.5 0 000-3z",
    fillRule: "evenodd",
  },
];
export const Express = [
  {
    d:
      "M14 4a9 9 0 11-.001 18.001A9 9 0 0114 4zm0 3v6l4.461 4.012A6 6 0 0014 7zm-9.95 5a9.617 9.617 0 00-.029 1.668l.03.332H2v-2h2.05zm1.294-4c-.217.384-.389.72-.514 1.009a9.77 9.77 0 00-.277.72l-.09.271H1V8h4.344zM17 1l-.016 2.007L11 3V1h6z",
    fillRule: "evenodd",
  },
];
export const NextAppointment = [
  {
    d:
      "M8 2v2h8V2h2v2h1c1.1 0 2 .9 2 2v14c0 1.1-.9 2-2 2H5a2 2 0 01-2-2l.01-14c0-1.1.88-2 1.99-2h1V2h2zm11 7H5v11h14V9zm-7.076 1.424L16 14.5l-4.076 4.076-1.145-1.145 2.115-2.12H8V13.69h4.895l-2.116-2.12 1.145-1.145z",
    fillRule: "evenodd",
  },
];
export const RepairOrder = [
  {
    d:
      "M11.216 4.38a6 6 0 015.302-2.355.519.519 0 01.32.88l-2.917 2.917 1.027 3.231 3.23 1.022 2.917-2.913a.52.52 0 01.882.322 5.994 5.994 0 01-2.36 5.304 6.002 6.002 0 01-5.119 1.02c-4.555 4.505-7.002 6.93-7.34 7.276a3 3 0 01-4.338-4.145l7.395-7.35a5.994 5.994 0 011-5.209zM5 18a1 1 0 100 2 1 1 0 000-2z",
    fillRule: "evenodd",
  },
];
export const Sales = [
  {
    d:
      "M21.41 11.58l-9-9C12.05 2.22 11.55 2 11 2H4c-1.1 0-2 .9-2 2v7c0 .55.22 1.05.59 1.42l9 9c.36.36.86.58 1.41.58.55 0 1.05-.22 1.41-.59l7-7c.37-.36.59-.86.59-1.41 0-.55-.23-1.06-.59-1.42zM5.5 7C4.67 7 4 6.33 4 5.5S4.67 4 5.5 4 7 4.67 7 5.5 6.33 7 5.5 7z",
    fillRule: "evenodd",
  },
];
export const Scheduling = [
  {
    d:
      "M18.402 17.28h-.715c-.531 0-.773-.219-.809-.731-.003-.04-.002-.08-.002-.121 0-2.142-.001-4.283.003-6.425 0-.144.021-.294.07-.43.1-.276.29-.457.648-.42 0-.211.012-.412-.002-.61-.146-2.123-1.67-3.817-3.841-4.262a3.716 3.716 0 00-.721-.083c-.76-.005-1.525-.047-2.278.028-1.504.15-2.676.87-3.508 2.08-.588.854-.816 1.803-.76 2.82.369.055.617.286.622.84.01 1.238.004 2.475.004 3.713v2.757c0 .21-.035.412-.157.593a.526.526 0 01-.472.253c-.802-.006-1.605-.014-2.407.001-.57.011-1.01-.214-1.364-.617-.43-.492-.672-1.074-.689-1.707a60.51 60.51 0 01.003-3.513c.022-.663.287-1.27.768-1.767.33-.34.73-.543 1.23-.53.051.002.103-.004.176-.007v-.26c-.004-2.025.77-3.73 2.318-5.105a6.874 6.874 0 013.634-1.68 11.16 11.16 0 011.419-.09 21.94 21.94 0 011.74.023c1.47.09 2.781.592 3.924 1.479 1.37 1.062 2.221 2.43 2.532 4.09.084.448.079.913.114 1.37.004.054 0 .108 0 .17.133.01.249.009.361.027.505.083.871.368 1.158.756.423.573.587 1.223.593 1.914.008.909.008 1.818 0 2.727-.005.748-.18 1.453-.677 2.053-.36.435-.815.695-1.418.665-.146-.008-.293-.002-.463-.002v.22c-.01.468.027.937-.085 1.402-.297 1.235-1.429 2.173-2.747 2.253-.332.02-.665.015-.997.016-.094 0-.146.018-.184.116-.171.44-.581.71-1.072.712-.692.002-1.384.003-2.075 0-.634-.003-1.133-.481-1.136-1.088-.002-.212-.02-.428.016-.635.094-.53.543-.893 1.11-.901.702-.01 1.405-.015 2.106.001.498.012.86.25 1.047.702.034.08.072.118.17.113.41-.02.83.018 1.229-.056a1.923 1.923 0 001.58-1.778c.022-.352.004-.705.004-1.077",
    fillRule: "evenodd",
  },
];
export const ServiceLift = [
  {
    d:
      "M13.863 2.098c.196.098 1.666 1.078 4.51 3.04h3.137c.49 0 .49.49.49.49v2.745h-.98A2.063 2.063 0 0119.625 10H21a1 1 0 010 2h-7v10h-4V12H3a1 1 0 010-2l3.24.002c-.72-.265-1.274-.892-1.495-1.63H2V5.628c0-.098 0-.196.098-.294.098-.098.196-.098.294-.098h1.373c1.568-1.765 2.549-2.844 2.745-3.04.257-.171.515-.193.64-.196h6.419c.098 0 .196 0 .294.098zm2.647 6.275H9.255c-.147.736-.738 1.362-1.48 1.628h10.19c-.735-.264-1.234-.89-1.455-1.628zM7 6.51a1.34 1.34 0 00-1.373 1.372A1.34 1.34 0 007 9.255a1.34 1.34 0 001.373-1.373A1.34 1.34 0 007 6.51zm11.765 0a1.34 1.34 0 00-1.373 1.372 1.34 1.34 0 001.373 1.373 1.34 1.34 0 001.372-1.373 1.34 1.34 0 00-1.372-1.372zM9.647 3.373H7.294L5.627 5.137h4.02V3.373zm3.922 0h-2.451v1.764h5l-2.55-1.764z",
    fillRule: "evenodd",
  },
];
export const VehicleLeaving = [
  {
    d:
      "M17.995 12A5.003 5.003 0 0123 17c0 2.76-2.24 5-5.005 5A4.998 4.998 0 0113 17c0-2.76 2.235-5 4.995-5zM19 14l-.917.917 1.326 1.325H15v1.516h4.41l-1.327 1.325L19 20l3-3-3-3zM16.306 2c.646 0 1.202.385 1.447.942l.051.131L20 9.437l.001.853a7 7 0 00-9 6.585H4v1.063c0 .545-.365.998-.886 1.056L3 19H2c-.542 0-.943-.416-.994-.947L1 17.937v-8.5l2.196-6.364a1.591 1.591 0 011.351-1.066L4.694 2h11.612zM4.694 10.5c-.876 0-1.583.712-1.583 1.594 0 .882.707 1.594 1.583 1.594.877 0 1.584-.712 1.584-1.594 0-.882-.707-1.594-1.584-1.594zm11.612-6.906H4.694L3.111 8.375H17.89l-1.583-4.781z",
    fillRule: "evenodd",
  },
];
export const WaitingWarranty = [
  {
    d:
      "M8.875 1l7.875 3.5v5.25c0 .82-.096 1.632-.277 2.419a7 7 0 00-5.46 7.264 9.222 9.222 0 01-2.138.817C4.36 19.148 1 14.606 1 9.75V4.5L8.875 1zm4.016 5.758l-5.766 5.766-2.266-2.258L3.625 11.5l3.5 3.5 7-7-1.234-1.242z",
  },
  {
    d:
      "M17.995 14A5.003 5.003 0 0123 19c0 2.76-2.24 5-5.005 5A4.998 4.998 0 0113 19c0-2.76 2.235-5 4.995-5zm.505 2H17v3.375l3.257 2 .767-1.227-2.524-1.422V16z",
  },
];
export const Warranty = [
  {
    d:
      "M12 1L3 5v6c0 5.55 3.84 10.74 9 12 5.16-1.26 9-6.45 9-12V5l-9-4zm-2 16l-4-4 1.41-1.41L10 14.17l6.59-6.59L18 9l-8 8z",
    fillRule: "evenodd",
  },
];
export const Alignment = [
  {
    d:
      "M4.586 16.148h1.533c.374-.003.502.196.502.444v5.105c0 .247-.125.45-.502.45h-2c-.38 0-.498-.198-.498-.45v-5.105c0-.242.125-.444.499-.444h.466zm13 0h1.533c.374-.003.502.196.502.444v5.105c0 .247-.125.45-.502.45h-2c-.38 0-.498-.198-.498-.45v-5.105c0-.242.125-.444.499-.444h.466zm-1.965-12l-.5 2h-2.5v3c1.333.666 2 1.666 2 3 0 1.259-.595 2.22-1.784 2.885l-.216.115v3h3v2h-8v-2h3v-3c-1.334-.667-2-1.667-2-3 0-1.26.594-2.222 1.784-2.886l.216-.114v-3h-3l.5-2h7.5zm-4 7a1 1 0 100 2 1 1 0 000-2zM4.969 2.022c.356.092 1.547.4 1.905.49.358.089.415.348.336.634l-.82 2.942-.497 1.788c-.08.284-.264.489-.623.396l-1.905-.49c-.364-.092-.412-.35-.332-.64l1.317-4.73c.078-.279.263-.481.619-.39zm13 0c.356.092 1.547.4 1.905.49.358.089.415.348.336.634l-.82 2.942-.497 1.788c-.08.284-.264.489-.623.396l-1.905-.49c-.364-.092-.412-.35-.332-.64l1.317-4.73c.078-.279.263-.481.619-.39z",
    fillRule: "evenodd",
  },
];
export const BodyStyleSedan = [
  {
    d:
      "M6.737 7l.084.002L10.18 7h3.501c.064 0 .201.028.326.095.181.098 1.847 1.233 4.995 3.404h3.5c.5 0 .5.5.5.5v3h-1.047a2.504 2.504 0 01-2.449 2 2.504 2.504 0 01-2.449-2H8.956a2.504 2.504 0 01-2.449 2 2.504 2.504 0 01-2.449-2H1v-2.99c0-.144.05-.267.153-.368a.471.471 0 01.372-.142h1.478c1.772-2.005 2.772-3.112 3-3.322.248-.209.614-.176.818-.176L6.737 7zm-.23 5c-.826 0-1.499.673-1.499 1.5s.673 1.5 1.5 1.5c.826 0 1.5-.673 1.5-1.5s-.674-1.5-1.5-1.5zm13 0c-.826 0-1.499.673-1.499 1.5s.673 1.5 1.5 1.5c.826 0 1.5-.673 1.5-1.5s-.674-1.5-1.5-1.5zM9.504 8.5h-2.75l-1.75 2h4.5v-2zm4.25 0h-2.75v2h5.5l-2.75-2z",
    fillRule: "evenodd",
  },
];
export const Engine = [
  {
    d:
      "M20 9.004V11h-2l-4-2-2-.003V7h2V5H8v2h2v1.997c-.87 0-1.534.003-1.992.007C6.822 9.004 6 9.985 6 11v2H4v-1l-2 .007V18h2v-1h2l2 3h8c1.017 0 2-.832 2-2h2v2h2V9.004h-2z",
    fillRule: "evenodd",
  },
];
export const LicensePlate = [
  {
    d:
      "M22 5c1.05 0 1.918.82 1.994 1.851L24 7v10c0 1.05-.82 1.918-1.851 1.994L22 19H2C.95 19 .082 18.18.006 17.149L0 17V7c0-1.05.82-1.918 1.851-1.994L2 5h20zM3 7H2v10h20V7h-1a2 2 0 01-3.995.15L17 7H7a2 2 0 11-4 0zm16.111 4c.451 0 .827.303.882.7l.007.1v.8c0 .41-.34.745-.778.794l-.11.006h-.89v.8H20v.8h-2.667v-1.6c0-.41.341-.745.778-.794l.111-.006h.89v-.8h-1.779V11h1.778zm-2.444 0v4h-1v-3.2h-1V11h2zm-10 0a.67.67 0 01.66.568l.006.099V15h-1v-1H5v1H4v-3.333a.67.67 0 01.568-.66L4.667 11h2zm3.666 0c.496 0 .933.345.993.76l.007.097v.572c0 .215-.25.417-.572.571.281.135.508.306.56.491l.012.08v.572l-.007.097c-.055.38-.427.702-.87.753l-.123.007H8v-4h2.333zm0 2.333H9v.666l1.333.001v-.667zm3.667-1v1.334h-1.333v-1.334H14zM6.333 12H5v1.333h1.333V12zM9 11.999v.667h1.333V12L9 11.999zM5 6a1 1 0 100 2 1 1 0 000-2zm14 0a1 1 0 100 2 1 1 0 000-2z",
    fillRule: "evenodd",
  },
];
export const Odometer = [
  {
    d:
      "M22 4c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H2c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2h20zm-5 1c-.108.003-1 .056-1 1v12l.009.158C16.108 19 17 19 17 19h2.5v-4.5H18V13h3v6h1c.108-.003 1-.056 1-1V6l-.009-.158c-.093-.792-.89-.84-.991-.842v3.5l-.006.154c-.064.754-.593 1.277-1.341 1.34L20.5 10h-2l-.153-.006c-.748-.063-1.277-.586-1.34-1.34L17 8.5V5zm-2 2H9v2h4l-4 8h2l4-8V7zM5 7H1v2h4v2H3v2h2v2H1v2h4a2 2 0 002-2v-1.5c0-.83-.67-1.5-1.5-1.5.83 0 1.5-.67 1.5-1.5V9a2 2 0 00-2-2zm15.5-2h-2v3.5h2V5z",
    fillRule: "evenodd",
  },
];
export const Rental = [
  {
    d:
      "M19.982 7c.141 0 .26.055.359.165.098.11.2.298.305.564L22 12.521v5.78a.82.82 0 01-.226.506c-.137.148-.292.21-.466.186h-.662c-.17.03-.323-.033-.46-.186a.672.672 0 01-.186-.505v-.893h-5v-6.053h5.646l-.922-3.184L15 8.17V7h4.982zm-.539 6.04a.967.967 0 00-.494.137 1.15 1.15 0 00-.544.995c0 .625.465 1.132 1.038 1.132.574 0 1.039-.507 1.039-1.132 0-.625-.465-1.132-1.039-1.132zm-7.612 6.943c-.774 0-2.717.005-5.831.017V4h5.83C13.009 4 13 4.006 13 5.07v13.871c0 1.055-.01 1.042-1.17 1.042zM4 20H2.978c-.63 0-.978.046-.978-1.032V5.06C2 4.009 2 4 2.978 4H4v16z",
    fillRule: "evenodd",
  },
];
export const Tire = [
  {
    d:
      "M13 16.472c.614-1.098 1-2.695 1-4.472 0-1.777-.386-3.374-1-4.472C13.53 6.578 14.232 6 15 6c1.657 0 3 2.686 3 6s-1.343 6-3 6c-.768 0-1.47-.578-2-1.528zm3.084-14.034c-2.723-.584-5.37-.584-8.168 0C5.42 2.892 3 6.722 3 11.98c0 5.257 2.42 9.087 4.916 9.606 2.798.52 5.445.584 8.168 0 2.42-.52 4.916-4.284 4.916-9.542 0-5.322-2.496-9.087-4.916-9.606zM15 19c-2.182 0-4-3.133-4-7s1.818-7 4-7 4 3.133 4 7-1.745 7-4 7z",
    fillRule: "evenodd",
  },
];
export const VehicleBus = [
  {
    d:
      "M4 16.5c0 .88.39 1.67 1 2.22v1.78c0 .55.45 1 1 1h1c.55 0 1-.45 1-1v-1h8v1c0 .55.45 1 1 1h1c.55 0 1-.45 1-1v-1.78c.61-.55 1-1.34 1-2.22v-10c0-3.5-3.58-4-8-4s-8 .5-8 4v10zm3.5 1c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5S9 15.17 9 16s-.67 1.5-1.5 1.5zm9 0c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zm1.5-6H6v-5h12v5z",
    fillRule: "evenodd",
  },
];
export const VehicleCar = [
  {
    d:
      "M18.92 5.01C18.72 4.42 18.16 4 17.5 4h-11c-.66 0-1.21.42-1.42 1.01L3 11v8c0 .55.45 1 1 1h1c.55 0 1-.45 1-1v-1h12v1c0 .55.45 1 1 1h1c.55 0 1-.45 1-1v-8l-2.08-5.99zM6.5 15c-.83 0-1.5-.67-1.5-1.5S5.67 12 6.5 12s1.5.67 1.5 1.5S7.33 15 6.5 15zm11 0c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zM5 10l1.5-4.5h11L19 10H5z",
    fillRule: "evenodd",
  },
];
export const VehicleLoaner = [
  {
    d:
      "M17 2a4.998 4.998 0 012 9.578v1.915l-1.5 1.499 1.5 1.5-1.5 1.498 1.5 1.5-2 2.498L15 22V11.578A4.998 4.998 0 0117 2zM5.805 5.995L6 5.998h3.675l.398.002c-.049.24-.073.574-.073 1.003 0 .428.025.76.074.997H5.5l-1.367 3.994h7.96c.161.149.296.268.403.358.108.09.276.217.504.384v6.254H5v.999c0 .265-.096.5-.287.703a1.02 1.02 0 01-.713.296H3c-.316-.012-.565-.126-.745-.34A.988.988 0 012 19.99v-6.996l1.862-5.54c.185-.52.452-.885.8-1.093.36-.267.806-.388 1.338-.362l-.195-.003zM5.5 13.993a1.5 1.5 0 100 2.999 1.5 1.5 0 000-3zm11-9.994a1.5 1.5 0 100 2.999 1.5 1.5 0 000-3z",
    fillRule: "evenodd",
  },
];
export const VehicleShuttle = [
  {
    d:
      "M17 5H3a2 2 0 00-2 2v9h2c0 1.65 1.34 3 3 3s3-1.35 3-3h5.5c0 1.65 1.34 3 3 3s3-1.35 3-3H23v-5l-6-6zM3 11V7h4v4H3zm3 6.5c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zm7-6.5H9V7h4v4zm4.5 6.5c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zM15 11V7h1l4 4h-5z",
    fillRule: "evenodd",
  },
];
export const WeightScale = [
  {
    d:
      "M12 2a4 4 0 013.874 5H18c1.039 0 1.706.727 1.922 1.442l.034.125 2.03 11.174a2.001 2.001 0 01-1.85 2.256L20 22H4a2.003 2.003 0 01-2-2.11l.015-.162L4.05 8.518c.197-.722.822-1.428 1.769-1.51L6 7h2.126A4 4 0 0112 2zm4.2 9H13v8h3.2a1.6 1.6 0 001.593-1.454l.007-.146v-1.2c0-.64-.56-1.2-1.2-1.2.6 0 1.13-.492 1.194-1.081l.006-.119v-1.2a1.6 1.6 0 00-1.6-1.6zm-8.406 0H6.2v6.4c0 .884.714 1.6 1.594 1.6H11v-1.6H7.794V11zm8.406 4.8v1.6h-1.6v-1.6h1.6zm0-3.2v1.6h-1.6v-1.6h1.6zM12 4a2 2 0 00-1.732 3h3.464A2 2 0 0012 4z",
    fillRule: "evenodd",
  },
];
export const BuildingBodyShop = [
  {
    d:
      "M22 2l2 3v3h-2v12h2l-.008 2H0l.008-2h1.983V8H0V5l1.991-3H22zM9.49 7.765l-2.928.467c-.483 0-.886.309-1.04.743L4 13.38v5.884c0 .405.33.736.732.736h.732a.736.736 0 00.732-.736v-.735l8.157-.023c.174.355.331.667.47.934.21.4.487.56.89.56h.731a.736.736 0 00.73-.673c.002-.02.028-.129-.034-.248a5.082 5.082 0 00-.03-.054l-.054-.099c-.122-.223-.371-.672-.746-1.348l-.996-2.355.919-1.843-.58-3.471a1.094 1.094 0 00-1.039-.743l-2.928.96L9.49 7.765zm-2.928 6.35A1.1 1.1 0 017.66 15.22a1.1 1.1 0 11-2.196 0 1.1 1.1 0 011.098-1.103zm6.432 0c.275 0 .49.098.437.266-.044.134-.19.414-.437.838.241.4.387.666.437.8.073.194-.138.303-.437.303a1.1 1.1 0 01-1.099-1.103 1.1 1.1 0 011.099-1.103zm4.826-3.667l-.856 1.161 2.18 1.803.856-1.16-2.18-1.804zM8.787 8.925l2.46 2.653 3.106-1.011.418 2.078H5.464l1.098-3.31 2.225-.41zM19.462 7l-2.706 1.218.537 1.352L20 8.352 19.462 7zm-8.375-1.797L10.035 6.25l1.983 2.052 1.03-1.022-1.961-2.077zM15.312 4l-.764 2.824 1.333.405.764-2.824L15.312 4z",
    fillRule: "evenodd",
  },
];
export const BuildingCorporateOffice = [
  {
    d:
      "M22 2H2v20h6v-4h8v4h6V2zm-2 4h-4V4h4v2zm-6 0h-4V4h4v2zM8 6H4V4h4v2zm12 4h-4V8h4v2zm-6 0h-4V8h4v2zm-6 0H4V8h4v2zm12 4h-4v-2h4v2zm-6 0h-4v-2h4v2zm-6 0H4v-2h4v2z",
    fillRule: "evenodd",
  },
];
export const BuildingDealer = [
  {
    d:
      "M24 8V5l-2-3H1.991L0 5v3h1.991v12H.008L0 22h23.992L24 20h-2V8h2zm-5.844 1a.84.84 0 01.799.568l1.17 3.37v4.5a.564.564 0 01-.563.562H19a.564.564 0 01-.563-.563v-.562h-6.75v.563a.564.564 0 01-.562.562h-.563a.564.564 0 01-.562-.563v-4.5l1.17-3.369A.846.846 0 0111.969 9h6.187zm-8.087 1l-.259.75H5.75L5 13h4.035l-.031.093L9.001 17H5.5v.5a.503.503 0 01-.41.492L5 18h-.5a.502.502 0 01-.492-.41L4 17.5v-4l1.04-2.995a.754.754 0 01.602-.497L5.75 10h4.319zm-4.32 4a.749.749 0 100 1.5.749.749 0 100-1.5zm6.22-.5a.843.843 0 100 1.688.843.843 0 100-1.688zm6.187 0a.843.843 0 100 1.688.843.843 0 100-1.688zm0-3.656H11.97l-.844 2.531H19l-.844-2.531z",
    fillRule: "evenodd",
  },
];
export const BuildingMakeReady = [
  {
    d:
      "M22 2l2 3v3h-2v12h2l-.008 2H0l.008-2h1.983V8H0V5l1.991-3H22zm-2.995 4.804c-2.867-2.293-5.678-2.4-8.435-.32-2.758 2.08-4.743 2.747-5.958 2.002-.85 1.422-.815 3.443.103 6.065.918 2.621 1.05 4.131.393 4.529 1.532.823 3.16 1.094 4.884.812-.016-.248.02-.4-.152-.588a1.443 1.443 0 00-.075-.08l.028.031.01.01-.326-.324-.432-.43-.538-.537-.339-.338-.567-.566-.418-.417L6 15.473s.646-.622.665-.628a.612.612 0 01.714-.067c.018.006 2.077 1.24 2.077 1.24v-6.005l.008-.102a.765.765 0 01.762-.653c.425 0 .77.338.77.755l.016 3.678.854-.146v-4.79l.007-.102A.765.765 0 0112.636 8c.43 0 .77.339.77.756v4.789l.918.012-.021-4.248.007-.102a.76.76 0 01.762-.654c.425 0 .77.339.77.756l-.01 4.596.525.106.313-2.79.003-.088c.03-.38.261-.69.774-.608.369.059.553.347.553.863l-.648 6.668-.007.169a2.11 2.11 0 01-.094.482c.27.097.524.22.761.373 1.958-1.531 2.47-3.708 1.535-6.532-.935-2.823-1.116-4.738-.542-5.744zM3 4c-.02.57-.212 1.04-.58 1.412-.367.37-.84.567-1.42.588a2.01 2.01 0 011.452.621A2.07 2.07 0 013 7.967l.001-.042c.007-.21.068-.812.556-1.304.388-.404.869-.61 1.443-.621a1.986 1.986 0 01-1.408-.588A1.94 1.94 0 013 4zm5-1a2.01 2.01 0 01-.621 1.452A2.072 2.072 0 016.033 5l.043.001c.208.007.811.068 1.303.556.404.388.61.87.621 1.443.01-.55.206-1.02.588-1.408A1.94 1.94 0 0110 5c-.57-.02-1.04-.212-1.412-.58C8.218 4.053 8.021 3.58 8 3z",
    fillRule: "evenodd",
  },
];
export const BuildingNetworkColo = [
  {
    d:
      "M22 2l2 3v3h-2v12h2l-.008 2H0l.008-2h1.983V8H0V5l1.991-3H22zm-5.556 7.333h-1.777v3.556h-8.89c-.977 0-1.777.8-1.777 1.778v3.555C4 19.2 4.8 20 5.778 20h12.444C19.2 20 20 19.2 20 18.222v-3.555c0-.978-.8-1.778-1.778-1.778h-1.778V9.333zm-8 6.223v1.777H6.667v-1.777h1.777zm3.112 0v1.777H9.778v-1.777h1.778zm3.11 0v1.777H12.89v-1.777h1.778zm.89-9.512c-1.067 0-2.134.445-2.934 1.245l.711.711c.623-.622 1.423-.889 2.223-.889.8 0 1.6.267 2.222.889l.71-.711c-.8-.8-1.866-1.245-2.932-1.245zm0-2.044c-1.6 0-3.2.622-4.445 1.867l.711.71c1.067-.977 2.4-1.51 3.734-1.51 1.333 0 2.666.533 3.733 1.51l.711-.71C18.756 4.622 17.156 4 15.556 4z",
    fillRule: "evenodd",
  },
];
export const BuildingPartsWarehouse = [
  {
    d:
      "M24 8V5l-2-3H1.991L0 5v3h1.991v12H.008L0 22h23.992L24 20h-2V8h2zm-8.5-2l3.5 6-3.5 6h-7L5 12l3.5-6h7zM12 9a3 3 0 100 6 3 3 0 000-6z",
    fillRule: "evenodd",
  },
];
export const BuildingPreownedBuilding = [
  {
    d:
      "M22 2l2 3v3h-2v12h2l-.008 2H0l.008-2h1.983V8H0V5l1.991-3H22zm-3.944 10h-6.112l-.12.008a.821.821 0 00-.668.497L10 15.5v4l.009.09c.047.232.275.41.547.41h.555l.1-.008c.258-.043.456-.248.456-.492V19h6.666v.5l.01.09c.047.232.274.41.546.41h.555l.1-.008c.258-.043.456-.248.456-.492v-4l-1.156-2.995-.044-.095a.846.846 0 00-.744-.41zM12 16a1 1 0 110 2 1 1 0 110-2zm6 0a1 1 0 110 2 1 1 0 110-2zm-9.531-1.066l-.802 1.42c.42.204.867.364 1.332.473V15.14a4.846 4.846 0 01-.53-.206zm-1.844-1.47l-1.406.831a6.523 6.523 0 001.596 1.564l.803-1.424a4.915 4.915 0 01-.993-.97zM18.143 13L19 15h-8l.857-2h6.286zm-12.5-1.998H4.02a6.47 6.47 0 00.683 2.442l1.4-.827a4.836 4.836 0 01-.458-1.615zM17 4l-1.91 1.91-.201-.193A6.493 6.493 0 004.019 10h1.624a4.882 4.882 0 014.853-4.375c1.35 0 2.553.56 3.43 1.446L11.31 9.688H17V4z",
  },
];
export const BuildingQuickLane = [
  {
    d:
      "M24 8V5l-2-3H1.991L0 5v3h1.991v12H.008L0 22h23.992L24 20h-2V8h2zM13.455 6.182a6.546 6.546 0 11-.002 13.092 6.546 6.546 0 01.002-13.092zm0 2.182v4.363l3.244 2.918a4.364 4.364 0 00-3.244-7.282zM6.219 12a6.994 6.994 0 00-.022 1.213l.022.242H4.727V12H6.22zm.94-2.91c-.158.28-.283.525-.374.734-.06.14-.12.294-.179.46l-.087.261H4V9.091h3.16zM15.636 4l-.011 1.46-4.352-.005V4h4.363z",
    fillRule: "evenodd",
  },
];
export const BuildingServiceShop = [
  {
    d:
      "M24 8V5l-2-3H1.991L0 5v3h1.991v12H.008L0 22h23.992L24 20h-2V8h2zM11.45 6.664a4.2 4.2 0 013.712-1.648.363.363 0 01.225.615l-2.042 2.043.718 2.261 2.262.715 2.042-2.039a.363.363 0 01.617.226 4.196 4.196 0 01-5.235 4.427 1342.3 1342.3 0 00-5.138 5.093 2.1 2.1 0 01-3.037-2.902l5.176-5.144a4.196 4.196 0 01.7-3.647zM7.1 16.198a.7.7 0 100 1.4.7.7 0 000-1.4z",
    fillRule: "evenodd",
  },
];
export const DateRange = [
  {
    d:
      "M9 11H7v2h2v-2zm4 0h-2v2h2v-2zm4 0h-2v2h2v-2zm2-7h-1V2h-2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20a2 2 0 002 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H5V9h14v11z",
    fillRule: "evenodd",
  },
];
export const Event = [
  {
    d:
      "M17 13h-5v5h5v-5zM16 2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20a2 2 0 002 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2h-1V2h-2zm3 18H5V9h14v11z",
    fillRule: "evenodd",
  },
];
export const EventAvailable = [
  {
    d:
      "M16.53 12.06L15.47 11l-4.88 4.88-2.12-2.12-1.06 1.06L10.59 18l5.94-5.94zM19 4h-1V2h-2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20a2 2 0 002 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H5V9h14v11z",
    fillRule: "evenodd",
  },
];
export const EventBusy = [
  {
    d:
      "M9.31 18l2.44-2.44L14.19 18l1.06-1.06-2.44-2.44 2.44-2.44L14.19 11l-2.44 2.44L9.31 11l-1.06 1.06 2.44 2.44-2.44 2.44L9.31 18zM19 4h-1V2h-2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20a2 2 0 002 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H5V9h14v11z",
    fillRule: "evenodd",
  },
];
export const EventNote = [
  {
    d:
      "M17 11H7v2h10v-2zm2-7h-1V2h-2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20a2 2 0 002 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H5V9h14v11zm-5-5H7v2h7v-2z",
    fillRule: "evenodd",
  },
];
export const Today = [
  {
    d:
      "M19 4h-1V2h-2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20a2 2 0 002 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H5V9h14v11zM7 11h5v5H7v-5z",
    fillRule: "evenodd",
  },
];
export const Chat = [
  {
    d: "M20 2H4c-1.1 0-2 .9-2 2v18l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2z",
    fillRule: "evenodd",
  },
];
export const Comment = [
  {
    d:
      "M21.99 4c0-1.1-.89-2-1.99-2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h14l4 4-.01-18zM18 14H6v-2h12v2zm0-3H6V9h12v2zm0-3H6V6h12v2z",
    fillRule: "evenodd",
  },
];
export const CommentAdd = [
  {
    d:
      "M21.99 4c0-1.1-.89-2-1.99-2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h14l4 4-.01-18zM17 11h-4v4h-2v-4H7V9h4V5h2v4h4v2z",
    fillRule: "evenodd",
  },
];
export const Conversation = [
  {
    d:
      "M21 6h-2v9H6v2c0 .55.45 1 1 1h11l4 4V7c0-.55-.45-1-1-1zm-4 6V3c0-.55-.45-1-1-1H3c-.55 0-1 .45-1 1v14l4-4h10c.55 0 1-.45 1-1z",
    fillRule: "evenodd",
  },
];
export const Demo = [
  {
    fillRule: "evenodd",
    d:
      "M21 2H3c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h7v2H8v2h8v-2h-2v-2h7c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm0 2v12H3V4h18zM10 6v8l6-4-6-4z",
  },
];
export const Drafts = [
  {
    d:
      "M21.99 9.5c0-.72-.37-1.35-.94-1.7L12 2.5 2.95 7.8c-.57.35-.95.98-.95 1.7v10c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2l-.01-10zm-9.99 5L3.74 9.34 12 4.5l8.26 4.84L12 14.5z",
    fillRule: "evenodd",
  },
];
export const Link = [
  {
    d:
      "M3.9 12c0-1.71 1.39-3.1 3.1-3.1h4V7H7c-2.76 0-5 2.24-5 5s2.24 5 5 5h4v-1.9H7c-1.71 0-3.1-1.39-3.1-3.1zM8 13h8v-2H8v2zm9-6h-4v1.9h4c1.71 0 3.1 1.39 3.1 3.1 0 1.71-1.39 3.1-3.1 3.1h-4V17h4c2.76 0 5-2.24 5-5s-2.24-5-5-5z",
    fillRule: "evenodd",
  },
];
export const Mail = [
  {
    d:
      "M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z",
    fillRule: "evenodd",
  },
];
export const Message = [
  {
    d:
      "M20 2H4c-1.1 0-1.99.9-1.99 2L2 22l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-2 12H6v-2h12v2zm0-3H6V9h12v2zm0-3H6V6h12v2z",
    fillRule: "evenodd",
  },
];
export const MessageExpired = [
  {
    d:
      "M19 12a5 5 0 110 10 5 5 0 010-10zM18 2c1.05 0 1.918.82 1.994 1.851L20 4v5h-4v1.674a6.983 6.983 0 00-.604.324L3 11v2h10.255A6.968 6.968 0 0012 17H4l-4 4L.01 4c0-1.05.81-1.918 1.841-1.994L2 2h16zm1 11.667V17l2.478 2.229A3.334 3.334 0 0019 13.667zM20.6 10l-.009 1.3-3.291-.005V10h3.3zM17 6H3v2h14V6z",
    fillRule: "evenodd",
  },
];
export const MoveToInbox = [
  {
    d:
      "M19 3H4.99c-1.11 0-1.98.9-1.98 2L3 19c0 1.1.88 2 1.99 2H19c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 12h-4c0 1.66-1.35 3-3 3s-3-1.34-3-3H4.99V5H19v10zm-3-5h-2V7h-4v3H8l4 4 4-4z",
    fillRule: "evenodd",
  },
];
export const Phone = [
  {
    d:
      "M6.62 10.79c1.44 2.83 3.76 5.14 6.59 6.59l2.2-2.2c.27-.27.67-.36 1.02-.24 1.12.37 2.33.57 3.57.57.55 0 1 .45 1 1V20c0 .55-.45 1-1 1-9.39 0-17-7.61-17-17 0-.55.45-1 1-1h3.5c.55 0 1 .45 1 1 0 1.25.2 2.45.57 3.57.11.35.03.74-.25 1.02l-2.2 2.2z",
    fillRule: "evenodd",
  },
];
export const Reply = [
  {
    d: "M10 8.5v-4l-7 7 7 7v-4.1c5 0 8.5 1.6 11 5.1-1-5-4-10-11-11z",
    fillRule: "evenodd",
  },
];
export const ReplyAll = [
  {
    d:
      "M7 7.5v-3l-7 7 7 7v-3l-4-4 4-4zm6 1v-4l-7 7 7 7v-4.1c5 0 8.5 1.6 11 5.1-1-5-4-10-11-11z",
    fillRule: "evenodd",
  },
];
export const Send = [
  { d: "M1.51 21l20.99-9L1.51 3l-.01 7 15 2-15 2z", fillRule: "evenodd" },
];
export const SendEmail = [
  {
    id: "a",
    d:
      "M18 12a5 5 0 110 10 5 5 0 010-10zm-2.997 2L15 16.333 19 17l-4 .667.003 2.333L22 17l-6.997-3zM19 2c1.05 0 1.918.82 1.994 1.851L21 4v6.674a7 7 0 00-9.93 7.327L3.001 18c-1.05 0-1.918-.82-1.994-1.851L1 16l.01-12c0-1.05.81-1.918 1.841-1.994L3 2h16zm0 2l-8 5-8-5v2l8 5 8-5V4z",
  },
];
export const TextSent = [
  {
    d:
      "M19 11a5 5 0 110 10 5 5 0 010-10zm-1.353-9a2.327 2.327 0 011.674.698c.392.404.615.885.667 1.442l.012.213v4.718a6.977 6.977 0 00-5.898 1.928L3 11v2h9.674a7.039 7.039 0 00-.554 4.295H3.53L0 20.823V4.353a2.33 2.33 0 01.643-1.612 2.285 2.285 0 011.51-.732l.2-.009h15.294zm-1.644 11L16 15.333 20 16l-4 .667.003 2.333L23 16l-6.997-3zM17 6H3v2h14V6z",
    fillRule: "evenodd",
  },
];
export const TextSms = [
  {
    d:
      "M20 2H4c-1.1 0-1.99.9-1.99 2L2 22l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zM9 11H7V9h2v2zm4 0h-2V9h2v2zm4 0h-2V9h2v2z",
    fillRule: "evenodd",
  },
];
export const Assignment = [
  {
    d:
      "M19 4h-4.18C14.4 2.84 13.3 2 12 2c-1.3 0-2.4.84-2.82 2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm-7 0c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm2 14H7v-2h7v2zm3-4H7v-2h10v2zm0-4H7V8h10v2z",
    fillRule: "evenodd",
  },
];
export const Description = [
  {
    d:
      "M14 2H6c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zm2 16H8v-2h8v2zm0-4H8v-2h8v2zm-3-5V3.5L18.5 9H13z",
    fillRule: "evenodd",
  },
];
export const OtherReports = [
  {
    d:
      "M3 5v16h16v2H3c-1.1 0-2-.9-2-2V5h2zm18-4c1.1 0 2 .9 2 2v14c0 1.1-.9 2-2 2H7c-1.1 0-2-.9-2-2V3c0-1.1.9-2 2-2h14zm-6 12H9v2h6v-2zm4-4H9v2h10V9zm0-4H9v2h10V5z",
    fillRule: "evenodd",
  },
];
export const BarChart = [
  {
    d: "M10 20h4V4h-4v16zm-6 0h4v-8H4v8zM16 9v11h4V9h-4z",
    fillRule: "evenodd",
  },
];
export const BubbleChart = [
  {
    d:
      "M7.2 11.2a3.2 3.2 0 100 6.4 3.2 3.2 0 000-6.4zm7.6 4.8a2 2 0 100 4 2 2 0 000-4zm.4-12a4.8 4.8 0 100 9.6 4.8 4.8 0 000-9.6z",
    fillRule: "evenodd",
  },
];
export const DatavisDown = [
  {
    d: "M13.4 14.1l3.744-3.956L19 12l-7 7-7-7 1.856-1.856L10.6 14.1V5h2.8z",
    fillRule: "evenodd",
  },
];
export const DatavisDownFilled = [
  {
    d:
      "M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2zm1.429 12.143V4.857H10.57v9.286l-3.814-4.037L4.857 12 12 19.143 19.143 12l-1.894-1.894-3.82 4.037z",
    fillRule: "evenodd",
  },
];
export const DatavisDownOutline = [
  {
    d:
      "M12 1c6.075 0 11 4.925 11 11s-4.925 11-11 11S1 18.075 1 12 5.925 1 12 1zm0 2a9 9 0 100 18 9 9 0 000-18zm-1.4 2h2.8v9.1l3.744-3.956L19 12l-7 7-7-7 1.856-1.856L10.6 14.1V5z",
    fillRule: "evenodd",
  },
];
export const DatavisUp = [
  {
    d: "M10.6 9.9l-3.744 3.956L5 12l7-7 7 7-1.856 1.856L13.4 9.9V19h-2.8z",
    fillRule: "evenodd",
  },
];
export const DatavisUpFilled = [
  {
    d:
      "M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zM10.571 9.857v9.286h2.858V9.857l3.814 4.037 1.9-1.894L12 4.857 4.857 12l1.894 1.894 3.82-4.037z",
    fillRule: "evenodd",
  },
];
export const DatavisUpOutline = [
  {
    d:
      "M12 1c6.075 0 11 4.925 11 11s-4.925 11-11 11S1 18.075 1 12 5.925 1 12 1zm0 2a9 9 0 100 18 9 9 0 000-18zm0 2l7 7-1.856 1.856L13.4 9.9V19h-2.8V9.9l-3.744 3.956L5 12l7-7z",
    fillRule: "evenodd",
  },
];
export const PieChart = [
  {
    d:
      "M11 2v20c-5.07-.5-9-4.79-9-10s3.93-9.5 9-10zm2.03 0v8.99H22c-.47-4.74-4.24-8.52-8.97-8.99zm0 11.01V22c4.74-.47 8.5-4.25 8.97-8.99h-8.97z",
    fillRule: "evenodd",
  },
];
export const Timeline = [
  {
    d:
      "M23 8c0 1.1-.9 2-2 2a1.7 1.7 0 01-.51-.07l-3.56 3.55c.05.16.07.34.07.52 0 1.1-.9 2-2 2s-2-.9-2-2c0-.18.02-.36.07-.52l-2.55-2.55c-.16.05-.34.07-.52.07s-.36-.02-.52-.07l-4.55 4.56c.05.16.07.33.07.51 0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2c.18 0 .35.02.51.07l4.56-4.55C8.02 9.36 8 9.18 8 9c0-1.1.9-2 2-2s2 .9 2 2c0 .18-.02.36-.07.52l2.55 2.55c.16-.05.34-.07.52-.07s.36.02.52.07l3.55-3.56A1.7 1.7 0 0119 8c0-1.1.9-2 2-2s2 .9 2 2z",
    fillRule: "evenodd",
  },
];
export const TrendingDown = [
  {
    d: "M16 18l2.29-2.29-4.88-4.88-4 4L2 7.41 3.41 6l6 6 4-4 6.3 6.29L22 12v6z",
    fillRule: "evenodd",
  },
];
export const TrendingFlat = [
  { d: "M21.5 12l-4-4v3h-15v2h15v3z", fillRule: "evenodd" },
];
export const TrendingUp = [
  {
    d:
      "M16 6l2.29 2.29-4.88 4.88-4-4L2 16.59 3.41 18l6-6 4 4 6.3-6.29L22 12V6z",
    fillRule: "evenodd",
  },
];
export const DealerBarcodeLabelPrinter = [
  {
    d:
      "M19.383 2l-.026 10.91h1.81A1.83 1.83 0 0123 14.727v3.637c0 1-.825 1.818-1.833 1.818h-1.834V22H4.667v-1.818H2.833A1.831 1.831 0 011 18.364v-3.637c0-1 .825-1.818 1.833-1.818h1.845L4.683 2h14.7zM17.5 18.364h-11v1.818h11v-1.818zM20 15.5a1 1 0 100 2 1 1 0 000-2zM17.5 3.818h-11v10.91h11V3.817zm-.917.91v9.09h-1.375v-9.09h1.375zm-7.333 0v9.09H7.417v-9.09H9.25zm5.042 0V12H12V4.727h2.292zm-3.209 0V12h-.916V4.727h.916z",
    fillRule: "evenodd",
  },
];
export const DealerBarcodeReader = [
  {
    d:
      "M20 6c.55 0 1.324.153 2.046.812C22.768 7.47 23 8.352 23 9c0 .647-.238 1.605-1.01 2.239-.55.45-1.044.644-1.482.72.698 1.908 2.114 5.68 2.349 6.329.295.816.091 1.712-.23 1.712h-4.11c-.426-.066-.745-.418-.956-1.056-.318-.957.085-1.025-.178-1.96-.264-.933-.763-.743-1.016-1.464-.252-.722.182-1.196-.121-1.923-.303-.726-.791-.81-1.025-1.455a2.42 2.42 0 01-.046-.14L9 12c-.55 0-1-.563-1-1.25v-3.5C8 6.562 8.45 6 9 6h11zM3.752 4.22a.776.776 0 01.022 1.117c-.76.771-1.156 1.98-1.156 3.663 0 1.683.397 2.892 1.156 3.663.31.315.3.815-.022 1.117a.823.823 0 01-1.144-.022C1.525 12.658 1 11.059 1 9c0-2.06.525-3.658 1.608-4.758a.823.823 0 011.144-.022zm2.514 1.475a.66.66 0 01.021.99C5.79 7.162 5.524 7.922 5.524 9c0 1.078.265 1.838.763 2.315a.66.66 0 01-.02.99.81.81 0 01-1.078-.02C4.386 11.517 4 10.411 4 9s.386-2.517 1.189-3.285a.81.81 0 011.077-.02z",
    fillRule: "evenodd",
  },
];
export const DealerBwPrinter = [
  {
    d:
      "M19.383 2l-.026 10.91h1.81A1.83 1.83 0 0123 14.727v3.637c0 1-.825 1.818-1.833 1.818h-1.834V22H4.667v-1.818H2.833A1.831 1.831 0 011 18.364v-3.637c0-1 .825-1.818 1.833-1.818h1.845L4.683 2h14.7zM17.5 18.364h-11v1.818h11v-1.818zm3-2.864a1 1 0 100 2 1 1 0 000-2zm-3-11.682h-11v10.91h11V3.817zm-5.5.91c2.531 0 4.583 2.034 4.583 4.545 0 2.51-2.052 4.545-4.583 4.545s-4.583-2.035-4.583-4.545S9.469 4.727 12 4.727zm0 1.817V12c1.519 0 2.75-1.221 2.75-2.727A2.739 2.739 0 0012 6.545z",
    fillRule: "evenodd",
  },
];
export const DealerColorPrinter = [
  {
    d:
      "M19.383 2l-.026 10.91h1.81A1.83 1.83 0 0123 14.727v3.637c0 1-.825 1.818-1.833 1.818h-1.834V22H4.667v-1.818H2.833A1.831 1.831 0 011 18.364v-3.637c0-1 .825-1.818 1.833-1.818h1.845L4.683 2h14.7zM17.5 18.364h-11v1.818h11v-1.818zm2.75-2.947a1 1 0 100 2 1 1 0 000-2zM10.535 3.818H6.5v10.91h11V3.817h-4.045l-.296 2.724A2.634 2.634 0 0012 6.265a2.645 2.645 0 00-1.16.284l-.305-2.73zM12 7.795a2.282 2.282 0 012.292 2.273c.015 1.778-1.575 3.41-2.292 3.41-.737 0-.262-.464-.844-1.16-.336-.402-1.442-.79-1.448-2.25C9.703 8.813 10.734 7.795 12 7.795z",
    fillRule: "evenodd",
  },
];
export const DealerColorPrinter2 = [
  {
    d:
      "M18 2v8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v2H6v-2H4a2 2 0 01-2-2v-6a2 2 0 012-2h2V2h12zM6 12H4v6h16v-6h-2v2H6v-2zm11 3a1 1 0 110 2 1 1 0 010-2zM12 4a4 4 0 100 8 .667.667 0 00.667-.667.652.652 0 00-.174-.444.667.667 0 01.498-1.111h.787A2.222 2.222 0 0016 7.556C16 5.59 14.209 4 12 4zm-1.5 4a1 1 0 110 2 1 1 0 010-2zm3-1.5a1 1 0 110 2 1 1 0 010-2zM11 5a1 1 0 110 2 1 1 0 010-2z",
    fillRule: "evenodd",
  },
];
export const DealerColorPrinter3 = [
  {
    d:
      "M18 2v8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v2H6v-2H4a2 2 0 01-2-2v-6a2 2 0 012-2h2V2h12zM6 12H4v6h16v-6h-2v2H6v-2zm11 3a1 1 0 110 2 1 1 0 010-2zm-5-7c-1.333 1.264-2 2.264-2 3a2 2 0 104 0c0-.736-.667-1.736-2-3zM9 3C7.667 4.264 7 5.264 7 6a2 2 0 104 0c0-.736-.667-1.736-2-3zm6 0c-1.333 1.264-2 2.264-2 3a2 2 0 104 0c0-.736-.667-1.736-2-3z",
    fillRule: "evenodd",
  },
];
export const DealerComputer = [
  {
    d:
      "M14 4c1.1 0 2 .9 2 2v8c0 1.1-.9 2-2 2h-4v2h2v2H4v-2h2v-2H2c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2h12zm9 0c.51 0 .935.388.993.884L24 5v14c0 .51-.388.935-.884.993L23 20h-4c-.51 0-.935-.388-.993-.884L18 19V5c0-.51.388-.935.884-.993L19 4h4zm-2 12a1 1 0 100 2 1 1 0 000-2zM14 6H2v8h12V6zm9 4h-4v2h4v-2zm0-4h-4v2h4V6z",
    fillRule: "evenodd",
  },
];
export const DealerDocumentScanner = [
  {
    d:
      "M19.8 10.7L4.2 5l-.7 1.9L17.6 12H5c-1.1 0-2 .9-2 2v4c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-5.5c0-.8-.5-1.6-1.2-1.8zM7 17H5v-2h2v2zm12 0H9v-2h10v2z",
    fillRule: "evenodd",
  },
];
export const DealerImpactPrinter = [
  {
    d:
      "M18 2v11h1c1.122-.087 1.924.655 1.995 1.592l.005.135v.773h1v-1h1c.51 0 .935.388.993.884L24 15.5v2c0 .51-.388.935-.884.993L23 18.5h-1v-1h-1v.5c0 1.302-.911 1.94-1.864 1.996L19 20h-1v2H4v-2H3c-.955 0-1.91-.58-1.994-1.819L1 18v-3c0-1.217.755-2.019 1.849-2.007L3 13h1V2h14zm-2 16l-.543.001a.75.75 0 01-1.414 0H7.957a.75.75 0 01-1.414 0L6 18v2h.543a.75.75 0 011.414 0h6.086a.75.75 0 011.414 0H16v-2zm2-2.5a1 1 0 100 2 1 1 0 000-2zM16 4H6v11h.543a.75.75 0 011.414 0h6.086a.75.75 0 011.414 0H16V4zm-8.75 8a.75.75 0 110 1.5.75.75 0 010-1.5zm7.5 0a.75.75 0 110 1.5.75.75 0 010-1.5zm-7.5-2.5a.75.75 0 110 1.5.75.75 0 010-1.5zm7.5 0a.75.75 0 110 1.5.75.75 0 010-1.5zM7.25 7a.75.75 0 110 1.5.75.75 0 010-1.5zm7.5 0a.75.75 0 110 1.5.75.75 0 010-1.5zm-7.5-2.5a.75.75 0 110 1.5.75.75 0 010-1.5zm7.5 0a.75.75 0 110 1.5.75.75 0 010-1.5z",
    fillRule: "evenodd",
  },
];
export const DealerMagneticInkPrinter = [
  {
    d:
      "M19.383 2l-.026 10.91h1.81A1.83 1.83 0 0123 14.727v3.637c0 1-.825 1.818-1.833 1.818h-1.834V22H4.667v-1.818H2.833A1.831 1.831 0 011 18.364v-3.637c0-1 .825-1.818 1.833-1.818h1.845L4.683 2h14.7zM17.5 18.364h-11v1.818h11v-1.818zm2.833-2.864a1 1 0 100 2 1 1 0 000-2zM17.5 3.818h-11v10.91h11V3.817zm-1.833 7.273v1.818h-1.834v-1.818h1.834zm-5.5 0v1.818H8.333v-1.818h1.834zM12 5.636c2.025 0 3.667 1.603 3.667 3.637v.909h-1.834v-.91l-.005-.165c-.067-1.031-.76-1.652-1.828-1.652-1.125 0-1.833.688-1.833 1.818v.909H8.333v-.91l.005-.195c.102-1.94 1.702-3.44 3.662-3.44z",
    fillRule: "evenodd",
  },
];
export const DealerPhone = [
  {
    d:
      "M20.01 15.38c-1.23 0-2.42-.2-3.53-.56a.977.977 0 00-1.01.24l-1.57 1.97c-2.83-1.35-5.48-3.9-6.89-6.83l1.95-1.66c.27-.28.35-.67.24-1.02-.37-1.11-.56-2.3-.56-3.53 0-.54-.45-.99-.99-.99H4.19C3.65 3 3 3.24 3 3.99 3 13.28 10.73 21 20.01 21c.71 0 .99-.63.99-1.18v-3.45c0-.54-.45-.99-.99-.99z",
    fillRule: "evenodd",
  },
];
export const DealerStaff = [
  {
    d:
      "M10.205 13.151L12 17l1.8-3.848.035.005c2.66.413 5.975 1.639 6.157 3.667L20 17v4H4v-4c0-2.136 3.437-3.42 6.173-3.844l.032-.005zM18 17h-4v2h4v-2zM12 3c2.21 0 4 1.79 4 4s-1.79 4-4 4-4-1.79-4-4 1.79-4 4-4z",
    fillRule: "evenodd",
  },
];
export const AttachFile = [
  {
    d:
      "M16 6v11.5c0 2.21-1.79 4-4 4s-4-1.79-4-4V5a2.5 2.5 0 015 0v10.5c0 .55-.45 1-1 1s-1-.45-1-1V6H9.5v9.5a2.5 2.5 0 005 0V5c0-2.21-1.79-4-4-4s-4 1.79-4 4v12.5c0 3.04 2.46 5.5 5.5 5.5s5.5-2.46 5.5-5.5V6H16z",
    fillRule: "evenodd",
  },
];
export const ContentCopy = [
  {
    d:
      "M16.5 1h-12c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4h-11c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16h-11V7h11v14z",
    fillRule: "evenodd",
  },
];
export const ContentCut = [
  {
    d:
      "M9.64 7.64c.23-.5.36-1.05.36-1.64 0-2.21-1.79-4-4-4S2 3.79 2 6s1.79 4 4 4c.59 0 1.14-.13 1.64-.36L10 12l-2.36 2.36C7.14 14.13 6.59 14 6 14c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4c0-.59-.13-1.14-.36-1.64L12 14l7 7h3v-1L9.64 7.64zM6 8a2 2 0 11-.001-3.999A2 2 0 016 8zm0 12a2 2 0 11-.001-3.999A2 2 0 016 20zm6-7.5c-.28 0-.5-.22-.5-.5s.22-.5.5-.5.5.22.5.5-.22.5-.5.5zM19 3l-6 6 2 2 7-7V3h-3z",
    fillRule: "evenodd",
  },
];
export const ContentPaste = [
  {
    d:
      "M19 3h-4.18C14.4 1.84 13.3 1 12 1c-1.3 0-2.4.84-2.82 2H5c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7 0c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm7 18H5V5h2v3h10V5h2v16z",
    fillRule: "evenodd",
  },
];
export const DeviceLog = [
  {
    fillRule: "evenodd",
    d:
      "M18 2c1.05 0 1.918.82 1.994 1.851L20 4v6h-2V4H2v11h8v4H2C.95 19 .082 18.18.006 17.149L0 17V4c0-1.05.82-1.918 1.851-1.994L2 2h16zm4 10c1.05 0 1.918.82 1.994 1.851L24 14v8c0 1.05-.82 1.918-1.851 1.994L22 24h-8c-1.05 0-1.918-.82-1.994-1.851L12 22v-8c0-1.05.82-1.918 1.851-1.994L14 12h8zm-2 8h-6v2h6v-2zm2-3h-8v2h8v-2zm0-3h-8v2h8v-2z",
  },
];
export const FileDownload = [
  { d: "M19 9.5h-4v-6H9v6H5l7 7 7-7zm-14 9v2h14v-2H5z", fillRule: "evenodd" },
];
export const FileUpload = [
  { d: "M19 18.5v2H5v-2h14zm-7-15l7 7h-4v6H9v-6H5l7-7z", fillRule: "evenodd" },
];
export const Folder = [
  {
    d:
      "M10 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2h-8l-2-2z",
    fillRule: "evenodd",
  },
];
export const FolderCreateNew = [
  {
    d:
      "M20 6h-8l-2-2H4c-1.11 0-1.99.89-1.99 2L2 18c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V8c0-1.11-.89-2-2-2zm-1 8h-3v3h-2v-3h-3v-2h3V9h2v3h3v2z",
    fillRule: "evenodd",
  },
];
export const FolderOpen = [
  {
    d:
      "M20 6h-8l-2-2H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2zm0 12H4V8h16v10z",
    fillRule: "evenodd",
  },
];
export const FolderShared = [
  {
    d:
      "M20 6h-8l-2-2H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2zm-5 3c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2zm4 8h-8v-1c0-1.33 2.67-2 4-2s4 .67 4 2v1z",
    fillRule: "evenodd",
  },
];
export const FolderSpecial = [
  {
    d:
      "M20 6h-8l-2-2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2zm-2.06 11L15 15.28 12.06 17l.78-3.33-2.59-2.24 3.41-.29L15 8l1.34 3.14 3.41.29-2.59 2.24.78 3.33z",
    fillRule: "evenodd",
  },
];
export const NoteAdd = [
  {
    d:
      "M14 2H6c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zm2 14h-3v3h-2v-3H8v-2h3v-3h2v3h3v2zm-3-7V3.5L18.5 9H13z",
    fillRule: "evenodd",
  },
];
export const Save = [
  {
    d:
      "M17 3H5a2 2 0 00-2 2v14a2 2 0 002 2h14c1.1 0 2-.9 2-2V7l-4-4zm-5 16c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3zm3-10H5V5h10v4z",
    fillRule: "evenodd",
  },
];
export const ThreatLog = [
  {
    fillRule: "evenodd",
    d:
      "M12 0c.276.007.511.107.707.3a.94.94 0 01.285.567L13 1l.003 2.034.002.021a9.008 9.008 0 017.71 6.686l.062.259H18.71A7 7 0 1010 18.71v2.067A9.003 9.003 0 013 12H1c-.308-.02-.55-.122-.724-.307A1.027 1.027 0 010 11c0-.272.099-.507.296-.705a.955.955 0 01.571-.287L1 10h2.2l.021.008a9.007 9.007 0 017.783-6.953L11 3l.004-2c0-.273.093-.506.28-.7A.979.979 0 0112 0zm0 7a5.001 5.001 0 014.584 3H12c-1.1 0-2 .9-2 2v4.584A5.001 5.001 0 0112 7zm10 5c1.05 0 1.918.82 1.994 1.851L24 14v8c0 1.05-.82 1.918-1.851 1.994L22 24h-8c-1.05 0-1.918-.82-1.994-1.851L12 22v-8c0-1.05.82-1.918 1.851-1.994L14 12h8zm-2 8h-6v2h6v-2zm2-3h-8v2h8v-2zm0-3h-8v2h8v-2z",
  },
];
export const AddLocation = [
  {
    d:
      "M12 2C8.14 2 5 5.14 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.86-3.14-7-7-7zm4 8h-3v3h-2v-3H8V8h3V5h2v3h3v2z",
    fillRule: "evenodd",
  },
];
export const LocationOff = [
  {
    d:
      "M13 6.5A2.5 2.5 0 0115.5 9c0 .74-.33 1.39-.83 1.85l3.63 3.63c.98-1.86 1.7-3.8 1.7-5.48 0-3.87-3.13-7-7-7a7 7 0 00-5.04 2.15l3.19 3.19c.46-.52 1.11-.84 1.85-.84zm4.37 9.6l-4.63-4.63-.11-.11L4.27 3 3 4.27l3.18 3.18C6.07 7.95 6 8.47 6 9c0 5.25 7 13 7 13s1.67-1.85 3.38-4.35L19.73 21 21 19.73l-3.63-3.63z",
    fillRule: "evenodd",
  },
];
export const LocationOn = [
  {
    d:
      "M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5a2.5 2.5 0 010-5 2.5 2.5 0 010 5z",
    fillRule: "evenodd",
  },
];
export const Apps = [
  {
    d:
      "M4 8h4V4H4v4zm6 12h4v-4h-4v4zm-6 0h4v-4H4v4zm0-6h4v-4H4v4zm6 0h4v-4h-4v4zm6-10v4h4V4h-4zm-6 4h4V4h-4v4zm6 6h4v-4h-4v4zm0 6h4v-4h-4v4z",
    fillRule: "evenodd",
  },
];
export const ArrowBack = [
  {
    d: "M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20z",
    fillRule: "evenodd",
  },
];
export const ArrowCascadeLeft = [
  { d: "M14.5 7l-5 5 5 5z", fillRule: "evenodd" },
];
export const ArrowCascadeRight = [
  { d: "M9.5 17l5-5-5-5z", fillRule: "evenodd" },
];
export const ArrowDownward = [
  {
    d: "M20 12l-1.41-1.41L13 16.17V4h-2v12.17l-5.58-5.59L4 12l8 8z",
    fillRule: "evenodd",
  },
];
export const ArrowDropDown = [{ d: "M7 9.5l5 5 5-5z", fillRule: "evenodd" }];
export const ArrowDropUp = [{ d: "M7 14.5l5-5 5 5z", fillRule: "evenodd" }];
export const ArrowForward = [
  {
    d: "M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z",
    fillRule: "evenodd",
  },
];
export const ArrowUpward = [
  {
    d: "M4 12l1.41 1.41L11 7.83V20h2V7.83l5.58 5.59L20 12l-8-8z",
    fillRule: "evenodd",
  },
];
export const ChevronLeft = [
  {
    d: "M15.705 7.41L14.295 6l-6 6 6 6 1.41-1.41-4.58-4.59z",
    fillRule: "evenodd",
  },
];
export const ChevronRight = [
  {
    d: "M9.705 6l-1.41 1.41 4.58 4.59-4.58 4.59L9.705 18l6-6z",
    fillRule: "evenodd",
  },
];
export const ExpandLess = [
  {
    d: "M12 8.295l-6 6 1.41 1.41 4.59-4.58 4.59 4.58 1.41-1.41z",
    fillRule: "evenodd",
  },
];
export const ExpandMore = [
  {
    d: "M16.59 8.295L12 12.875l-4.59-4.58L6 9.705l6 6 6-6z",
    fillRule: "evenodd",
  },
];
export const Fullscreen = [
  {
    d:
      "M7 14H5v5h5v-2H7v-3zm-2-4h2V7h3V5H5v5zm12 7h-3v2h5v-5h-2v3zM14 5v2h3v3h2V5h-5z",
    fillRule: "evenodd",
  },
];
export const FullscreenExit = [
  {
    d:
      "M5 16h3v3h2v-5H5v2zm3-8H5v2h5V5H8v3zm6 11h2v-3h3v-2h-5v5zm2-11V5h-2v5h5V8h-3z",
    fillRule: "evenodd",
  },
];
export const Help = [
  {
    d:
      "M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 17h-2v-2h2v2zm2.07-7.75l-.9.92C13.45 12.9 13 13.5 13 15h-2v-.5c0-1.1.45-2.1 1.17-2.83l1.24-1.26c.37-.36.59-.86.59-1.41 0-1.1-.9-2-2-2s-2 .9-2 2H8c0-2.21 1.79-4 4-4s4 1.79 4 4c0 .88-.36 1.68-.93 2.25z",
    fillRule: "evenodd",
  },
];
export const Home = [
  { d: "M10 20.5v-6h4v6h5v-8h3l-10-9-10 9h3v8z", fillRule: "evenodd" },
];
export const Info = [
  {
    id: "a",
    d:
      "M12 2c5.52 0 10 4.48 10 10s-4.48 10-10 10S2 17.52 2 12 6.48 2 12 2zm1 9h-2v6h2v-6zm0-4h-2v2h2V7z",
  },
];
export const Menu = [
  { d: "M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z", fillRule: "evenodd" },
];
export const MoreHoriz = [
  {
    d:
      "M6 10c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm12 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm-6 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z",
    fillRule: "evenodd",
  },
];
export const MoreVert = [
  {
    d:
      "M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z",
    fillRule: "evenodd",
  },
];
export const OpenInBrowser = [
  {
    d:
      "M19 4H5a2 2 0 00-2 2v12a2 2 0 002 2h4v-2H5V8h14v10h-4v2h4c1.1 0 2-.9 2-2V6a2 2 0 00-2-2zm-7 6l-4 4h3v6h2v-6h3l-4-4z",
    fillRule: "evenodd",
  },
];
export const OpenInNew = [
  {
    d:
      "M19 19H5V5h7V3H5a2 2 0 00-2 2v14a2 2 0 002 2h14c1.1 0 2-.9 2-2v-7h-2v7zM14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3h-7z",
    fillRule: "evenodd",
  },
];
export const PageFirst = [
  {
    d:
      "M18.205 16.59L13.615 12l4.59-4.59L16.795 6l-6 6 6 6 1.41-1.41zM5.795 6h2v12h-2V6z",
    fillRule: "evenodd",
  },
];
export const PageLast = [
  {
    d:
      "M5.795 7.41l4.59 4.59-4.59 4.59L7.205 18l6-6-6-6-1.41 1.41zM16.205 6h2v12h-2V6z",
    fillRule: "evenodd",
  },
];
export const Refresh = [
  {
    d:
      "M17.645 6.35A7.958 7.958 0 0011.995 4c-4.42 0-7.99 3.58-7.99 8s3.57 8 7.99 8c3.73 0 6.84-2.55 7.73-6h-2.08a5.99 5.99 0 01-5.65 4c-3.31 0-6-2.69-6-6s2.69-6 6-6c1.66 0 3.14.69 4.22 1.78L12.995 11h7V4l-2.35 2.35z",
    fillRule: "evenodd",
  },
];
export const Search = [
  {
    d:
      "M15.755 14.255h-.79l-.28-.27a6.471 6.471 0 001.57-4.23 6.5 6.5 0 10-6.5 6.5c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99 1.49-1.49-4.99-5zm-6 0c-2.49 0-4.5-2.01-4.5-4.5s2.01-4.5 4.5-4.5 4.5 2.01 4.5 4.5-2.01 4.5-4.5 4.5z",
    fillRule: "evenodd",
  },
];
export const SubdirectoryArrowLeft = [
  {
    d:
      "M10.5 8.5l1.42 1.42-3.59 3.58h9.17v-10h2v12H8.33l3.59 3.58-1.42 1.42-6-6z",
    fillRule: "evenodd",
  },
];
export const SubdirectoryArrowRight = [
  {
    d:
      "M19.5 14.5l-6 6-1.42-1.42 3.59-3.58H4.5v-12h2v10h9.17l-3.59-3.58L13.5 8.5z",
    fillRule: "evenodd",
  },
];
export const UnfoldLess = [
  {
    d:
      "M7.41 18.59L8.83 20 12 16.83 15.17 20l1.41-1.41L12 14l-4.59 4.59zm9.18-13.18L15.17 4 12 7.17 8.83 4 7.41 5.41 12 10l4.59-4.59z",
    fillRule: "evenodd",
  },
];
export const UnfoldMore = [
  {
    d:
      "M12 5.83L15.17 9l1.41-1.41L12 3 7.41 7.59 8.83 9 12 5.83zm0 12.34L8.83 15l-1.41 1.41L12 21l4.59-4.59L15.17 15 12 18.17z",
    fillRule: "evenodd",
  },
];
export const NetworkingCdkDmsServer = [
  {
    d:
      "M20 13H4c-.55 0-1 .45-1 1v6c0 .55.45 1 1 1h16c.55 0 1-.45 1-1v-6c0-.55-.45-1-1-1zM7 19c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zM20 3H4c-.55 0-1 .45-1 1v6c0 .55.45 1 1 1h16c.55 0 1-.45 1-1V4c0-.55-.45-1-1-1zM7 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z",
    fillRule: "evenodd",
  },
];
export const NetworkingCloset = [
  {
    d:
      "M24 1v22l-6-2V3l6-2zM0 1l6 2v18l-6 2V1zm16 2c.51 0 .935.388.993.884L17 4v16c0 .51-.388.935-.884.993L16 21H8c-.51 0-.935-.388-.993-.884L7 20V4c0-.51.388-.935.884-.993L8 3h8zm-1 14h-2v2h2v-2zm-4 0H9v2h2v-2zm4-4H9v2h6v-2zm7-2a1 1 0 100 2 1 1 0 100-2zM2 11a1 1 0 100 2 1 1 0 100-2zm13-2H9v2h6V9z",
    fillRule: "evenodd",
  },
];
export const NetworkingCloudService = [
  {
    d:
      "M12 1c3.033 0 5.558 2.266 6.125 5.285 2.167.157 3.875 2.03 3.875 4.34C22 13.04 20.133 15 17.833 15H17v3h1.268a2 2 0 110 2.001L17 20c-.675-.009-1.175-.175-1.5-.5-.325-.325-.491-.825-.5-1.5v-3h-2l.001 4.268a2 2 0 11-2.001 0V15H9v3c.009.658-.158 1.158-.5 1.5-.342.342-.842.509-1.5.5l-1.268.001a2 2 0 110-2.001H7v-3c-2.758 0-5-2.354-5-5.25 0-2.704 1.95-4.935 4.458-5.215C7.5 2.435 9.592 1 12 1z",
    fillRule: "evenodd",
  },
];
export const NetworkingCoverageArea = [
  {
    d:
      "M12 11.15c-1.1 0-2 .917-2 2.038 0 1.12.9 2.037 2 2.037s2-.917 2-2.037c0-1.121-.9-2.038-2-2.038zm6 2.038c0-3.372-2.69-6.113-6-6.113s-6 2.74-6 6.113a6.13 6.13 0 003 5.287l1-1.773a4.086 4.086 0 01-2-3.514c0-2.252 1.79-4.075 4-4.075s4 1.823 4 4.075c0 1.507-.81 2.801-2 3.514l1 1.773a6.13 6.13 0 003-5.287zM12 3C6.48 3 2 7.564 2 13.188c0 3.77 2.01 7.05 4.99 8.812l1-1.762A8.184 8.184 0 014 13.188c0-4.503 3.58-8.15 8-8.15s8 3.647 8 8.15a8.171 8.171 0 01-4 7.05L17 22a10.2 10.2 0 005-8.812C22 7.564 17.52 3 12 3z",
    fillRule: "evenodd",
  },
];
export const NetworkingRoom = [
  {
    d:
      "M23 21v2H1v-2h22zM10 1c.51 0 .935.388.993.884L11 2v16c0 .51-.388.935-.884.993L10 19H2c-.51 0-.935-.388-.993-.884L1 18V2c0-.51.388-.935.884-.993L2 1h8zm12 0c.51 0 .935.388.993.884L23 2v16c0 .51-.388.935-.884.993L22 19h-8c-.51 0-.935-.388-.993-.884L13 18V2c0-.51.388-.935.884-.993L14 1h8zM9 15H7v2h2v-2zm-4 0H3v2h2v-2zm16 0h-2v2h2v-2zm-4 0h-2v2h2v-2zm-8-4H3v2h6v-2zm12 0h-6v2h6v-2zM9 7H3v2h6V7zm12 0h-6v2h6V7z",
    fillRule: "evenodd",
  },
];
export const NetworkingRouter = [
  {
    d:
      "M19 10h-2V6h-2v4H5c-1.1 0-2 .9-2 2v4c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-4c0-1.1-.9-2-2-2zM8 15H6v-2h2v2zm3.5 0h-2v-2h2v2zm3.5 0h-2v-2h2v2z",
    fillRule: "evenodd",
  },
];
export const NetworkingSdwan = [
  {
    d:
      "M18.125 7.285C17.558 4.266 15.033 2 12 2 9.592 2 7.5 3.435 6.458 5.535 3.95 5.815 2 8.046 2 10.75 2 13.646 4.242 16 7 16h1v2H6l4 4 4-4h-2v-4h-2l4-4 4 4h-2v2h1.833c2.3 0 4.167-1.96 4.167-4.375 0-2.31-1.708-4.183-3.875-4.34z",
    fillRule: "evenodd",
  },
];
export const NetworkingSecurity = [
  {
    d:
      "M17.494 3.302c.246-.1.522-.104.768-.01a.963.963 0 01.544.509l3.08 7.007c.206.484-.035 1.04-.542 1.253l-4.848 2.264.85 1.675h4.653v3.01h-5.505c-.577 0-.992.121-1.546-.767l-1.34-2.624-2.963 1.363c-.386.155-.83.07-1.117-.215l-6.276-6.272a.922.922 0 01-.242-.848.992.992 0 01.59-.688l13.894-5.657zM2.657 12.139l4.901 4.801-2.283.89c-.507.193-1.08-.01-1.292-.455l-1.87-4.065a.803.803 0 01.005-.68.98.98 0 01.539-.49zm11.183-1.126l-.114.026a1 1 0 10.548 1.923 1 1 0 00-.548-1.923l.114-.026z",
    fillRule: "evenodd",
  },
];
export const NetworkingServerFirewall = [
  {
    d:
      "M16 7l6 2.727v4.09c0 3.703-2.443 7.159-5.762 8.118l-.239.064c-3.44-.859-6-4.39-6-8.181V9.727l6-2.728zm0 1.646v11.79l.193-.064c2.374-.88 4.169-3.436 4.299-6.23l.007-.324v-3.126L16 8.646zM8.498 12v1.446l.004.28A10.137 10.137 0 0010.941 20H3c-.51 0-.935-.388-.993-.884L2 19v-6c0-.51.388-.935.884-.993L3 12h5.499zM6 14c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zM19 2c.51 0 .935.388.993.884L20 3v3.818l-4-1.819-7.5 3.41L8.498 10H3c-.51 0-.935-.388-.993-.884L2 9V3c0-.51.388-.935.884-.993L3 2h16zM6 4c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z",
  },
];
export const NetworkingServerRacks = [
  {
    d:
      "M20 1c.55 0 1 .45 1 1v18c0 .55-.45 1-1 1h-1v2h-2v-2H7v2H5v-2H4c-.55 0-1-.45-1-1V2c0-.55.45-1 1-1h16zm-2 14H6l-.116.007A1.004 1.004 0 005 16v2l.007.116c.058.496.482.884.993.884h12l.116-.007c.496-.058.884-.482.884-.993v-2l-.007-.116A1.004 1.004 0 0018 15zm0-6H6l-.116.007A1.004 1.004 0 005 10v2l.007.116c.058.496.482.884.993.884h12l.116-.007c.496-.058.884-.482.884-.993v-2l-.007-.116A1.004 1.004 0 0018 9zm0-6H6l-.116.007A1.004 1.004 0 005 4v2l.007.116C5.065 6.612 5.489 7 6 7h12l.116-.007C18.612 6.935 19 6.511 19 6V4l-.007-.116A1.004 1.004 0 0018 3z",
    fillRule: "evenodd",
  },
];
export const NetworkingSwitches = [
  {
    d:
      "M22 4a2 2 0 012 2v12a2 2 0 01-2 2H2a2 2 0 01-2-2V6a2 2 0 012-2h20zM8.503 8H3.997v2H2v6h.999v-3h1.497v3h.999v-3h1.498v3h.998v-3H9.49v3h.999l.012-6H8.503V8zm11.5 0h-4.506v2H13.5v6h.999v-3h1.497v3h.999v-3h1.498v3h.998v-3h1.498v3h.999L22 10h-1.997V8z",
    fillRule: "evenodd",
  },
];
export const NetworkingWireless = [
  {
    d:
      "M12 10c1.1 0 2 .917 2 2.038a2.05 2.05 0 01-.999 1.762l-.001.04v.072l-.001.049-.001.124v.254l-.001.102v.497l-.001.146v2.448l.001 1.397v.944l.001.332v.691L13 22h-2v-.745l.001-.359v-1.024l.001-.323v-1.206l.001-.28v-3.902l-.001-.076-.001-.173v-.04L11 13.818V13.8a2.05 2.05 0 01-1-1.762c0-1.121.9-2.038 2-2.038zM5.994 3l1.003 1.79c-.011.006-.02.01-.025.014-2.385 1.431-3.991 4.125-3.991 7.214 0 2.971 1.49 5.564 3.718 7.037l.233.149L5.942 21C2.991 19.204 1 15.86 1 12.018c0-3.849 2.001-7.21 4.971-9.004L5.994 3zm12.012 0l.023.014C20.999 4.807 23 8.169 23 12.018c0 3.842-1.99 7.186-4.942 8.982l-.99-1.796.233-.149c2.228-1.473 3.718-4.066 3.718-7.037 0-3.089-1.606-5.783-3.991-7.214a11.42 11.42 0 00-.025-.015L18.006 3zM8.003 6.582L9 8.375l-.02.012c-1.216.709-2.038 2.069-2.038 3.631 0 1.456.72 2.716 1.798 3.463l.183.12-.99 1.806c-1.773-1.08-2.971-3.084-2.971-5.389 0-2.324 1.219-4.355 3.022-5.425l.02-.011zm7.994 0l.02.01c1.802 1.071 3.021 3.102 3.021 5.426 0 2.305-1.198 4.31-2.97 5.39l-.991-1.807.183-.12a4.192 4.192 0 001.798-3.463c0-1.562-.822-2.922-2.038-3.631a5.697 5.697 0 00-.02-.012l.997-1.793z",
    fillRule: "evenodd",
  },
];
export const AddAlert = [
  {
    d:
      "M10.01 20.51c0 1.1.89 1.99 1.99 1.99s1.99-.89 1.99-1.99h-3.98zm8.87-4.19V10.5c0-3.25-2.25-5.97-5.29-6.69v-.72c0-.88-.71-1.59-1.59-1.59-.88 0-1.59.71-1.59 1.59v.72a6.873 6.873 0 00-5.29 6.69v5.82L3 18.44v1.06h18v-1.06l-2.12-2.12zM16 12.51h-3v3h-2v-3H8V10.5h3v-3h2v3h3v2.01z",
    fillRule: "evenodd",
  },
];
export const Notification = [
  {
    d:
      "M12 21.75c1.1 0 2-.9 2-2h-4a2 2 0 002 2zm6-6v-5c0-3.07-1.64-5.64-4.5-6.32v-.68c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68C7.63 5.11 6 7.67 6 10.75v5l-2 2v1h16v-1l-2-2z",
    fillRule: "evenodd",
  },
];
export const NotificationActive = [
  {
    d:
      "M7.58 3.83L6.15 2.4c-2.4 1.83-3.98 4.65-4.12 7.85h2a8.445 8.445 0 013.55-6.42zm12.39 6.42h2c-.15-3.2-1.73-6.02-4.12-7.85l-1.42 1.43a8.495 8.495 0 013.54 6.42zm-1.97.5c0-3.07-1.64-5.64-4.5-6.32v-.68c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68C7.63 5.11 6 7.67 6 10.75v5l-2 2v1h16v-1l-2-2v-5zm-6 11c.14 0 .27-.01.4-.04.65-.14 1.18-.58 1.44-1.18.1-.24.15-.5.15-.78h-4c.01 1.1.9 2 2.01 2z",
    fillRule: "evenodd",
  },
];
export const NotificationOff = [
  {
    d:
      "M19.5 18.44L7.34 5.89 4.77 3.24 3.5 4.51l2.8 2.8v.01c-.52.99-.8 2.16-.8 3.42v5l-2 2v1h13.73l2 2 1.27-1.27-1-1.03zm-8 3.31c1.11 0 2-.89 2-2h-4c0 1.11.89 2 2 2zm6-7.32v-3.68c0-3.08-1.64-5.64-4.5-6.32v-.68c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68c-.15.03-.29.08-.42.12-.1.03-.2.07-.3.11h-.01c-.01 0-.01 0-.02.01-.23.09-.46.2-.68.31 0 0-.01 0-.01.01l8.94 9.44z",
    fillRule: "evenodd",
  },
];
export const NotificationPaused = [
  {
    d:
      "M12 21.75c1.1 0 2-.9 2-2h-4a2 2 0 002 2zm6-6v-5c0-3.07-1.64-5.64-4.5-6.32v-.68c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68C7.63 5.11 6 7.68 6 10.75v5l-2 2v1h16v-1l-2-2zm-3.5-6.2l-2.8 3.4h2.8v1.8h-5v-1.8l2.8-3.4H9.5v-1.8h5v1.8z",
    fillRule: "evenodd",
  },
];
export const AccountCircle = [
  {
    d:
      "M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 3c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm0 14.2a7.2 7.2 0 01-6-3.22c.03-1.99 4-3.08 6-3.08 1.99 0 5.97 1.09 6 3.08a7.2 7.2 0 01-6 3.22z",
    fillRule: "evenodd",
  },
];
export const Admin = [
  {
    fillRule: "evenodd",
    d:
      "M19.365 12c.15 0 .276.108.294.252l.228 1.59c.366.15.702.348 1.014.588l1.494-.6a.302.302 0 01.366.132l1.2 2.076a.303.303 0 01-.072.384l-1.266.99c.024.192.042.384.042.588 0 .204-.018.396-.042.588l1.266.99c.114.09.144.252.072.384l-1.2 2.076c-.072.132-.228.186-.366.132l-1.494-.6a4.61 4.61 0 01-1.014.588l-.228 1.59a.293.293 0 01-.294.252h-2.4a.293.293 0 01-.294-.252l-.228-1.59a4.384 4.384 0 01-1.014-.588l-1.494.6a.302.302 0 01-.366-.132l-1.2-2.076a.303.303 0 01.072-.384l1.266-.99a4.758 4.758 0 01-.042-.588c0-.198.018-.396.042-.588l-1.266-.99a.296.296 0 01-.072-.384l1.2-2.076a.293.293 0 01.366-.132l1.494.6a4.61 4.61 0 011.014-.588l.228-1.59a.293.293 0 01.294-.252zm-1.2 3.9c-1.158 0-2.1.942-2.1 2.1 0 1.158.942 2.1 2.1 2.1 1.158 0 2.1-.942 2.1-2.1 0-1.158-.942-2.1-2.1-2.1zm-11.27-4.621l1.994 4.277 2-4.276.039.006c.714.11 1.47.274 2.21.49A7.983 7.983 0 0010.164 18a8 8 0 00.252 2H0v-4.444c0-2.374 3.819-3.801 6.86-4.272l.035-.005zM8.889 0a4.443 4.443 0 014.444 4.444A4.443 4.443 0 018.89 8.89a4.443 4.443 0 01-4.445-4.445A4.443 4.443 0 018.89 0z",
  },
];
export const Customer = [
  {
    d:
      "M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 3c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm0 14.2a7.2 7.2 0 01-6-3.22c.03-1.99 4-3.08 6-3.08 1.99 0 5.97 1.09 6 3.08a7.2 7.2 0 01-6 3.22z",
    fillRule: "evenodd",
  },
];
export const CustomerDeclined = [
  {
    d:
      "M19 14a5 5 0 110 10 5 5 0 010-10zm-2.927 3.133a3.575 3.575 0 004.943 4.945l-4.943-4.945zm3.002-1.633c-.716 0-1.382.21-1.941.572l4.944 4.944a3.575 3.575 0 00-3.003-5.516zM10 0c5.52 0 10 4.48 10 10a10 10 0 01-.21 2.046 6.968 6.968 0 00-4.23.856C14.468 11.6 11.595 10.9 10 10.9c-2 0-5.97 1.09-6 3.08a7.2 7.2 0 008.351 2.826 6.975 6.975 0 00-.307 2.984A9.985 9.985 0 0110 20C4.48 20 0 15.52 0 10S4.48 0 10 0zm0 3C8.34 3 7 4.34 7 6s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z",
    fillRule: "evenodd",
  },
];
export const CustomerReplied = [
  {
    d:
      "M18.995 14a5.003 5.003 0 014.158 7.783c-.442-1.926-1.655-3.798-4.353-4.183V16L16 18.8l2.8 2.8v-1.64c1.933 0 3.305.598 4.298 1.901A4.998 4.998 0 0114 19c0-2.76 2.235-5 4.995-5zM10 0c5.52 0 10 4.48 10 10a10 10 0 01-.21 2.046 6.968 6.968 0 00-4.23.856C14.468 11.6 11.595 10.9 10 10.9c-2 0-5.97 1.09-6 3.08a7.2 7.2 0 008.351 2.826 6.975 6.975 0 00-.307 2.984A9.985 9.985 0 0110 20C4.48 20 0 15.52 0 10S4.48 0 10 0zm0 3C8.34 3 7 4.34 7 6s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z",
    fillRule: "evenodd",
  },
];
export const CustomerWaiting = [
  {
    d:
      "M18.995 14A5.003 5.003 0 0124 19c0 2.76-2.24 5-5.005 5A4.998 4.998 0 0114 19c0-2.76 2.235-5 4.995-5zm.505 2H18v3.375l3.257 2 .767-1.227-2.524-1.422V16zM10 0c5.52 0 10 4.48 10 10a10 10 0 01-.21 2.046 6.968 6.968 0 00-4.23.856C14.468 11.6 11.595 10.9 10 10.9c-2 0-5.97 1.09-6 3.08a7.2 7.2 0 008.351 2.826 6.975 6.975 0 00-.307 2.984A9.985 9.985 0 0110 20C4.48 20 0 15.52 0 10S4.48 0 10 0zm0 3C8.34 3 7 4.34 7 6s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z",
    fillRule: "evenodd",
  },
];
export const Customers = [
  {
    d:
      "M12 2c5.52 0 10 4.48 10 10s-4.48 10-10 10S2 17.52 2 12 6.48 2 12 2zM8.727 12.286c-1.038 0-2.644.272-3.893.818a7.256 7.256 0 009.62 5.72v-3.788l-.009-.155c-.207-1.728-3.866-2.595-5.718-2.595zm7.118.471c-.213 0-.5.034-.751.073.854.856.999 1.63.999 2.402v2.751a7.26 7.26 0 003.023-4.587c-1.084-.426-2.4-.639-3.27-.639zm0-5.657c-1.222 0-2.209.948-2.209 2.121 0 1.174.987 2.122 2.21 2.122 1.222 0 2.201-.948 2.201-2.122 0-1.173-.98-2.121-2.202-2.121zM8.727 6C7.37 6 6.273 7.053 6.273 8.357c0 1.304 1.096 2.357 2.454 2.357s2.447-1.053 2.447-2.357C11.174 7.053 10.085 6 8.727 6z",
    fillRule: "evenodd",
  },
];
export const Face = [
  {
    d:
      "M9 11.75a1.25 1.25 0 100 2.5 1.25 1.25 0 000-2.5zm6 0a1.25 1.25 0 100 2.5 1.25 1.25 0 000-2.5zM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8 0-.29.02-.58.05-.86 2.36-1.05 4.23-2.98 5.21-5.37a9.974 9.974 0 0010.41 3.97c.21.71.33 1.47.33 2.26 0 4.41-3.59 8-8 8z",
    fillRule: "evenodd",
  },
];
export const People = [
  {
    d:
      "M8 13c2.33 0 7 1.17 7 3.5V19H1v-2.5C1 14.17 5.67 13 8 13zm8.7.6c2.097 0 6.3 1.053 6.3 3.15V19h-5.998v-2.25c0-.983-.177-1.968-1.22-3.058a6.32 6.32 0 01.918-.092zm0-7.2a2.689 2.689 0 012.691 2.7c0 1.494-1.197 2.7-2.691 2.7A2.696 2.696 0 0114 9.1c0-1.494 1.206-2.7 2.7-2.7zM8 5c1.66 0 2.99 1.34 2.99 3S9.66 11 8 11c-1.66 0-3-1.34-3-3s1.34-3 3-3z",
    fillRule: "evenodd",
  },
];
export const Person = [
  {
    d:
      "M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z",
    fillRule: "evenodd",
  },
];
export const PersonAdd = [
  {
    d:
      "M15 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm-9-2V7H4v3H1v2h3v3h2v-3h3v-2H6zm9 4c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z",
    fillRule: "evenodd",
  },
];
export const SmsCustomerReplied = [
  {
    d:
      "M22 7c.575.005 1.06.215 1.454.63.354.377.537.834.546 1.37v14l-3-3H10a1.997 1.997 0 01-1.508-.68C8.164 18.914 8 18.474 8 18v-2h2v2h11l1 1V9h-3V7h3zm-7-6c.55.003 1.024.201 1.423.594.333.343.523.752.567 1.225L17 3v9c0 .475-.164.915-.492 1.32a1.982 1.982 0 01-1.31.669L15 14H3l-3 3V3c.01-.536.192-.993.546-1.37a1.936 1.936 0 011.266-.62L2 1h13zM8.5 3a4.5 4.5 0 100 9 4.5 4.5 0 000-9zm0 4.905c.9 0 2.687.495 2.7 1.395a3.254 3.254 0 01-2.7 1.44A3.254 3.254 0 015.8 9.3c.014-.9 1.8-1.395 2.7-1.395zm0-3.555a1.35 1.35 0 110 2.7 1.35 1.35 0 010-2.7z",
    fillRule: "evenodd",
  },
];
export const User = [
  {
    d:
      "M10.205 13.151L12 17l1.8-3.848.035.005c2.66.413 5.975 1.639 6.157 3.667L20 17v4H4v-4c0-2.136 3.437-3.42 6.173-3.844l.032-.005zM18 17h-4v2h4v-2zM12 3c2.21 0 4 1.79 4 4s-1.79 4-4 4-4-1.79-4-4 1.79-4 4-4z",
  },
];
export const UserAdd = [
  {
    d:
      "M13.205 14.151L15 18l1.8-3.848.035.005c2.66.413 5.975 1.639 6.157 3.667L23 18v4H7v-4c0-2.136 3.437-3.42 6.173-3.844l.032-.005zM21 18h-4v2h4v-2zM6 7v3h3v2H6v3H4v-3H1v-2h3V7h2zm9-3c2.21 0 4 1.79 4 4s-1.79 4-4 4-4-1.79-4-4 1.79-4 4-4z",
    fillRule: "evenodd",
  },
];
export const Users = [
  {
    d:
      "M6.43 13.139L8 16.506l1.575-3.367.03.005c2.327.361 5.229 1.434 5.388 3.209l.007.153V19H1v-2.494c0-1.868 3.007-2.993 5.402-3.363l.028-.004zm11.145 0l.03.005c2.327.361 5.229 1.434 5.388 3.209l.007.153V19h-6.001L17 16.505l-.009-.243a4.166 4.166 0 00-.3-1.234l.884-1.89zm-4.325 3.434h-3.5v1.75h3.5v-1.75zm8 0h-3.5v1.75h3.5v-1.75zM16.7 6.4a2.689 2.689 0 012.691 2.7c0 1.494-1.197 2.7-2.691 2.7A2.696 2.696 0 0114 9.1c0-1.494 1.206-2.7 2.7-2.7zM8 5c1.66 0 2.99 1.34 2.99 3S9.66 11 8 11c-1.66 0-3-1.34-3-3s1.34-3 3-3z",
    fillRule: "evenodd",
  },
];
export const Valet = [
  {
    d:
      "M16.17 16c2.49.666 3.781 1.625 4.755 2.431.89.738 1.075.898 1.075 1.483V22h-7l1.17-6zm-8.34 0L9 22H2v-2.086c0-.585.185-.745 1.075-1.483.974-.806 2.265-1.765 4.756-2.431zm3.685-1c.828 0 1.627.065 2.397.195l-1.108 2.08L14 22H9l1.209-4.656-1.063-2.149c.75-.13 1.54-.195 2.369-.195zM16.5 6.997v1.252c0 2.624-2.239 4.751-5 4.751-2.687 0-4.879-2.014-4.995-4.54L6.5 8.25V7.01l10-.014zM14.5 2a2 2 0 012 2v1.952L6.5 6V4a2 2 0 012-2h6z",
    fillRule: "evenodd",
  },
];
export const WaitingOnCustomerResponse = [
  {
    d:
      "M18 12c3.312 0 6 2.688 6 6s-2.688 6-6 6-6-2.688-6-6 2.688-6 6-6zm0 6.54c-1.2 0-3.582.654-3.6 1.848a4.32 4.32 0 007.2 0c-.018-1.194-2.406-1.848-3.6-1.848zM9.99 0C15.52 0 20 4.48 20 10l-.004.251A8.015 8.015 0 0018 10a7.963 7.963 0 00-3.972 1.054L10.8 9V4H8.3v6.182l3.739 2.483A7.97 7.97 0 0010 18c0 .69.087 1.358.251 1.996L9.99 20C4.47 20 0 15.52 0 10S4.47 0 9.99 0zM18 13.8c-.996 0-1.8.804-1.8 1.8s.804 1.8 1.8 1.8 1.8-.804 1.8-1.8-.804-1.8-1.8-1.8z",
    fillRule: "evenodd",
  },
];
export const CheckBoxChecked = [
  {
    d:
      "M19 3H5a2 2 0 00-2 2v14a2 2 0 002 2h14a2 2 0 002-2V5a2 2 0 00-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z",
    fillRule: "evenodd",
  },
];
export const CheckBoxIndeterminate = [
  {
    d:
      "M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-2 10H7v-2h10v2z",
    fillRule: "evenodd",
  },
];
export const CheckBoxOutlineBlank = [
  {
    d:
      "M19 5v14H5V5h14zm0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z",
    fillRule: "evenodd",
  },
];
export const RadioButtonChecked = [
  {
    d:
      "M12 7c-2.76 0-5 2.24-5 5s2.24 5 5 5 5-2.24 5-5-2.24-5-5-5zm0-5C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z",
    fillRule: "evenodd",
  },
];
export const RadioButtonUnchecked = [
  {
    d:
      "M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z",
    fillRule: "evenodd",
  },
];
export const ContactSupport = [
  {
    d:
      "M11.5 2C6.81 2 3 5.81 3 10.5c0 4.69 3.81 8.5 8.5 8.5h.5v3c4.86-2.34 8-7 8-11.5C20 5.81 16.19 2 11.5 2zm1 14.5h-2v-2h2v2zm0-3.5h-2c0-3.25 3-3 3-5 0-1.1-.9-2-2-2s-2 .9-2 2h-2c0-2.21 1.79-4 4-4s4 1.79 4 4c0 2.5-3 2.75-3 5z",
    fillRule: "evenodd",
  },
];
export const Alarm = [
  {
    d:
      "M22 5.79l-4.6-3.86-1.29 1.53 4.6 3.86L22 5.79zM7.88 3.46L6.6 1.93 2 5.78l1.29 1.53 4.59-3.85zm4.62 4.61H11v6l4.75 2.85.75-1.23-4-2.37V8.07zm-.5-4a9 9 0 00-9 9c0 4.97 4.02 9 9 9a9 9 0 000-18zm0 16c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7z",
    fillRule: "evenodd",
  },
];
export const AlarmAdd = [
  {
    d:
      "M7.88 3.53L6.6 2 2 5.85l1.29 1.53 4.59-3.85zM22 5.86L17.4 2l-1.29 1.53 4.6 3.86L22 5.86zM12 4.14a9 9 0 00-9 9c0 4.97 4.02 9 9 9a9 9 0 000-18zm0 16c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7zm1-11h-2v3H8v2h3v3h2v-3h3v-2h-3v-3z",
    fillRule: "evenodd",
  },
];
export const AlarmOff = [
  {
    d:
      "M12.175 6.07c3.87 0 7 3.13 7 7 0 .84-.16 1.65-.43 2.4l1.52 1.52c.58-1.19.91-2.51.91-3.92a9 9 0 00-9-9c-1.41 0-2.73.33-3.92.91l1.52 1.52c.75-.27 1.56-.43 2.4-.43zm10-.28l-4.6-3.86-1.29 1.53 4.6 3.86 1.29-1.53zM3.095 2.36l-1.27 1.28 1.33 1.33-1.11.93 1.42 1.42 1.11-.94.8.8a8.964 8.964 0 00-2.2 5.89c0 4.97 4.02 9 9 9 2.25 0 4.31-.83 5.89-2.2l2.2 2.2 1.27-1.27L4.065 3.34l-.97-.98zm13.55 16.1c-1.21 1-2.77 1.61-4.47 1.61-3.87 0-7-3.13-7-7 0-1.7.61-3.26 1.61-4.47l9.86 9.86zM8.195 3.35l-1.42-1.42-.86.71 1.42 1.42.86-.71z",
    fillRule: "evenodd",
  },
];
export const AlarmOn = [
  {
    d:
      "M22 5.79l-4.6-3.86-1.29 1.53 4.6 3.86L22 5.79zM7.88 3.46L6.6 1.93 2 5.78l1.29 1.53 4.59-3.85zm4.12.61a9 9 0 00-9 9c0 4.97 4.02 9 9 9a9 9 0 000-18zm0 16c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7zm-1.46-5.47l-2.13-2.13-1.06 1.06 3.18 3.18 6-6-1.06-1.06-4.93 4.95z",
    fillRule: "evenodd",
  },
];
export const Restore = [
  {
    d:
      "M13.5 3a9 9 0 00-9 9h-3l3.89 3.89.07.14L9.5 12h-3c0-3.87 3.13-7 7-7s7 3.13 7 7-3.13 7-7 7c-1.93 0-3.68-.79-4.94-2.06l-1.42 1.42A8.954 8.954 0 0013.5 21a9 9 0 000-18zm-1 5v5l4.28 2.54.72-1.21-3.5-2.08V8h-1.5z",
    fillRule: "evenodd",
  },
];
export const Schedule = [
  {
    d:
      "M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm.5-13H11v6l5.25 3.15.75-1.23-4.5-2.67V7z",
    fillRule: "evenodd",
  },
];
export const Snooze = [
  {
    d:
      "M7.88 3.46L6.6 1.93 2 5.78l1.29 1.53 4.59-3.85zM22 5.79l-4.6-3.86-1.29 1.53 4.6 3.86L22 5.79zM12 4.07a9 9 0 00-9 9c0 4.97 4.02 9 9 9a9 9 0 000-18zm0 16c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7zm-3-9h3.63L9 15.27v1.8h6v-2h-3.63l3.63-4.2v-1.8H9v2z",
    fillRule: "evenodd",
  },
];
export const Waiting = [
  {
    d:
      "M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2zm4.2 14.2L11 13V7h1.5v5.2l4.5 2.7-.8 1.3z",
    fillRule: "evenodd",
  },
];
export const Dashboard = [
  {
    d: "M3 13h8V3H3v10zm0 8h8v-6H3v6zm10 0h8V11h-8v10zm0-18v6h8V3h-8z",
    fillRule: "evenodd",
  },
];
export const FilterList = [
  { d: "M10 18h4v-2h-4v2zM3 6v2h18V6H3zm3 7h12v-2H6v2z", fillRule: "evenodd" },
];
export const List = [
  {
    d:
      "M3 13h2v-2H3v2zm0 4h2v-2H3v2zm0-8h2V7H3v2zm4 4h14v-2H7v2zm0 4h14v-2H7v2zM7 7v2h14V7H7z",
    fillRule: "evenodd",
  },
];
export const Reorder = [
  {
    d: "M3 15h18v-2H3v2zm0 4h18v-2H3v2zm0-8h18V9H3v2zm0-6v2h18V5H3z",
    fillRule: "evenodd",
  },
];
export const Sort = [
  { d: "M3 18h6v-2H3v2zM3 6v2h18V6H3zm0 7h12v-2H3v2z", fillRule: "evenodd" },
];
export const ViewCard = [
  {
    d:
      "M21 13a1 1 0 011 1v6a1 1 0 01-1 1H3a1 1 0 01-1-1v-6a1 1 0 011-1h18zm-1 2H4v4h16v-4zm1-12a1 1 0 011 1v6a1 1 0 01-1 1H3a1 1 0 01-1-1V4a1 1 0 011-1h18zm-1 2H4v4h16V5z",
    fillRule: "evenodd",
  },
];
export const ViewGrid = [
  {
    id: "a",
    d:
      "M20 2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zM8 20H4v-4h4v4zm0-6H4v-4h4v4zm0-6H4V4h4v4zm6 12h-4v-4h4v4zm0-6h-4v-4h4v4zm0-6h-4V4h4v4zm6 12h-4v-4h4v4zm0-6h-4v-4h4v4zm0-6h-4V4h4v4z",
  },
];
export const ViewList = [
  {
    d:
      "M20 2a2 2 0 012 2v16a2 2 0 01-2 2H4a2 2 0 01-2-2V4a2 2 0 012-2h16zM8 16H4v4h4v-4zm12 0H10v4h10v-4zM8 10H4v4h4v-4zm12 0H10v4h10v-4zM8 4H4v4h4V4zm12 0H10v4h10V4z",
    fillRule: "evenodd",
  },
];
export const Visibility = [
  {
    d:
      "M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z",
    fillRule: "evenodd",
  },
];
export const VisibilityOff = [
  {
    d:
      "M12.005 6.5c2.76 0 5 2.24 5 5 0 .65-.13 1.26-.36 1.83l2.92 2.92c1.51-1.26 2.7-2.89 3.43-4.75-1.73-4.39-6-7.5-11-7.5-1.4 0-2.74.25-3.98.7l2.16 2.16c.57-.23 1.18-.36 1.83-.36zm-10-2.73l2.28 2.28.46.46a11.804 11.804 0 00-3.74 4.99c1.73 4.39 6 7.5 11 7.5 1.55 0 3.03-.3 4.38-.84l.42.42 2.93 2.92 1.27-1.27L3.275 2.5l-1.27 1.27zm5.53 5.53l1.55 1.55c-.05.21-.08.43-.08.65 0 1.66 1.34 3 3 3 .22 0 .44-.03.65-.08l1.55 1.55c-.67.33-1.41.53-2.2.53-2.76 0-5-2.24-5-5 0-.79.2-1.53.53-2.2zm4.31-.78l3.15 3.15.02-.16c0-1.66-1.34-3-3-3l-.17.01z",
    fillRule: "evenodd",
  },
];
export const ZoomIn = [
  {
    d:
      "M15.755 14.255h-.79l-.28-.27a6.471 6.471 0 001.57-4.23 6.5 6.5 0 10-6.5 6.5c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99 1.49-1.49-4.99-5zm-6 0c-2.49 0-4.5-2.01-4.5-4.5s2.01-4.5 4.5-4.5 4.5 2.01 4.5 4.5-2.01 4.5-4.5 4.5zm2.5-4h-2v2h-1v-2h-2v-1h2v-2h1v2h2v1z",
    fillRule: "evenodd",
  },
];
export const ZoomOut = [
  {
    d:
      "M15.755 14.255h-.79l-.28-.27a6.471 6.471 0 001.57-4.23 6.5 6.5 0 10-6.5 6.5c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99 1.49-1.49-4.99-5zm-6 0c-2.49 0-4.5-2.01-4.5-4.5s2.01-4.5 4.5-4.5 4.5 2.01 4.5 4.5-2.01 4.5-4.5 4.5zm-2.5-5h5v1h-5v-1z",
    fillRule: "evenodd",
  },
];
export const EnFlag = [
  { fill: "#FFF", d: "M0 0H24V24H0z", opacity: "0" },
  {
    fill: "#FFF",
    d:
      "M20.721,16.893 L20.6783982,16.9718547 C20.2698013,17.6835267 19.7761831,18.3401644 19.2114012,18.9279105 L19.139,19 L4.86,19 L4.64130078,18.7712591 C4.21656289,18.309946 3.83465299,17.8085673 3.50187956,17.2734447 L3.33808045,16.9998462 L20.6619196,16.9998462 L20.721,16.893 Z M21.995,11.72 L21.9937779,11.6439014 C21.9979145,11.7620908 22,11.8808019 22,12 C22,12.5409232 21.9570516,13.0718153 21.8743788,13.5894522 L21.7999031,13.9996983 L2.2,13.999 L2.19518545,13.9754661 C2.09272259,13.4644007 2.02928162,12.9394802 2.00800374,12.4037638 L2,11.9993993 L2,12 L12,12 L12,11.999 L22,11.9993993 L21.995,11.72 Z M20.6610295,6.99801501 C21.0240019,7.62515389 21.320814,8.29536982 21.5419205,8.99911747 L12,8.999 L12,6.998 L20.6610295,6.99801501 Z M12,2 C14.2517953,2 16.3297558,2.74427531 18.0012995,4.00024387 L12,4 L12,2 Z",
  },
  {
    fill: "#B22234",
    fillRule: "nonzero",
    d:
      "M19.1407345,19.0007123 C17.3258607,20.8516568 14.7970353,22 12,22 C9.20296467,22 6.67413926,20.8516568 4.85926552,19.0007123 L19.1407345,19.0007123 Z M21.7999031,14.000299 C21.5821968,15.0726315 21.1928995,16.0826502 20.6619196,17.0004468 L3.33808045,17.0004468 C2.80710049,16.0826502 2.41780319,15.0726315 2.20009693,14.000299 L21.7999031,14.000299 Z M21.5421829,8.99995292 C21.8024586,9.82862596 21.957768,10.7037833 21.9925286,11.6098424 L22,12 L12,12 L11.9990969,8.999 L21.5421829,8.99995292 Z M18.0017743,4.00060068 C19.077503,4.80898395 19.9848848,5.82930142 20.6619196,6.99955316 L11.9990969,6.999 L11.9990969,4 L18.0017743,4.00060068 Z",
  },
  {
    fill: "#3C3B6E",
    fillRule: "nonzero",
    d:
      "M12,2 L12,12 L2,12 L2.0038418,11.7200952 C2.14951265,6.41966086 6.41966086,2.14951265 11.7200952,2.0038418 L12,2 Z",
  },
  {
    fill: "#FFF",
    fillRule: "nonzero",
    d:
      "M9.5,8 L9.94083894,8.89323725 L10.9265848,9.03647451 L10.2132924,9.73176275 L10.3816779,10.7135255 L9.5,10.25 L8.61832212,10.7135255 L8.78670761,9.73176275 L8.07341523,9.03647451 L9.05916106,8.89323725 L9.5,8 Z M5.5,8 L5.94083894,8.89323725 L6.92658477,9.03647451 L6.21329239,9.73176275 L6.38167788,10.7135255 L5.5,10.25 L4.61832212,10.7135255 L4.78670761,9.73176275 L4.07341523,9.03647451 L5.05916106,8.89323725 L5.5,8 Z M2.2326593,9.84558276 L2.38167788,10.7135255 L2.10198472,10.5664395 C2.1369001,10.3232176 2.1805681,10.0828223 2.2326593,9.84558276 Z M2.46733996,8.96980183 L2.92658477,9.03647451 L2.27207559,9.67339038 C2.32877322,9.43544991 2.39397433,9.2008074 2.46733996,8.96980183 Z M9.5,5 L9.94083894,5.89323725 L10.9265848,6.03647451 L10.2132924,6.73176275 L10.3816779,7.71352549 L9.5,7.25 L8.61832212,7.71352549 L8.78670761,6.73176275 L8.07341523,6.03647451 L9.05916106,5.89323725 L9.5,5 Z M5.5,5 L5.94083894,5.89323725 L6.92658477,6.03647451 L6.21329239,6.73176275 L6.38167788,7.71352549 L5.5,7.25 L4.61832212,7.71352549 L4.78670761,6.73176275 L4.07341523,6.03647451 L5.05916106,5.89323725 L5.5,5 Z M9.6384504,2.28046809 L9.94083894,2.89323725 L10.9265848,3.03647451 L10.2132924,3.73176275 L10.3816779,4.71352549 L9.5,4.25 L8.61832212,4.71352549 L8.78670761,3.73176275 L8.07341523,3.03647451 L9.05916106,2.89323725 L9.32150388,2.36276128 L9.6384504,2.28046809 L9.6384504,2.28046809 Z M6.23001168,3.83154886 L6.38167788,4.71352549 L5.61039832,4.30726639 L5.91542768,4.06349482 L5.91542768,4.06349482 L6.23001168,3.83154886 Z",
  },
];
export const EsFlag = [
  {
    fill: "#D80027",
    d:
      "M21.3704708,15.4997898 C19.9517912,19.2965681 16.2916058,22 12,22 C7.7083942,22 4.04820875,19.2965681 2.62952916,15.4997898 L21.3704708,15.4997898 Z M12,2 C16.2916058,2 19.9517912,4.70343189 21.3704708,8.50021015 L2.62952916,8.50021015 C4.04820875,4.70343189 7.7083942,2 12,2 Z",
  },
  {
    fill: "#FFDA44",
    d:
      "M21.3704132,8.50005603 C21.7774624,9.5893769 22,10.7687002 22,12 C22,13.2312998 21.7774624,14.4106231 21.3704132,15.499944 L2.62958675,15.499944 C2.22253759,14.4106231 2,13.2312998 2,12 C2,10.7687002 2.22253759,9.5893769 2.62958675,8.50005603 L21.3704132,8.50005603 Z",
  },
];
export const FrFlag = [
  {
    fill: "#0055A4",
    id: "fr_flag-a",
    d:
      "M8.66616684,2.5691957 L8.66616684,21.4308043 C4.78245336,20.0578752 2,16.353876 2,12 C2,7.64612402 4.78245336,3.94212484 8.66616684,2.5691957 Z",
  },
  { fill: "#FFF", d: "M0 0H24V24H0z", opacity: "0" },
  {
    fill: "#EF4135",
    fillRule: "nonzero",
    d:
      "M15.3348351,2.56954997 C19.2180252,3.94276193 22,7.64649845 22,12 C22,16.3535015 19.2180252,20.0572381 15.3348351,21.43045 Z",
  },
  {
    fill: "#FFF",
    fillRule: "nonzero",
    d:
      "M12,2 C13.1687895,2 14.2907461,2.20051571 15.3333461,2.56902355 L15.3333461,21.4309765 C14.2907461,21.7994843 13.1687895,22 12,22 C10.8306541,22 9.70818566,21.7992933 8.66516488,21.43045 L8.66516488,2.56954997 C9.70818566,2.20070667 10.8306541,2 12,2 Z",
  },
];
export const Logo = [
  { fill: "#FFF", d: "M0 0H24V24H0z", opacity: "0" },
  {
    fill: "#82C600",
    d:
      "M9.6,18 C9.6,19.3254834 10.6745166,20.4 12,20.4 C13.3254834,20.4 14.4,19.3254834 14.4,18 C14.4,16.6745166 13.3254834,15.6 12,15.6 C10.6745166,15.6 9.6,16.6745166 9.6,18 Z",
  },
  {
    fill: "#FFF",
    d:
      "M12,9.6 C13.3254834,9.6 14.4,10.6745166 14.4,12 C14.4,13.3254834 13.3254834,14.4 12,14.4 C10.6745166,14.4 9.6,13.3254834 9.6,12 C9.6,10.6745166 10.6745166,9.6 12,9.6 Z M12,3.6 C13.3254834,3.6 14.4,4.6745166 14.4,6 C14.4,7.3254834 13.3254834,8.4 12,8.4 C10.6745166,8.4 9.6,7.3254834 9.6,6 C9.6,4.6745166 10.6745166,3.6 12,3.6 Z",
  },
];
