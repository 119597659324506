import { cloneElement } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const InputSuffixElement = styled(({ children, ...props }) => cloneElement(children, props)).withConfig({
  shouldForwardProp: (prop) => !['hasError', 'isDisabled'].includes(prop),
})`
  ${({ hasError, isDisabled, isFocused, theme }) =>
    css`
      /* This is needed for IE */
      flex-shrink: 0;

      /* No other way to apply styling */
      svg {
        fill: ${theme.color.gray['600'].value};
        fill: ${isFocused && theme.color.primary['500'].value};
        fill: ${hasError && theme.color.system.negative[500].value};
        fill: ${isDisabled && theme.color.text.light.disabled.value};
        padding-right: ${theme.size.spacing.small.value};
      }
      &:hover,
      &:focus {
        /* No other way to apply styling */
        svg {
          fill: ${!isDisabled && theme.color.primary['500'].value};
        }
      }
    `}
`;

InputSuffixElement.propTypes = {
  /** If true, input is in error state */
  hasError: PropTypes.bool,
  /** If true, disables state appearances changes */
  isDisabled: PropTypes.bool,
};

InputSuffixElement.defaultProps = {
  hasError: false,
  isDisabled: false,
};

export { InputSuffixElement };
