import React from 'react';

import { CHECKBOX_SIZES } from 'lib/checkbox';
import { useWindowSize } from 'lib/utilities';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { LIST_SIZES } from '../../constants';
import {
  ListItemCheckbox,
  ListItemGraphic,
  ListItemMetadata,
  ListItemPrimaryText,
  ListItemSubtext,
  ListItemText,
} from '../../List/elements';
import { NestedListItemContent, NestedListItemIcon } from '../elements';
import { nestedListItemPropTypes } from '../propTypes';
import { isMobileScreen } from './../../../core';

const StyledNestedListItem = styled.li`
  list-style: none;
`;

const NestedListItem = ({
  children,
  customContentTag,
  customGraphicTag,
  customPrimaryTextTag,
  dataTestId,
  isCheckable,
  isChecked,
  isExpanded,
  item,
  nestingLevel,
  onItemCheck,
  onItemExpandCollapse,
  size,
  ...other
}) => {
  const windowSize = useWindowSize();
  const hasItems = !!(item?.items?.length || 0);
  const hasIcon = !!item.icon;
  const hasAvatar = !!item.avatar;
  const { isDisabled } = item;

  return (
    <StyledNestedListItem data-testid={dataTestId ? `${dataTestId}-item-${item.id}` : undefined}>
      <NestedListItemContent
        data-testid={dataTestId ? `${dataTestId}-item-button-${item.id}` : undefined}
        aria-expanded={hasItems ? !!children : null}
        aria-haspopup={hasItems}
        customListItemTag={customContentTag}
        hasAvatar={hasAvatar}
        hasIcon={hasIcon}
        href={hasItems ? '' : item.href}
        isCheckable={isCheckable}
        isChecked={isChecked}
        isExpanded={isExpanded}
        isDisabled={isDisabled}
        isMultiLine={!!item.subtext}
        isNested={hasItems}
        nestingLevel={nestingLevel}
        onClick={(...args) => {
          if (item.onClick) {
            item.onClick(...args);
          }

          onItemExpandCollapse(item.id);
        }}
        renderCustomAnchorElement={item.renderCustomAnchorElement}
        showOutline
        size={size}
        {...other}
      >
        {hasItems && (
          <NestedListItemIcon
            isDisabled={isDisabled}
            isExpanded={isExpanded}
            nestingLevel={nestingLevel}
            withExtraMargin={!isCheckable && !hasAvatar && !hasIcon}
          />
        )}
        {isCheckable && (
          <ListItemCheckbox
            isDisabled={isDisabled}
            checked={isChecked}
            onClick={() => onItemCheck(item.id)}
            size={isMobileScreen(windowSize.width) ? CHECKBOX_SIZES.LARGE : size}
          />
        )}
        {hasAvatar && (
          <ListItemGraphic as={customGraphicTag} isDisabled={isDisabled}>
            {item.avatar}
          </ListItemGraphic>
        )}
        {hasIcon && (
          <ListItemGraphic as={customGraphicTag} isDisabled={isDisabled}>
            {item.icon}
          </ListItemGraphic>
        )}
        <ListItemText>
          <ListItemPrimaryText as={customPrimaryTextTag} isDisabled={isDisabled} size={size}>
            {item.text}
          </ListItemPrimaryText>
          {!!item.subtext && <ListItemSubtext>{item.subtext}</ListItemSubtext>}
        </ListItemText>
        {!!item.metadata && <ListItemMetadata>{item.metadata}</ListItemMetadata>}
      </NestedListItemContent>
      {children}
    </StyledNestedListItem>
  );
};

NestedListItem.propTypes = {
  /** Content that is rendered inside list item */
  children: PropTypes.node,
  /** Ability to supply a different element instead of the default one for NestedListItemContent element */
  customContentTag: PropTypes.elementType,
  /** Ability to supply a different element instead of the default one for NestedListItemGraphics element */
  customGraphicTag: PropTypes.elementType,
  /** Ability to supply a different element instead of the default one for NestedListItemPrimaryText element */
  customPrimaryTextTag: PropTypes.elementType,
  /** Id value used for testing */
  dataTestId: PropTypes.string,
  /** If true, item can be checked with a checkbox */
  isCheckable: PropTypes.bool,
  /** If true, item is checked */
  isChecked: PropTypes.bool,
  /** If true, children items are shown */
  isExpanded: PropTypes.bool,
  /** Item for which list item is displayed */
  item: PropTypes.shape(nestedListItemPropTypes).isRequired,
  /** Specifies the nesting level in the list which ranges from 0 to 2 */
  nestingLevel: PropTypes.number.isRequired,
  /** Callback called when item is being checked */
  onItemCheck: PropTypes.func,
  /** Callback called when item is being collapsed of expanded */
  onItemExpandCollapse: PropTypes.func.isRequired,
  /** Changes list item height */
  size: PropTypes.oneOf(Object.values(LIST_SIZES)),
};

NestedListItem.defaultProps = {
  children: undefined,
  customContentTag: undefined,
  customGraphicTag: undefined,
  customPrimaryTextTag: undefined,
  dataTestId: undefined,
  isCheckable: false,
  isChecked: false,
  isExpanded: false,
  onItemCheck: () => {},
  size: LIST_SIZES.STANDARD,
};

export { NestedListItem };
