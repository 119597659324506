import React from 'react';

import { ICON_SIZE } from 'lib/icons';
import PropTypes from 'prop-types';

import { BUTTON_BACKGROUND_APPEARANCES, BUTTON_ICON_COLORS, BUTTON_SIZES, BUTTON_VARIANTS } from '../../constants';
import { IconButton } from '../IconButton/IconButton';
import { PrimaryButton } from '../PrimaryButton/PrimaryButton';
import { SecondaryButton } from '../SecondaryButton/SecondaryButton';
import { TextButton } from '../TextButton/TextButton';

const Button = React.forwardRef(({ variant, ...other }, ref) => {
  switch (variant) {
    case BUTTON_VARIANTS.ICON:
      return <IconButton ref={ref} {...other} />;
    case BUTTON_VARIANTS.SECONDARY:
      return <SecondaryButton ref={ref} {...other} />;
    case BUTTON_VARIANTS.TEXT:
      return <TextButton ref={ref} {...other} />;
    default:
      return <PrimaryButton ref={ref} {...other} />;
  }
});

Button.propTypes = {
  /** Will adjust button styles accordingly to background it is represented on */
  backgroundAppearance: PropTypes.oneOf(Object.values(BUTTON_BACKGROUND_APPEARANCES)),
  /** Replaces default tag: button or anchor with new value */
  customTagElement: PropTypes.string,
  /** Id value used for testing */
  dataTestId: PropTypes.string,
  /** If true, will visually hide text */
  hideText: PropTypes.bool,
  /** When URL is provided, element changes from button to hyperlink <a> */
  href: PropTypes.string,
  /** If not loading, will render specified icon before text */
  icon: PropTypes.node,
  /** Adds new class for icon element */
  iconClassName: PropTypes.string,
  /** Will change icon color */
  iconColor: PropTypes.oneOf(Object.values(BUTTON_ICON_COLORS)),
  /** Will set size of the icon */
  iconSize: PropTypes.oneOf(Object.values(ICON_SIZE)),
  /** Disallows user to interact with the button and adjusts appearance */
  isDisabled: PropTypes.bool,
  /** If true, will display loader and adjust width to better accommodate spinner */
  isLoading: PropTypes.bool,
  /** Loader text which will be read for screen reader users */
  loaderText: PropTypes.node,
  /** Callback that is called on click */
  onClick: PropTypes.func,
  /** Callback that is called on key down */
  onKeyDown: PropTypes.func,
  /** If true, will add vertical margins to the component */
  preserveClickableArea: PropTypes.bool,
  /** Changes button height */
  size: PropTypes.oneOf(Object.values(BUTTON_SIZES)),
  /** Will display text inside button */
  text: PropTypes.node.isRequired,
  /** Default html button 'type' attribute values when button component is used */
  type: PropTypes.string,
  /** Changes button style depending on variant. Default 'primary' */
  variant: PropTypes.oneOf(Object.values(BUTTON_VARIANTS)),
};

Button.defaultProps = {
  backgroundAppearance: BUTTON_BACKGROUND_APPEARANCES.LIGHT,
  customTagElement: '',
  dataTestId: undefined,
  hideText: false,
  href: undefined,
  icon: undefined,
  iconClassName: '',
  iconColor: BUTTON_ICON_COLORS.PRIMARY,
  iconSize: ICON_SIZE.DEFAULT,
  isDisabled: false,
  isLoading: false,
  loaderText: 'Loading...',
  onClick: () => {},
  onKeyDown: () => {},
  preserveClickableArea: false,
  size: BUTTON_SIZES.STANDARD,
  type: '',
  variant: BUTTON_VARIANTS.PRIMARY,
};

export { Button };
