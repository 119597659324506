import React from 'react';

import { Avatar, AVATAR_SIZES, AVATAR_VARIANTS } from 'lib/avatar';
import { NormalizedButton } from 'lib/button';
import { IconArrowDropDown } from 'lib/icons';
import { KEY_CODES } from 'lib/utilities';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { NAVIGATION_DROPDOWN_WIDTHS } from '../constants';
import { dropdownButtonStateStyles } from '../shared-styles/navigationContextSwitcherStateStyles';
import {
  ElementStateWrapper,
  getHexToRgb,
  getRem,
  transitionAnimation,
  typographyBody1,
  typographySubtitle1,
  truncateText,
} from './../../core';

const triggerKeys = [KEY_CODES.SPACE];

const StyledDropdownButton = styled(NormalizedButton)`
  ${({ isCollapsed, isOpen, theme }) => css`
    ${typographySubtitle1(theme)};
    ${transitionAnimation('background-color, width')};
    align-items: center;
    background-color: ${isOpen ? `#4f545b` : `rgba(${getHexToRgb(theme.color.additional.light.value)}, 0.15)`};
    border-radius: ${theme.size.borderRadius.large.value};
    color: ${theme.color.additional.light.value};
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
    display: flex;
    height: ${getRem(36)};
    margin: 0 ${theme.size.spacing.medium.value};
    padding-left: ${getRem(6)};
    padding-right: ${isCollapsed ? getRem(6) : getRem(4)};
    width: ${isCollapsed ? NAVIGATION_DROPDOWN_WIDTHS.COLLAPSED : NAVIGATION_DROPDOWN_WIDTHS.EXPANDED};
  `}
`;

const StyledAvatarWrapper = styled(Avatar).withConfig({
  shouldForwardProp: (prop) => !['isCollapsed', 'isDisabled'].includes(prop),
})`
  background-color: ${({ theme, variant }) =>
    variant === AVATAR_VARIANTS.ICON ? 'transparent' : `rgba(${getHexToRgb(theme.color.additional.light.value)}, 0.7)`};
  height: ${getRem(24)};
  flex-shrink: 0;
  height: ${getRem(24)};
  margin-right: ${({ isCollapsed }) => (isCollapsed ? getRem(0) : getRem(8))};
  width: ${getRem(24)};
  ${({ variant }) =>
    variant === AVATAR_VARIANTS.ICON
      ? css`
          background-color: transparent;
        `
      : css`
          background-color: ${({ theme }) => `rgba(${getHexToRgb(theme.color.additional.light.value)}, 0.7)`};
        `}

  ${({ theme, variant }) =>
    variant === AVATAR_VARIANTS.IMAGE &&
    css`
      border-radius: ${theme.size.borderRadius.small.value};
    `};

  ${({ isDisabled, theme, variant }) =>
    css`
      ${isDisabled
        ? css`
            & > span > svg {
              fill: rgba(${getHexToRgb(theme.color.additional.light.value)}, 0.4);
            }
            & > span {
              color: rgba(${getHexToRgb(theme.color.additional.dark.value)}, 0.4);
            }
          `
        : css`
            & > span > svg {
              fill: rgba(${getHexToRgb(theme.color.additional.light.value)}, 0.7);
            }
            & > span {
              color: ${theme.color.additional.dark.value};
            }
          `}
      background-color:${isDisabled &&
      variant !== AVATAR_VARIANTS.ICON &&
      `rgba(${getHexToRgb(theme.color.additional.light.value)}, 0.4)`}
    `}
`;

const StyledIconCaret = styled(IconArrowDropDown).withConfig({
  shouldForwardProp: (prop) => !['isDisabled', 'isOpen'].includes(prop),
})`
  fill: ${({ theme }) => `rgba(${getHexToRgb(theme.color.additional.light.value)}, 0.7)`};
  flex-shrink: 0;
  margin-left: auto;
  ${({ isOpen }) =>
    isOpen &&
    css`
      transform: rotate(180deg);
    `};

  ${({ isDisabled, theme }) =>
    isDisabled &&
    css`
      fill: rgba(${getHexToRgb(theme.color.additional.light.value)}, 0.4);
    `}
`;

const StyledLabel = styled.span`
  ${({ isDisabled, theme }) => css`
    ${truncateText}
    ${typographyBody1(theme)};
    color: ${isDisabled
      ? `rgba(${getHexToRgb(theme.color.additional.light.value)}, 0.4)`
      : theme.color.additional.light.value};
  `}
`;

const DropdownButton = React.forwardRef(
  ({ avatarProps, dropdownLabel, isCollapsed, isDisabled, isOpen, onClick, ...other }, ref) => {
    return (
      <ElementStateWrapper
        isDisabled={isDisabled}
        stateStyles={dropdownButtonStateStyles}
        triggerClickEventAsKeyDown
        triggerKeys={triggerKeys}
      >
        <StyledDropdownButton
          disabled={isDisabled}
          isCollapsed={isCollapsed}
          isOpen={isOpen}
          onClick={onClick}
          ref={ref}
          tabIndex={isDisabled ? '-1' : '0'}
          type="button"
          {...other}
        >
          {
            <StyledAvatarWrapper
              isCollapsed={isCollapsed}
              isDisabled={isDisabled}
              size={avatarProps.variant === AVATAR_VARIANTS.ICON ? undefined : AVATAR_SIZES.SMALL}
              {...avatarProps}
            />
          }
          {!isCollapsed && <StyledLabel isDisabled={isDisabled}>{dropdownLabel}</StyledLabel>}
          {!isCollapsed && <StyledIconCaret isDisabled={isDisabled} isOpen={isOpen} />}
        </StyledDropdownButton>
      </ElementStateWrapper>
    );
  }
);

DropdownButton.propTypes = {
  /** Object of properties, which are applied to avatar component */
  avatarProps: PropTypes.shape({
    /** Outputs icon inside the avatar. Use icon component from the library */
    icon: PropTypes.node,
    /** Username, that this avatar depicts */
    label: PropTypes.node,
    /** Avatar variant. Values: [EMPTY, TEXT, ICON, IMAGE]. Default TEXT */
    variant: PropTypes.oneOf(Object.values(AVATAR_VARIANTS)),
  }).isRequired,
  /* Label for the dropdown button */
  dropdownLabel: PropTypes.node.isRequired,
  /** If true, component is collapsed */
  isCollapsed: PropTypes.bool,
  /** Dropdown will be disabled if true */
  isDisabled: PropTypes.bool,
  /** If true, shows popup menu */
  isOpen: PropTypes.bool,
  /** Callback that is called on click */
  onClick: PropTypes.func,
};

DropdownButton.defaultProps = {
  isCollapsed: false,
  isDisabled: false,
  isOpen: false,
  onClick: () => {},
};

export { DropdownButton };
