import React from 'react';
import { components } from 'react-select';
import styled, { css } from 'styled-components';

import { getRem } from '../../core';
import { INPUT_SIZES } from '../../input';

import { DROPDOWN_VARIANTS } from '../constants';
import { StyledSingleValueWrapper } from './SingleValueComponent';

const StyledWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex: 1 1 auto;
  flex-wrap: wrap;
  overflow: hidden;
`;

const StyledValueContainer = styled(components.ValueContainer)`
  align-items: ${({ isSearchable, variant }) =>
    variant === DROPDOWN_VARIANTS.DEFAULT && isSearchable ? 'flex-start' : 'center'};
  box-sizing: border-box;
  display: flex;
  flex: 1 1 auto;
  flex-wrap: wrap;
  margin: 0 ${getRem('12px')};
  overflow: hidden;
  ${({ hasValue, isMulti }) =>
    isMulti &&
    css`
      margin-left: ${hasValue && 0};
      margin-right: ${hasValue && 0};
      padding-left: ${hasValue && getRem('6px')};
      padding-right: 0;
    `};
  ${({ isMulti, size, theme }) => {
    if (isMulti) {
      if (size === INPUT_SIZES.LARGE) {
        return css`
          margin-top: ${getRem('22px')};
        `;
      }

      if (size === INPUT_SIZES.STANDARD) {
        return css`
          margin-top: ${getRem('18px')};
        `;
      }

      if (size === INPUT_SIZES.SMALL) {
        return css`
          margin-top: ${theme.size.spacing.large.value};
        `;
      }
    }
    return false;
  }};
  ${({ hasValue, isMulti, variant }) =>
    variant === DROPDOWN_VARIANTS.NO_OUTLINE &&
    css`
      margin-left: 0;
      margin-right: 0;
      margin-top: ${isMulti && 0};
      padding: ${hasValue ? `${getRem('2px')} 0` : 0};
    `};
  ${({ hasValue, isMulti, variant }) =>
    variant === DROPDOWN_VARIANTS.INLINE &&
    css`
      margin-top: ${isMulti && 0};
      padding-bottom: ${hasValue && getRem('2px')};
      padding-top: ${hasValue && getRem('2px')};
    `};
  ${({ isSearchable, variant }) =>
    variant !== DROPDOWN_VARIANTS.NO_OUTLINE &&
    !isSearchable &&
    css`
      & > input {
        position: absolute;
      }
    `};
`;

export const ValueContainerComponent = (props) => {
  /* eslint-disable react/prop-types */
  const {
    allSelectedLabel,
    dataTestId,
    fewSelectedLabel,
    isMultiOptionsTypeCheckbox,
    singleValueSubclass,
    size,
    theme,
    valueContainerProps,
    variant,
  } = props;
  const { children, getValue, isMulti, options } = valueContainerProps;
  /* eslint-enable */

  /* eslint-disable-next-line react/prop-types */
  const allOptions = isMulti && isMultiOptionsTypeCheckbox ? options[1].options : options;
  const selectedValues = getValue();
  const isAllOptionsSelected = allOptions.length === selectedValues.length;
  const isSingleOptionSelected = selectedValues && selectedValues.length === 1;
  const moreThanOneOptionSelected = selectedValues && selectedValues.length > 0 && !isSingleOptionSelected;
  const showSingleOptionSelected = isSingleOptionSelected && isMulti && isMultiOptionsTypeCheckbox;
  const showMoreThanOneOptionSelected = moreThanOneOptionSelected && isMulti && isMultiOptionsTypeCheckbox;

  return (
    <StyledWrapper data-testid={dataTestId ? `${dataTestId}-multi-value-container` : undefined} theme={theme}>
      <StyledValueContainer
        isMulti={isMulti && isMultiOptionsTypeCheckbox}
        size={size}
        variant={variant}
        {...valueContainerProps}
      >
        {children}
        {showSingleOptionSelected && (
          <StyledSingleValueWrapper className={singleValueSubclass} size={size} variant={variant}>
            <span>{selectedValues[0].label}</span>
          </StyledSingleValueWrapper>
        )}
        {showMoreThanOneOptionSelected && (
          <StyledSingleValueWrapper className={singleValueSubclass} size={size} variant={variant}>
            <span>{isAllOptionsSelected ? allSelectedLabel : `${selectedValues.length} ${fewSelectedLabel}`}</span>
          </StyledSingleValueWrapper>
        )}
      </StyledValueContainer>
    </StyledWrapper>
  );
};
