import React from 'react';

import {
  ListItemGraphic,
  ListItemPrimaryText,
  ListItemText,
  NestedList,
  NestedListItemContent,
  NestedListItemIcon,
} from 'lib/list';
import { removeObjectProperties } from 'lib/utilities';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { slideDownKeyframes } from '../shared-styles';
import { getListItemParentsIds } from '../utilities';
import {
  getHexToRgb,
  getRem,
  keyframesAnimation,
  typographyBody1,
  typographyButton,
  visuallyHidden,
} from './../../core';

const StyledListItemGraphic = styled(ListItemGraphic)``;
const StyledListItemPrimaryText = styled(ListItemPrimaryText)``;

const nestingLevel1Styles = css`
  padding-left: ${getRem(14)};
  ${({ isExpanded, isNested, theme }) =>
    isExpanded &&
    isNested &&
    css`
      background-color: rgba(${getHexToRgb(theme.color.additional.light.value)}, 0.2);

      ${StyledListItemGraphic},
      ${NestedListItemIcon} {
        fill: ${theme.color.additional.light.value};
      }
    `}

  ${StyledListItemPrimaryText} {
    ${({ isActive, isExpanded, isNested, theme }) =>
      css`
        ${isActive || (isExpanded && isNested) ? typographyButton(theme) : typographyBody1(theme)};
        color: ${theme.color.additional.light.value};
      `}
  }

  ${ListItemText} {
    width: ${getRem(162)};
  }

  ${NestedListItemIcon} {
    margin-right: ${getRem(12)};
  }
`;

const nestingLevel2Styles = css`
  ${keyframesAnimation(slideDownKeyframes)};
  border-radius: ${({ theme }) => theme.size.borderRadius.large.value};
  height: ${getRem(32)};
  margin: ${({ theme }) =>
    `${theme.size.spacing.small.value} 0 ${theme.size.spacing.small.value} ${theme.size.spacing.medium.value}`};
  padding-left: ${getRem(54)};
  width: ${getRem(240)};
  ${({ isExpanded, theme }) =>
    isExpanded &&
    css`
      margin-bottom: ${theme.size.spacing.small.value};
    `}

  &:hover,
    &:focus {
    ${StyledListItemPrimaryText} {
      color: ${({ theme }) => theme.color.additional.light.value};
    }
  }

  ${StyledListItemPrimaryText} {
    ${({ isActive, isExpanded, isNested, theme }) => css`
      ${typographyBody1(theme)};
      color: ${isActive && (!isExpanded || !isNested)
        ? theme.color.additional.light.value
        : `rgba(${getHexToRgb(theme.color.additional.light.value)}, 0.7)`};
    `}
  }

  ${ListItemText} {
    width: ${getRem(146)};
  }

  ${NestedListItemIcon} {
    margin-right: ${({ theme }) => theme.size.spacing.small.value};
  }
`;

const nestingLevel3Styles = css`
  ${keyframesAnimation(slideDownKeyframes)};
  border-radius: ${({ theme }) => theme.size.borderRadius.large.value};
  height: ${getRem(32)};
  margin: ${({ theme }) =>
    `${theme.size.spacing.small.value} 0 ${theme.size.spacing.small.value} ${theme.size.spacing.medium.value}`};
  padding-left: ${getRem(70)};
  width: ${getRem(240)};

  ${StyledListItemPrimaryText} {
    ${({ theme }) => typographyBody1(theme)};
    color: ${({ theme }) => theme.color.additional.light.value};
  }
`;

const StyledNestedList = styled(NestedList)`
  margin: 0;

  /* No other way to interact with list items */
  & > li > ul > li {
    overflow: hidden;

    & > ul > li {
      overflow: hidden;
    }

    &:last-child {
      border-bottom: 1px solid rgba(${({ theme }) => getHexToRgb(theme.color.additional.light.value)}, 0.25);
    }
  }

  ${StyledListItemGraphic} {
    fill: rgba(${({ theme }) => getHexToRgb(theme.color.additional.light.value)}, 0.7);
    margin-right: ${({ theme }) => theme.size.spacing.medium.value};
    vertical-align: middle;
  }

  ${NestedListItemIcon} {
    bottom: 0;
    fill: rgba(${({ theme }) => getHexToRgb(theme.color.additional.light.value)}, 0.7);
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    ${({ isCollapsed }) => isCollapsed && visuallyHidden};
  }
`;

const StyledNestedListItemContent = styled(NestedListItemContent)`
  height: ${getRem(40)};
  position: relative;

  &:hover,
  &:focus {
    background-color: rgba(${({ theme }) => getHexToRgb(theme.color.additional.light.value)}, 0.2);
    ${StyledListItemGraphic},
    ${NestedListItemIcon} {
      fill: ${({ theme }) => theme.color.additional.light.value};
    }
  }

  &:active {
    background-color: rgba(${({ theme }) => getHexToRgb(theme.color.additional.light.value)}, 0.25);
  }

  ${({ isActive, isExpanded, isNested, theme }) =>
    isActive &&
    (!isNested || !isExpanded) &&
    css`
      &,
      &:hover,
      &:focus {
        background-color: ${theme.color.primary[500].value};
      }

      ${StyledListItemGraphic},
      ${NestedListItemIcon} {
        fill: ${theme.color.additional.light.value};
      }
    `}

  ${({ nestingLevel }) => {
    switch (nestingLevel) {
      case 0:
        return nestingLevel1Styles;
      case 1:
        return nestingLevel2Styles;
      case 2:
        return nestingLevel3Styles;
      default:
        return css``;
    }
  }}
`;

const NavigationMenu = ({
  activeItemId,
  dataTestId,
  expandedItemIds,
  isCollapsed,
  items,
  onItemsExpandCollapse,
  ...other
}) => {
  const activeItemParentIds = getListItemParentsIds(items, activeItemId);
  const otherWithoutIsCheckable = removeObjectProperties(other, 'isCheckable');

  const handleItemsExpandCollapse = (itemIds) => {
    const activeItemFirstLevelParentId = Object.keys(activeItemParentIds)[0];
    const isActiveItemParentExpanding =
      !expandedItemIds[`${activeItemFirstLevelParentId}`] && itemIds[`${activeItemFirstLevelParentId}`];

    const expandedItemIdsWithPreviousState = isCollapsed ? { ...itemIds, ...expandedItemIds } : itemIds;

    const fullExpandedMenuItemIds = isActiveItemParentExpanding
      ? { ...expandedItemIdsWithPreviousState, ...activeItemParentIds }
      : expandedItemIdsWithPreviousState;

    onItemsExpandCollapse(fullExpandedMenuItemIds);
  };

  return (
    <div>
      <StyledNestedList
        activeItemIds={activeItemParentIds}
        customItemContentTag={StyledNestedListItemContent}
        customItemGraphicTag={StyledListItemGraphic}
        customItemPrimaryTextTag={StyledListItemPrimaryText}
        dataTestId={dataTestId ? `${dataTestId}-main-menu` : undefined}
        expandedItemIds={isCollapsed ? {} : expandedItemIds}
        isCollapsed={isCollapsed}
        items={items}
        onItemExpandCollapse={handleItemsExpandCollapse}
        {...otherWithoutIsCheckable}
      />
    </div>
  );
};

NavigationMenu.propTypes = {
  /** Currently active navigation item id */
  activeItemId: PropTypes.string,
  /** Id value used for testing */
  dataTestId: PropTypes.string,
  /** Item ids that are currently expanded. This prop consists of object entries, where key is the id of the item
   * and value is true if item is expanded and falsy (false, null, undefined) if it is not */
  expandedItemIds: PropTypes.shape({}),
  /** If true, component is collapsed */
  isCollapsed: PropTypes.bool,
  /** Items of navigation menu */
  items: PropTypes.arrayOf(PropTypes.shape({ ...NestedList.propTypes.items })).isRequired,
  /** Callback that is called on item expand or collapse */
  onItemsExpandCollapse: PropTypes.func.isRequired,
};

NavigationMenu.defaultProps = {
  activeItemId: '',
  dataTestId: undefined,
  expandedItemIds: {},
  isCollapsed: false,
};

export { NavigationMenu };
