import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledWrapperInner = styled.span`
  &:focus {
    outline: none;
  }
`;

const StyledWrapper = styled.span`
  &:focus {
    outline: none;
    ${StyledWrapperInner} {
      border-radius: ${({ theme }) => theme.size.borderRadius.extraSmall.value};
      box-shadow: 0 0 0 2px currentColor;
    }
  }
`;

/**
 * Helper component to make outline visible only on keyboard
 */
const OutlineKeyboard = React.forwardRef(({ children, innerProps, ...other }, ref) => (
  <StyledWrapper ref={ref} {...other}>
    <StyledWrapperInner tabIndex={-1} {...innerProps}>
      {children}
    </StyledWrapperInner>
  </StyledWrapper>
));

OutlineKeyboard.propTypes = {
  /** Rendered content */
  children: PropTypes.node.isRequired,
  /** Inner wrapper element properties */
  innerProps: PropTypes.shape({}),
};

OutlineKeyboard.defaultProps = {
  innerProps: {},
};

export { OutlineKeyboard };
