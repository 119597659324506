export const CONNECTOR_VARIANTS = {
  HORIZONTAL: 'horizontal',
  VERTICAL: 'vertical',
};

export const STEPPER_POSITIONS = {
  BOTTOM: 'bottom',
  RIGHT: 'right',
};

export const STEPPER_VARIANTS = {
  HORIZONTAL: 'horizontal',
  SIMPLE: 'simple',
  VERTICAL: 'vertical',
};

export const STEPPER_MAX_WIDTH = 144;
