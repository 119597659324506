import React from 'react';
import { typographyBody1, getRem } from 'lib/core';
import { IconCheck, IconClose } from 'lib/icons';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const StyledContainer = styled.div`
  ${({ theme }) => typographyBody1(theme)};
  display: flex;
  justify-content: start;
  align-items: center;
  color: ${({ isValid, theme }) =>
    isValid ? theme.color.system.positive[500].value : theme.color.additional.dark.value};
`;

const StyledLabel = styled.div`
  margin-bottom: ${({ theme }) => theme.size.spacing.small.value};
  width: ${getRem(160)};
`;

const StyledIconCheck = styled(IconCheck)`
  width: ${getRem(16)};
  height: ${getRem(16)};
  position: relative;
  top: ${getRem(-2)};
  ${({ theme }) =>
    css`
      fill: ${theme.color.system.positive[500].value};
      margin-right: ${theme.size.spacing.small.value};
    `}
`;

const StyledIconClose = styled(IconClose)`
  width: ${getRem(16)};
  height: ${getRem(16)};
  position: relative;
  top: ${getRem(-2)};
  ${({ theme }) =>
    css`
      fill: ${theme.color.additional.dark.value};
      margin-right: ${theme.size.spacing.small.value};
    `}
`;

export const StrengthLabel = ({ isValid, label }) => (
  <StyledContainer isValid={isValid}>
    {isValid ? <StyledIconCheck /> : <StyledIconClose />}
    <StyledLabel>{label}</StyledLabel>
  </StyledContainer>
);

StrengthLabel.propTypes = {
  /** boolean prop to change icon and color of label */
  isValid: PropTypes.bool.isRequired,
  /** label to display text */
  label: PropTypes.string.isRequired,
};
