import React from 'react';
import { BUTTON_BACKGROUND_APPEARANCES, TextButton } from 'lib/button';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { NAVIGATION_WIDTHS } from '../constants';
import { getRem, transitionAnimation, typographySubtitle1 } from './../../core';

const StyledHeaderButton = styled(TextButton)`
  ${({ theme }) => typographySubtitle1(theme)};
  border-radius: 0;
  color: ${({ theme }) => theme.color.additional.light.value};
  height: ${getRem(56)};
  justify-content: flex-start;
  text-align: left;
  width: 100%;

  &::after {
    ${transitionAnimation('background-color, width')};
    border-radius: 0;
    width: ${({ isCollapsed }) => (isCollapsed ? NAVIGATION_WIDTHS.COLLAPSED : NAVIGATION_WIDTHS.EXPANDED)};
  }

  && {
    &:hover,
    &:focus {
      box-shadow: none;

      &::after {
        background-color: ${({ theme }) => theme.color.state.light.hover.value};
      }
    }
  }

  /* No other way to interact with icon */
  svg {
    display: inline-flex;
    flex-grow: 0;
    flex-shrink: 0;
    height: ${getRem(26)};
    margin: 0 ${({ theme }) => theme.size.spacing.medium.value};
  }
`;

const HeaderButton = ({ icon, isCollapsed, isOpen, onClose, onOpen, text, ...other }) => (
  <StyledHeaderButton
    backgroundAppearance={BUTTON_BACKGROUND_APPEARANCES.DARK}
    hideText={!text}
    icon={icon}
    isCollapsed={isCollapsed}
    onClick={isOpen ? onClose : onOpen}
    text={text}
    {...other}
  />
);

HeaderButton.propTypes = {
  /** Sets the icon for the button */
  icon: PropTypes.node.isRequired,
  /** If true, component is collapsed */
  isCollapsed: PropTypes.bool,
  /** If true, shows popup menu */
  isOpen: PropTypes.bool,
  /** Callback to be called when popup menu is being closed */
  onClose: PropTypes.func,
  /** Callback to be called when popup menu is getting opened */
  onOpen: PropTypes.func,
  /** Sets the label for the button */
  text: PropTypes.node,
};

HeaderButton.defaultProps = {
  isCollapsed: false,
  isOpen: false,
  onClose: undefined,
  onOpen: undefined,
  text: '',
};

export { HeaderButton };
