import React from 'react';
import { Loader, StyledLoaderSpinner } from 'lib/loader';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledButtonLoader = styled(Loader)`
  ${StyledLoaderSpinner} {
    z-index: ${({ theme }) => theme.zIndex.buttonContent.value};
  }
`;

const ButtonLoader = ({ label, ...other }) => <StyledButtonLoader hideLabel label={label} {...other} />;

ButtonLoader.propTypes = {
  /** Text that is read for screen reader users */
  label: PropTypes.node,
};

ButtonLoader.defaultProps = {
  label: 'Loading...',
};

export { ButtonLoader, StyledButtonLoader };
