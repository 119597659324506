import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { getRem } from './../../core';

const StyledTableHeaderCustomContent = styled.div`
  background-color: ${({ theme }) => theme.color.additional.light.value};
  border: 1px solid ${({ theme }) => theme.color.gray[200].value};
  border-bottom: none;
  border-radius: ${({ theme }) =>
    `${getRem(theme.size.borderRadius.large.value)} ${getRem(theme.size.borderRadius.large.value)} 0 0`};
  padding: ${({ theme }) => `${theme.size.spacing.medium.value} ${theme.size.spacing.large.value}`};

  &:not(:first-child) {
    border-radius: 0;
    border-top: none;
    padding: ${({ theme }) =>
      `0 ${theme.size.spacing.large.value} ${theme.size.spacing.medium.value} ${theme.size.spacing.large.value}`};
  }
`;

const TableHeaderCustomContent = ({ customHeaderContent, ...other }) => (
  <StyledTableHeaderCustomContent {...other}>{customHeaderContent}</StyledTableHeaderCustomContent>
);

TableHeaderCustomContent.propTypes = {
  /** Custom content to render */
  customHeaderContent: PropTypes.node.isRequired,
};

export { TableHeaderCustomContent };
