import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { STEPPER_POSITIONS, STEPPER_VARIANTS, STEPPER_MAX_WIDTH } from '../constants';

const StyledWrapper = styled.div`
  align-items: ${({ position }) => (position === 'bottom' ? 'flex-start' : 'center')};
  box-sizing: border-box;
  display: flex;
  height: inherit;
  justify-content: ${({ variant }) => (variant === STEPPER_VARIANTS.SIMPLE ? 'space-between' : 'center')};
  padding: ${({ theme }) => theme.size.spacing.large.value};
  width: ${({ variant }) => (variant !== STEPPER_VARIANTS.HORIZONTAL ? '100%' : '90%')};
  ${({ variant }) =>
    variant === STEPPER_VARIANTS.VERTICAL &&
    css`
      align-items: flex-start;
      flex-direction: column;
      max-width: ${STEPPER_MAX_WIDTH}px;
      padding-top: 0;
      padding-bottom: 0;
    `}
`;

export const StepWrapper = ({ children, ...other }) => {
  return <StyledWrapper {...other}>{children}</StyledWrapper>;
};

StepWrapper.propTypes = {
  /** Any content inserted between component tags */
  children: PropTypes.node,
  /** Position of the labels */
  position: PropTypes.oneOf(Object.values(STEPPER_POSITIONS)),
  /** Variant of the stepper */
  variant: PropTypes.oneOf(Object.values(STEPPER_VARIANTS)),
};

StepWrapper.defaultProps = {
  children: undefined,
  position: STEPPER_POSITIONS.RIGHT,
  variant: STEPPER_VARIANTS.HORIZONTAL,
};
