import React from 'react';
import { getRem } from 'lib/core';
import styled, { keyframes } from 'styled-components';

const animationDuration = '1.5s';
const spinnerSize = '20px';

const rotateSpinner = keyframes`
    100% {
        transform: rotate(360deg);
    }
`;

const spinnerDash = keyframes`
    0% {
        stroke-dasharray: 1px, 200px;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 100px, 200px;
        stroke-dashoffset: -15px;
    }
    100% {
        stroke-dasharray: 100px, 200px;
        stroke-dashoffset: -125px;
    }
`;

const StyledCircle = styled.circle`
  animation: ${spinnerDash} ${animationDuration} ease-in-out infinite;
  stroke-dasharray: 80px 200px;
  stroke-dashoffset: 0;
  transform-origin: center;
`;

const StyledSpinner = styled.svg`
  animation: ${rotateSpinner} ${animationDuration} linear infinite;
  flex-shrink: 0;
  height: ${getRem(spinnerSize)};
  stroke: inherit;
  stroke-width: 4px;
  width: ${getRem(spinnerSize)};
`;

export const StyledLoaderSpinner = ({ ...other }) => {
  return (
    <StyledSpinner viewBox="0 0 50 50">
      <StyledCircle className="path" cx="25" cy="25" r="20" fill="none" />
    </StyledSpinner>
  );
};

StyledLoaderSpinner.propTypes = {};

StyledLoaderSpinner.defaultProps = {};
