// This is a generated file. DO NOT modify directly.

import {
  AccountCircle,
  Accounting,
  Add,
  AddAlert,
  AddCircle,
  AddLocation,
  Admin,
  Alarm,
  AlarmAdd,
  AlarmOff,
  AlarmOn,
  Alignment,
  Apps,
  ArrowBack,
  ArrowCascadeLeft,
  ArrowCascadeRight,
  ArrowDownward,
  ArrowDropDown,
  ArrowDropUp,
  ArrowForward,
  ArrowUpward,
  Assignment,
  AttachFile,
  BarChart,
  BodyStyleSedan,
  Bookmark,
  BookmarkBorder,
  BubbleChart,
  BuildingBodyShop,
  BuildingCorporateOffice,
  BuildingDealer,
  BuildingMakeReady,
  BuildingNetworkColo,
  BuildingPartsWarehouse,
  BuildingPreownedBuilding,
  BuildingQuickLane,
  BuildingServiceShop,
  Campaigns,
  Cancel,
  CancelOutlined,
  CashRegister,
  CertifiedPreowned,
  Chat,
  Check,
  CheckBoxChecked,
  CheckBoxIndeterminate,
  CheckBoxOutlineBlank,
  CheckCircle,
  ChevronLeft,
  ChevronRight,
  Close,
  Comment,
  CommentAdd,
  Concierge,
  ConciergePremium,
  ContactSupport,
  ContentCopy,
  ContentCut,
  ContentPaste,
  Conversation,
  Customer,
  CustomerDeclined,
  CustomerReplied,
  CustomerWaiting,
  Customers,
  Dashboard,
  DatavisDown,
  DatavisDownFilled,
  DatavisDownOutline,
  DatavisUp,
  DatavisUpFilled,
  DatavisUpOutline,
  DateRange,
  Dealer,
  DealerBarcodeLabelPrinter,
  DealerBarcodeReader,
  DealerBwPrinter,
  DealerColorPrinter,
  DealerColorPrinter2,
  DealerColorPrinter3,
  DealerComputer,
  DealerDocumentScanner,
  DealerImpactPrinter,
  DealerMagneticInkPrinter,
  DealerPay,
  DealerPhone,
  DealerStaff,
  Declined,
  Delete,
  Demo,
  Description,
  DeviceLog,
  Drafts,
  DragHandle,
  DropOff,
  DropOffEarly,
  DropOffLate,
  Edit,
  EnFlag,
  Engine,
  Error,
  ErrorOutlined,
  EsFlag,
  Event,
  EventAvailable,
  EventBusy,
  EventNote,
  ExpandLess,
  ExpandMore,
  Express,
  Face,
  Favorite,
  FavoriteBorder,
  FileDownload,
  FileUpload,
  FilterList,
  Flag,
  Folder,
  FolderCreateNew,
  FolderOpen,
  FolderShared,
  FolderSpecial,
  FrFlag,
  Fullscreen,
  FullscreenExit,
  Help,
  Home,
  Info,
  Label,
  LabelOutline,
  LicensePlate,
  Link,
  List,
  LocationOff,
  LocationOn,
  LockOpen,
  LockOutline,
  Logo,
  Logout,
  Mail,
  Menu,
  Message,
  MessageExpired,
  MoreHoriz,
  MoreVert,
  MoveToInbox,
  NetworkingCdkDmsServer,
  NetworkingCloset,
  NetworkingCloudService,
  NetworkingCoverageArea,
  NetworkingRoom,
  NetworkingRouter,
  NetworkingSdwan,
  NetworkingSecurity,
  NetworkingServerFirewall,
  NetworkingServerRacks,
  NetworkingSwitches,
  NetworkingWireless,
  NextAppointment,
  NoteAdd,
  Notification,
  NotificationActive,
  NotificationOff,
  NotificationPaused,
  OEMCommunications,
  Odometer,
  OpenInBrowser,
  OpenInNew,
  Order,
  OtherReports,
  PageFirst,
  PageLast,
  Parts,
  PartsInStock,
  PartsOrdered,
  PartsOutOfStock,
  Payroll,
  People,
  Person,
  PersonAdd,
  Phone,
  PieChart,
  Print,
  RadioButtonChecked,
  RadioButtonUnchecked,
  Redo,
  Refresh,
  Remove,
  Rental,
  Reorder,
  RepairOrder,
  Reply,
  ReplyAll,
  Restore,
  Sales,
  Save,
  Schedule,
  Scheduling,
  Search,
  Send,
  SendEmail,
  ServiceLift,
  Settings,
  SmsCustomerReplied,
  Snooze,
  Sort,
  Star,
  StarBorder,
  StarHalf,
  StatusDefault,
  StatusInformation,
  StatusInformationI,
  StatusInformationTrendDown,
  StatusInformationTrendUp,
  StatusNegative,
  StatusNegativeTrendDown,
  StatusNegativeTrendUp,
  StatusNegativeX,
  StatusPositive,
  StatusPositiveSuccess,
  StatusPositiveTrendDown,
  StatusPositiveTrendUp,
  StatusWarning,
  StatusWarningExclamation,
  StatusWarningTrendDown,
  StatusWarningTrendUp,
  StockIn,
  StockOut,
  SubdirectoryArrowLeft,
  SubdirectoryArrowRight,
  Sync,
  TextSent,
  TextSms,
  ThreatLog,
  ThumbDown,
  ThumbUp,
  Timeline,
  Tire,
  Today,
  TrendingDown,
  TrendingFlat,
  TrendingUp,
  Undo,
  UnfoldLess,
  UnfoldMore,
  User,
  UserAdd,
  Users,
  Valet,
  VehicleBus,
  VehicleCar,
  VehicleLeaving,
  VehicleLoaner,
  VehicleShuttle,
  ViewCard,
  ViewGrid,
  ViewList,
  Visibility,
  VisibilityOff,
  Waiting,
  WaitingOnCustomerResponse,
  WaitingParts,
  WaitingWarranty,
  Warranty,
  WeightScale,
  ZoomIn,
  ZoomOut,
} from "./svgPaths";
import { withIcon } from "./withIcon";
export const IconAccountCircle = withIcon(AccountCircle);
export const IconAccounting = withIcon(Accounting);
export const IconAdd = withIcon(Add);
export const IconAddAlert = withIcon(AddAlert);
export const IconAddCircle = withIcon(AddCircle);
export const IconAddLocation = withIcon(AddLocation);
export const IconAdmin = withIcon(Admin);
export const IconAlarm = withIcon(Alarm);
export const IconAlarmAdd = withIcon(AlarmAdd);
export const IconAlarmOff = withIcon(AlarmOff);
export const IconAlarmOn = withIcon(AlarmOn);
export const IconAlignment = withIcon(Alignment);
export const IconApps = withIcon(Apps);
export const IconArrowBack = withIcon(ArrowBack);
export const IconArrowCascadeLeft = withIcon(ArrowCascadeLeft);
export const IconArrowCascadeRight = withIcon(ArrowCascadeRight);
export const IconArrowDownward = withIcon(ArrowDownward);
export const IconArrowDropDown = withIcon(ArrowDropDown);
export const IconArrowDropUp = withIcon(ArrowDropUp);
export const IconArrowForward = withIcon(ArrowForward);
export const IconArrowUpward = withIcon(ArrowUpward);
export const IconAssignment = withIcon(Assignment);
export const IconAttachFile = withIcon(AttachFile);
export const IconBarChart = withIcon(BarChart);
export const IconBodyStyleSedan = withIcon(BodyStyleSedan);
export const IconBookmark = withIcon(Bookmark);
export const IconBookmarkBorder = withIcon(BookmarkBorder);
export const IconBubbleChart = withIcon(BubbleChart);
export const IconBuildingBodyShop = withIcon(BuildingBodyShop);
export const IconBuildingCorporateOffice = withIcon(BuildingCorporateOffice);
export const IconBuildingDealer = withIcon(BuildingDealer);
export const IconBuildingMakeReady = withIcon(BuildingMakeReady);
export const IconBuildingNetworkColo = withIcon(BuildingNetworkColo);
export const IconBuildingPartsWarehouse = withIcon(BuildingPartsWarehouse);
export const IconBuildingPreownedBuilding = withIcon(BuildingPreownedBuilding);
export const IconBuildingQuickLane = withIcon(BuildingQuickLane);
export const IconBuildingServiceShop = withIcon(BuildingServiceShop);
export const IconCampaigns = withIcon(Campaigns);
export const IconCancel = withIcon(Cancel);
export const IconCancelOutlined = withIcon(CancelOutlined);
export const IconCashRegister = withIcon(CashRegister);
export const IconCertifiedPreowned = withIcon(CertifiedPreowned);
export const IconChat = withIcon(Chat);
export const IconCheck = withIcon(Check);
export const IconCheckBoxChecked = withIcon(CheckBoxChecked);
export const IconCheckBoxIndeterminate = withIcon(CheckBoxIndeterminate);
export const IconCheckBoxOutlineBlank = withIcon(CheckBoxOutlineBlank);
export const IconCheckCircle = withIcon(CheckCircle);
export const IconChevronLeft = withIcon(ChevronLeft);
export const IconChevronRight = withIcon(ChevronRight);
export const IconClose = withIcon(Close);
export const IconComment = withIcon(Comment);
export const IconCommentAdd = withIcon(CommentAdd);
export const IconConcierge = withIcon(Concierge);
export const IconConciergePremium = withIcon(ConciergePremium);
export const IconContactSupport = withIcon(ContactSupport);
export const IconContentCopy = withIcon(ContentCopy);
export const IconContentCut = withIcon(ContentCut);
export const IconContentPaste = withIcon(ContentPaste);
export const IconConversation = withIcon(Conversation);
export const IconCustomer = withIcon(Customer);
export const IconCustomerDeclined = withIcon(CustomerDeclined);
export const IconCustomerReplied = withIcon(CustomerReplied);
export const IconCustomerWaiting = withIcon(CustomerWaiting);
export const IconCustomers = withIcon(Customers);
export const IconDashboard = withIcon(Dashboard);
export const IconDatavisDown = withIcon(DatavisDown);
export const IconDatavisDownFilled = withIcon(DatavisDownFilled);
export const IconDatavisDownOutline = withIcon(DatavisDownOutline);
export const IconDatavisUp = withIcon(DatavisUp);
export const IconDatavisUpFilled = withIcon(DatavisUpFilled);
export const IconDatavisUpOutline = withIcon(DatavisUpOutline);
export const IconDateRange = withIcon(DateRange);
export const IconDealer = withIcon(Dealer);
export const IconDealerBarcodeLabelPrinter = withIcon(
  DealerBarcodeLabelPrinter
);
export const IconDealerBarcodeReader = withIcon(DealerBarcodeReader);
export const IconDealerBwPrinter = withIcon(DealerBwPrinter);
export const IconDealerColorPrinter = withIcon(DealerColorPrinter);
export const IconDealerColorPrinter2 = withIcon(DealerColorPrinter2);
export const IconDealerColorPrinter3 = withIcon(DealerColorPrinter3);
export const IconDealerComputer = withIcon(DealerComputer);
export const IconDealerDocumentScanner = withIcon(DealerDocumentScanner);
export const IconDealerImpactPrinter = withIcon(DealerImpactPrinter);
export const IconDealerMagneticInkPrinter = withIcon(DealerMagneticInkPrinter);
export const IconDealerPay = withIcon(DealerPay);
export const IconDealerPhone = withIcon(DealerPhone);
export const IconDealerStaff = withIcon(DealerStaff);
export const IconDeclined = withIcon(Declined);
export const IconDelete = withIcon(Delete);
export const IconDemo = withIcon(Demo);
export const IconDescription = withIcon(Description);
export const IconDeviceLog = withIcon(DeviceLog);
export const IconDrafts = withIcon(Drafts);
export const IconDragHandle = withIcon(DragHandle);
export const IconDropOff = withIcon(DropOff);
export const IconDropOffEarly = withIcon(DropOffEarly);
export const IconDropOffLate = withIcon(DropOffLate);
export const IconEdit = withIcon(Edit);
export const IconEnFlag = withIcon(EnFlag);
export const IconEngine = withIcon(Engine);
export const IconError = withIcon(Error);
export const IconErrorOutlined = withIcon(ErrorOutlined);
export const IconEsFlag = withIcon(EsFlag);
export const IconEvent = withIcon(Event);
export const IconEventAvailable = withIcon(EventAvailable);
export const IconEventBusy = withIcon(EventBusy);
export const IconEventNote = withIcon(EventNote);
export const IconExpandLess = withIcon(ExpandLess);
export const IconExpandMore = withIcon(ExpandMore);
export const IconExpress = withIcon(Express);
export const IconFace = withIcon(Face);
export const IconFavorite = withIcon(Favorite);
export const IconFavoriteBorder = withIcon(FavoriteBorder);
export const IconFileDownload = withIcon(FileDownload);
export const IconFileUpload = withIcon(FileUpload);
export const IconFilterList = withIcon(FilterList);
export const IconFlag = withIcon(Flag);
export const IconFolder = withIcon(Folder);
export const IconFolderCreateNew = withIcon(FolderCreateNew);
export const IconFolderOpen = withIcon(FolderOpen);
export const IconFolderShared = withIcon(FolderShared);
export const IconFolderSpecial = withIcon(FolderSpecial);
export const IconFrFlag = withIcon(FrFlag);
export const IconFullscreen = withIcon(Fullscreen);
export const IconFullscreenExit = withIcon(FullscreenExit);
export const IconHelp = withIcon(Help);
export const IconHome = withIcon(Home);
export const IconInfo = withIcon(Info);
export const IconLabel = withIcon(Label);
export const IconLabelOutline = withIcon(LabelOutline);
export const IconLicensePlate = withIcon(LicensePlate);
export const IconLink = withIcon(Link);
export const IconList = withIcon(List);
export const IconLocationOff = withIcon(LocationOff);
export const IconLocationOn = withIcon(LocationOn);
export const IconLockOpen = withIcon(LockOpen);
export const IconLockOutline = withIcon(LockOutline);
export const IconLogo = withIcon(Logo);
export const IconLogout = withIcon(Logout);
export const IconMail = withIcon(Mail);
export const IconMenu = withIcon(Menu);
export const IconMessage = withIcon(Message);
export const IconMessageExpired = withIcon(MessageExpired);
export const IconMoreHoriz = withIcon(MoreHoriz);
export const IconMoreVert = withIcon(MoreVert);
export const IconMoveToInbox = withIcon(MoveToInbox);
export const IconNetworkingCdkDmsServer = withIcon(NetworkingCdkDmsServer);
export const IconNetworkingCloset = withIcon(NetworkingCloset);
export const IconNetworkingCloudService = withIcon(NetworkingCloudService);
export const IconNetworkingCoverageArea = withIcon(NetworkingCoverageArea);
export const IconNetworkingRoom = withIcon(NetworkingRoom);
export const IconNetworkingRouter = withIcon(NetworkingRouter);
export const IconNetworkingSdwan = withIcon(NetworkingSdwan);
export const IconNetworkingSecurity = withIcon(NetworkingSecurity);
export const IconNetworkingServerFirewall = withIcon(NetworkingServerFirewall);
export const IconNetworkingServerRacks = withIcon(NetworkingServerRacks);
export const IconNetworkingSwitches = withIcon(NetworkingSwitches);
export const IconNetworkingWireless = withIcon(NetworkingWireless);
export const IconNextAppointment = withIcon(NextAppointment);
export const IconNoteAdd = withIcon(NoteAdd);
export const IconNotification = withIcon(Notification);
export const IconNotificationActive = withIcon(NotificationActive);
export const IconNotificationOff = withIcon(NotificationOff);
export const IconNotificationPaused = withIcon(NotificationPaused);
export const IconOEMCommunications = withIcon(OEMCommunications);
export const IconOdometer = withIcon(Odometer);
export const IconOpenInBrowser = withIcon(OpenInBrowser);
export const IconOpenInNew = withIcon(OpenInNew);
export const IconOrder = withIcon(Order);
export const IconOtherReports = withIcon(OtherReports);
export const IconPageFirst = withIcon(PageFirst);
export const IconPageLast = withIcon(PageLast);
export const IconParts = withIcon(Parts);
export const IconPartsInStock = withIcon(PartsInStock);
export const IconPartsOrdered = withIcon(PartsOrdered);
export const IconPartsOutOfStock = withIcon(PartsOutOfStock);
export const IconPayroll = withIcon(Payroll);
export const IconPeople = withIcon(People);
export const IconPerson = withIcon(Person);
export const IconPersonAdd = withIcon(PersonAdd);
export const IconPhone = withIcon(Phone);
export const IconPieChart = withIcon(PieChart);
export const IconPrint = withIcon(Print);
export const IconRadioButtonChecked = withIcon(RadioButtonChecked);
export const IconRadioButtonUnchecked = withIcon(RadioButtonUnchecked);
export const IconRedo = withIcon(Redo);
export const IconRefresh = withIcon(Refresh);
export const IconRemove = withIcon(Remove);
export const IconRental = withIcon(Rental);
export const IconReorder = withIcon(Reorder);
export const IconRepairOrder = withIcon(RepairOrder);
export const IconReply = withIcon(Reply);
export const IconReplyAll = withIcon(ReplyAll);
export const IconRestore = withIcon(Restore);
export const IconSales = withIcon(Sales);
export const IconSave = withIcon(Save);
export const IconSchedule = withIcon(Schedule);
export const IconScheduling = withIcon(Scheduling);
export const IconSearch = withIcon(Search);
export const IconSend = withIcon(Send);
export const IconSendEmail = withIcon(SendEmail);
export const IconServiceLift = withIcon(ServiceLift);
export const IconSettings = withIcon(Settings);
export const IconSmsCustomerReplied = withIcon(SmsCustomerReplied);
export const IconSnooze = withIcon(Snooze);
export const IconSort = withIcon(Sort);
export const IconStar = withIcon(Star);
export const IconStarBorder = withIcon(StarBorder);
export const IconStarHalf = withIcon(StarHalf);
export const IconStatusDefault = withIcon(StatusDefault);
export const IconStatusInformation = withIcon(StatusInformation);
export const IconStatusInformationI = withIcon(StatusInformationI);
export const IconStatusInformationTrendDown = withIcon(
  StatusInformationTrendDown
);
export const IconStatusInformationTrendUp = withIcon(StatusInformationTrendUp);
export const IconStatusNegative = withIcon(StatusNegative);
export const IconStatusNegativeTrendDown = withIcon(StatusNegativeTrendDown);
export const IconStatusNegativeTrendUp = withIcon(StatusNegativeTrendUp);
export const IconStatusNegativeX = withIcon(StatusNegativeX);
export const IconStatusPositive = withIcon(StatusPositive);
export const IconStatusPositiveSuccess = withIcon(StatusPositiveSuccess);
export const IconStatusPositiveTrendDown = withIcon(StatusPositiveTrendDown);
export const IconStatusPositiveTrendUp = withIcon(StatusPositiveTrendUp);
export const IconStatusWarning = withIcon(StatusWarning);
export const IconStatusWarningExclamation = withIcon(StatusWarningExclamation);
export const IconStatusWarningTrendDown = withIcon(StatusWarningTrendDown);
export const IconStatusWarningTrendUp = withIcon(StatusWarningTrendUp);
export const IconStockIn = withIcon(StockIn);
export const IconStockOut = withIcon(StockOut);
export const IconSubdirectoryArrowLeft = withIcon(SubdirectoryArrowLeft);
export const IconSubdirectoryArrowRight = withIcon(SubdirectoryArrowRight);
export const IconSync = withIcon(Sync);
export const IconTextSent = withIcon(TextSent);
export const IconTextSms = withIcon(TextSms);
export const IconThreatLog = withIcon(ThreatLog);
export const IconThumbDown = withIcon(ThumbDown);
export const IconThumbUp = withIcon(ThumbUp);
export const IconTimeline = withIcon(Timeline);
export const IconTire = withIcon(Tire);
export const IconToday = withIcon(Today);
export const IconTrendingDown = withIcon(TrendingDown);
export const IconTrendingFlat = withIcon(TrendingFlat);
export const IconTrendingUp = withIcon(TrendingUp);
export const IconUndo = withIcon(Undo);
export const IconUnfoldLess = withIcon(UnfoldLess);
export const IconUnfoldMore = withIcon(UnfoldMore);
export const IconUser = withIcon(User);
export const IconUserAdd = withIcon(UserAdd);
export const IconUsers = withIcon(Users);
export const IconValet = withIcon(Valet);
export const IconVehicleBus = withIcon(VehicleBus);
export const IconVehicleCar = withIcon(VehicleCar);
export const IconVehicleLeaving = withIcon(VehicleLeaving);
export const IconVehicleLoaner = withIcon(VehicleLoaner);
export const IconVehicleShuttle = withIcon(VehicleShuttle);
export const IconViewCard = withIcon(ViewCard);
export const IconViewGrid = withIcon(ViewGrid);
export const IconViewList = withIcon(ViewList);
export const IconVisibility = withIcon(Visibility);
export const IconVisibilityOff = withIcon(VisibilityOff);
export const IconWaiting = withIcon(Waiting);
export const IconWaitingOnCustomerResponse = withIcon(
  WaitingOnCustomerResponse
);
export const IconWaitingParts = withIcon(WaitingParts);
export const IconWaitingWarranty = withIcon(WaitingWarranty);
export const IconWarranty = withIcon(Warranty);
export const IconWeightScale = withIcon(WeightScale);
export const IconZoomIn = withIcon(ZoomIn);
export const IconZoomOut = withIcon(ZoomOut);
