import React from 'react';
import PropTypes from 'prop-types';

import { CHIP_BACKGROUND_APPEARANCES } from '../../constants';
import { ChipGroup, ChipOption } from '../../elements';
import { Chip } from '../Chip';

const ActionChips = ({ backgroundAppearance, dataTestId, icon, isLoading, options, ...other }) => (
  <ChipGroup data-testid={dataTestId} {...other}>
    {options.length > 0 &&
      options.map((option) => {
        const { icon: optionIcon, isLoading: optionIsLoading, label, onClick, ...otherOptionProps } = option;
        return (
          <ChipOption key={label}>
            <Chip
              backgroundAppearance={option.backgroundAppearance || backgroundAppearance}
              dataTestId={dataTestId ? `${dataTestId}-button` : undefined}
              icon={icon || optionIcon}
              isLoading={isLoading || optionIsLoading}
              label={label}
              onClick={onClick}
              {...otherOptionProps}
            />
          </ChipOption>
        );
      })}
  </ChipGroup>
);

ActionChips.propTypes = {
  /** Will adjust chip styles accordingly to background it is represented on */
  backgroundAppearance: PropTypes.oneOf(Object.values(CHIP_BACKGROUND_APPEARANCES)),
  /** Id value used for testing */
  dataTestId: PropTypes.string,
  /** Shows icon inside the button. Use icon component from the library */
  icon: PropTypes.node,
  /** If true, changes button content into loader icon with loader text */
  isLoading: PropTypes.bool,
  /** Array of options with label, isLoading, icon and onClick callback function */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      /** Will adjust chip styles accordingly to background it is represented on */
      backgroundAppearance: PropTypes.oneOf(Object.values(CHIP_BACKGROUND_APPEARANCES)),
      /** Shows icon inside the button */
      icon: PropTypes.node,
      /** If true, changes content of an individual button into loader icon with loader text */
      isLoading: PropTypes.bool,
      /** Chip label */
      label: PropTypes.node.isRequired,
      /** Callback function which is triggered on chip click */
      onClick: PropTypes.func,
    })
  ).isRequired,
};

ActionChips.defaultProps = {
  backgroundAppearance: CHIP_BACKGROUND_APPEARANCES.LIGHT,
  dataTestId: '',
  icon: undefined,
  isLoading: false,
};

export { ActionChips };
