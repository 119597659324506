import React from 'react';
import PropTypes from 'prop-types';

import { CHIP_BACKGROUND_APPEARANCES } from '../../constants';
import { ChipGroup, ChipOption } from '../../elements';
import { Chip } from '../Chip';
const ChoiceChips = ({
  backgroundAppearance,
  dataTestId,
  icon,
  isDisabled,
  isSelected,
  onClick,
  options,
  value,
  ...other
}) => {
  const handleSelection = (option) => {
    const sameChipSelected = value === option;
    onClick(sameChipSelected ? null : option);
  };

  return (
    <ChipGroup data-testid={dataTestId} {...other}>
      {options.map((option, index) => {
        const {
          icon: optionIcon,
          isDisabled: optionIsDisabled,
          isSelected: optionIsSelected,
          label,
          value: optionValue,
          ...otherOptionProps
        } = option;
        return (
          <ChipOption key={optionValue || index}>
            <Chip
              backgroundAppearance={option.backgroundAppearance || backgroundAppearance}
              dataTestId={dataTestId ? `${dataTestId}-${index + 1}` : undefined}
              icon={icon || optionIcon}
              isSelected={value === optionValue}
              isDisabled={isDisabled || optionIsDisabled}
              label={label}
              onClick={handleSelection}
              value={optionValue}
              {...otherOptionProps}
            />
          </ChipOption>
        );
      })}
    </ChipGroup>
  );
};

ChoiceChips.propTypes = {
  /** Will adjust chip styles accordingly to background it is represented on */
  backgroundAppearance: PropTypes.oneOf(Object.values(CHIP_BACKGROUND_APPEARANCES)),
  /** Id value used for testing */
  dataTestId: PropTypes.string,
  /** Shows icon inside the button. Use icon component from the library */
  icon: PropTypes.node,
  /** Callback function which is triggered on chip click */
  onClick: PropTypes.func,
  /** Array of options with label and value */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      /** Will adjust chip styles accordingly to background it is represented on */
      backgroundAppearance: PropTypes.oneOf(Object.values(CHIP_BACKGROUND_APPEARANCES)),
      /** Shows icon inside the button */
      icon: PropTypes.node,
      /** Chip label */
      label: PropTypes.node.isRequired,
      /** Value of the chip */
      value: PropTypes.node.isRequired,
      /** Chip style is disabled */
      isDisabled: PropTypes.bool,
      /** Chip is selected and disabled by default */
      isSelected: PropTypes.bool,
    })
  ).isRequired,
  /** Currently selected value */
  value: PropTypes.node,
  /** Chip style is disabled */
  isDisabled: PropTypes.bool,
  /** Chip is selected and disabled by default */
  isSelected: PropTypes.bool,
};

ChoiceChips.defaultProps = {
  backgroundAppearance: CHIP_BACKGROUND_APPEARANCES.LIGHT,
  dataTestId: '',
  icon: undefined,
  onClick: () => {},
  value: null,
  isDisabled: false,
  isSelected: false,
};

export { ChoiceChips };
