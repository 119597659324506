import React, { useState } from 'react';

import { ContextMenu, MENU_HORIZONTAL_POSITIONS, MENU_VERTICAL_POSITIONS } from 'lib/context-menu';
import { IconArrowDropDown } from 'lib/icons';
import { KEY_CODES } from 'lib/utilities';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { PageHeaderTitle, PageHeaderWrapper } from '../../elements';
import { getRem } from './../../../core';
import { DropdownOption } from './DropdownOption';
import { OutlineKeyboard } from './OutlineKeyboard';

const StyledContextMenu = styled(ContextMenu)`
  left: 0;
  margin-left: ${({ theme }) => theme.size.spacing.large.value};
  margin-top: ${getRem('20px')};
  overflow-y: auto;
  transform: none;
  width: calc(100% - ${getRem('32px')});
`;

const StyledIconArrowDropDown = styled(IconArrowDropDown)`
  align-self: center;
  fill: currentColor;
`;

const StyledHeaderContainer = styled.span`
  color: ${({ theme }) => theme.color.additional.light.value};
  display: inline-flex;
  margin: ${getRem('12px')} ${({ theme }) => theme.size.spacing.large.value};
  margin-left: ${({ theme }) => theme.size.spacing.medium.value};
  overflow-y: auto;
  padding-left: ${({ theme }) => theme.size.spacing.small.value};
  position: relative;

  &:hover {
    cursor: pointer;
  }
`;

const StyledPageHeaderTitle = styled(PageHeaderTitle)`
  padding: 0;
  padding-left: ${({ theme }) => theme.size.spacing.small.value};
`;

const PageHeaderTitleWithDropdown = ({ dataTestId, onSelect, options, selectedOption, ...other }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => setIsOpen(false);
  const handleOpen = () => setIsOpen(true);
  const handleSelect = (option) => onSelect(option, setIsOpen);

  const toggleSetIsOpen = () => setIsOpen(!isOpen);
  const StyledPageHeaderContentWrapper = styled.span.attrs(() => ({
    'data-testid': `${dataTestId}-with-dropdown`,
  }))``;

  const wrappedComponentContent = (
    <OutlineKeyboard
      as={StyledPageHeaderContentWrapper}
      innerProps={{ as: StyledHeaderContainer }}
      onClick={toggleSetIsOpen}
      onKeyPress={toggleSetIsOpen}
      role="button"
      tabIndex={0}
    >
      <StyledPageHeaderTitle>{selectedOption.label}</StyledPageHeaderTitle>
      <StyledIconArrowDropDown />
    </OutlineKeyboard>
  );

  return (
    <PageHeaderWrapper data-testid={dataTestId} {...other}>
      <StyledContextMenu
        customRenderOption={(option, ref, index, handleClick) => {
          const handleKeyPress = (event) => {
            const keyCode = event.keyCode || event.which || 0;
            if (keyCode === KEY_CODES.ENTER || keyCode === KEY_CODES.SPACE) {
              handleClick();
            }
          };
          return (
            <DropdownOption
              id={option.id}
              isFocused={!!(ref && ref.current)}
              isSelected={option.id === selectedOption.id}
              label={option.label}
              onClick={handleClick}
              onKeyPress={handleKeyPress}
              ref={ref}
              tabIndex={0}
            />
          );
        }}
        isOpen={isOpen}
        onClose={handleClose}
        onOpen={handleOpen}
        onSelect={handleSelect}
        options={options}
        positionHorizontal={MENU_HORIZONTAL_POSITIONS.CENTER}
        positionVertical={MENU_VERTICAL_POSITIONS.CENTER}
        wrappedComponentContent={wrappedComponentContent}
      />
    </PageHeaderWrapper>
  );
};

PageHeaderTitleWithDropdown.propTypes = {
  /** Id value used for testing */
  dataTestId: PropTypes.string,
  /** Callback called on option click or keypress, forwards selected option, setIsOpen callback  */
  onSelect: PropTypes.func.isRequired,
  /** List of options to select from */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.node.isRequired,
    }).isRequired
  ).isRequired,
  /** Current selected option */
  selectedOption: PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.node.isRequired,
  }).isRequired,
};

PageHeaderTitleWithDropdown.defaultProps = {
  dataTestId: undefined,
};

export { PageHeaderTitleWithDropdown };
