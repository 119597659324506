import React, { useState } from 'react';
import { Popup } from 'lib/popup';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { NAVIGATION_HEADER_HEIGHT } from '../constants';
import { HeaderButton } from '../elements';

const StyledNavigationHeader = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.color.additional.brand.value};
  box-sizing: border-box;
  display: flex;
  height: ${NAVIGATION_HEADER_HEIGHT};
  min-height: ${NAVIGATION_HEADER_HEIGHT};
  width: 100%;
  z-index: ${({ theme }) => theme.zIndex.globalNavigationHeader.value};
`;

const StyledPopup = styled(Popup)`
  width: 100%;
`;

const NavigationHeader = ({
  closeHeaderPopupOnOutsideClick,
  dataTestId,
  defaultIsOpen,
  headerButtonClassName,
  headerClassName,
  headerLabel,
  isCollapsed,
  logoIcon,
  renderContextMenuContent,
  ...other
}) => {
  const [isOpen, setIsOpen] = useState(defaultIsOpen);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const headerButtonComponentContent = (
    <HeaderButton
      className={headerButtonClassName}
      data-testid={dataTestId ? `${dataTestId}-header-button` : undefined}
      icon={logoIcon}
      isCollapsed={isCollapsed}
      isOpen={isOpen}
      onClose={handleClose}
      onOpen={handleOpen}
      text={headerLabel}
    />
  );

  return (
    <StyledNavigationHeader
      className={headerClassName}
      data-testid={dataTestId ? `${dataTestId}-header` : undefined}
      {...other}
    >
      <StyledPopup
        closePopupOnOutsideClick={closeHeaderPopupOnOutsideClick}
        isOpen={isOpen}
        onClose={handleClose}
        onOpen={handleOpen}
        wrappedComponentContent={headerButtonComponentContent}
      >
        {renderContextMenuContent()}
      </StyledPopup>
    </StyledNavigationHeader>
  );
};

NavigationHeader.propTypes = {
  /** If true, closes the header context menu content on outside click */
  closeHeaderPopupOnOutsideClick: PropTypes.bool,
  /** Id value used for testing */
  dataTestId: PropTypes.string,
  /** If true, navigation renders with header popup opened */
  defaultIsOpen: PropTypes.bool,
  /** Adds additional class to header button */
  headerButtonClassName: PropTypes.string,
  /** Adds additional class to navigation header */
  headerClassName: PropTypes.string,
  /** Shows text inside the navigation header */
  headerLabel: PropTypes.node.isRequired,
  /** If true, component is collapsed */
  isCollapsed: PropTypes.bool,
  /** Shows logo icon inside the navigation header. Use icon component from the library */
  logoIcon: PropTypes.node.isRequired,
  /** Function for context menu content rendering */
  renderContextMenuContent: PropTypes.func.isRequired,
};

NavigationHeader.defaultProps = {
  closeHeaderPopupOnOutsideClick: true,
  dataTestId: undefined,
  defaultIsOpen: false,
  headerButtonClassName: '',
  headerClassName: '',
  isCollapsed: false,
};

export { NavigationHeader };
