import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { TextAreaContentLabel, TextAreaField, TextAreaLabel, TextAreaWrapper } from '../elements';
import { getRem, typographyBaseStyle, visuallyHidden } from './../../core';
import {
  ie11ReadOnlyClickHandler,
  InputCharacterCount,
  InputHelperText,
  InputErrorText,
  INPUT_ERROR_TYPES,
  INPUT_SIZES,
} from './../../input';
import { useEventListener, useOnError, useShareForwardedRef } from './../../utilities';

const textAreaFieldPaddingWithNoLabel = {
  [INPUT_SIZES.LARGE]: getRem('7px'),
  [INPUT_SIZES.SMALL]: getRem('5px'),
  [INPUT_SIZES.STANDARD]: getRem('7px'),
};

const textAreaFieldPaddingTop = {
  [INPUT_SIZES.LARGE]: getRem('8px'),
  [INPUT_SIZES.SMALL]: getRem('5px'),
  [INPUT_SIZES.STANDARD]: getRem('6px'),
};

const textAreaFieldPaddingBottom = {
  [INPUT_SIZES.LARGE]: getRem('6px'),
  [INPUT_SIZES.SMALL]: getRem('3px'),
  [INPUT_SIZES.STANDARD]: getRem('6px'),
};

const getMaxTextAreaFieldHeight = (hideLabel, maxRows, size) => {
  const singleRowHeight = size === INPUT_SIZES.LARGE ? 24 : 20;
  const convertMaxRowsToMaxHeight = `${singleRowHeight * maxRows}px`;
  return getRem(convertMaxRowsToMaxHeight);
};

const StyledTextAreaContentLabel = styled(TextAreaContentLabel)`
  padding-bottom: ${({ hideLabel, size }) =>
    hideLabel ? textAreaFieldPaddingWithNoLabel[size] : textAreaFieldPaddingBottom[size]};
  padding-top: ${({ hideLabel, size }) =>
    hideLabel ? textAreaFieldPaddingWithNoLabel[size] : textAreaFieldPaddingTop[size]};
  ${({ hideLabel, isRequired, theme }) =>
    hideLabel &&
    isRequired &&
    css`
      &::after {
        ${typographyBaseStyle(theme)};
        color: ${({ hasError, isDisabled }) =>
          (hasError && theme.color.system.negative[500].value) ||
          (isDisabled && theme.color.text.light.disabled.value) ||
          theme.color.text.light.secondary.value};
        content: '*';
        font-size: ${getRem(17)};
        line-height: 0;
        margin-left: ${getRem(2)};
        position: absolute;
        right: ${theme.size.spacing.small.value};
        top: ${getRem(10)};
      }
    `}
  ${({ hasError, isFocused, isReadOnly, theme }) =>
    !hasError &&
    isFocused &&
    !isReadOnly &&
    css`
      &::after {
        color: ${theme.color.primary[500].value};
      }
    `}
`;

const StyledTextAreaField = styled(TextAreaField)`
  max-height: ${({ hideLabel, maxRows, size }) => maxRows && getMaxTextAreaFieldHeight(hideLabel, maxRows, size)};
  overflow: hidden;
  padding-bottom: 0;
`;

const StyledTextAreaLabel = styled(TextAreaLabel)`
  flex-shrink: 0;
  height: ${({ size }) => (size === INPUT_SIZES.SMALL ? getRem('13px') : getRem('14px'))};
  padding-top: 0;
  ${({ hideLabel }) => hideLabel && visuallyHidden};
`;

const StyledInputErrorText = styled(InputErrorText)`
  margin: ${getRem('3px')} ${getRem('6px')} 0 0;
  padding: 0;
`;

const StyledTextAreaFieldWrapper = styled.div`
  display: flex;
  ${StyledTextAreaField} {
    flex-grow: 1;
  }
`;

const StyledHelperTextContainer = styled.div`
  display: flex;
`;

const MultilineInput = React.forwardRef(
  (
    {
      dataTestId,
      defaultIsFocused,
      enableCustomValidation,
      errorMessage,
      hasCharacterCount,
      hasError,
      helperText,
      hideLabel,
      id,
      isDisabled,
      isReadOnly,
      isRequired,
      label,
      maxLength,
      maxRows,
      minLength,
      name,
      onBlur,
      onChange,
      onClick,
      onError,
      onFocus,
      placeholder,
      size,
      value,
      ...other
    },
    ref
  ) => {
    const multilineInputRef = useShareForwardedRef(ref);

    const [isTouched, setIsTouched] = useState(false);
    const [isFocused, setIsFocused] = useState(defaultIsFocused);
    const [isHighlighted, setIsHighlighted] = useState(false);

    const handleBlur = (event) => {
      setIsFocused(false);
      setIsTouched(true);
      onBlur(event, { setIsFocused, setIsTouched });
    };

    const handleHeightChange = (event) => {
      /* eslint-disable no-param-reassign */
      event.target.style.height = 'auto';
      event.target.style.height = `${event.target.scrollHeight}px`;
      /* eslint-enable */
    };

    useEventListener('input', handleHeightChange, multilineInputRef.current);

    const handleClick = (event) => {
      if (isReadOnly) {
        ie11ReadOnlyClickHandler(event);
      }
      onClick(event);
    };

    const handleFocus = (event) => {
      setIsFocused(true);
      onFocus(event, { setIsFocused });
    };
    const handleMouseEnter = () => setIsHighlighted(true);
    const handleMouseLeave = () => setIsHighlighted(false);

    const isEmpty = !value || !value.length;
    const isRequiredError = isRequired && isEmpty && isTouched;
    const minLengthError = !isEmpty && value.length < minLength && !isFocused;

    const errors = [
      isRequiredError && INPUT_ERROR_TYPES.REQUIRED,
      minLengthError && INPUT_ERROR_TYPES.MIN_LENGTH,
    ].filter(Boolean);

    const updatedHasError = enableCustomValidation ? hasError : errors.length > 0;

    const isInvalid = !isDisabled && !isReadOnly && updatedHasError;

    useOnError({
      errors,
      onError,
    });

    const showHelperText = !isInvalid && !!helperText;
    const showErrorMessage = !!isInvalid && !!errorMessage;

    const multilineInputLabelId = `multiline-input-${id}-label`;
    const multilineInputHelperTextId = `multiline-input-${id}-help-text`;
    const multilineInputErrorTextId = `multiline-input-${id}-error-text`;

    const multilineInputAriaDescribedBy = classNames({
      [`multiline-input-${id}-help-text`]: showHelperText,
      [`multiline-input-${id}-error-text`]: showErrorMessage,
    });

    return (
      <TextAreaWrapper data-testid={dataTestId ? `${dataTestId}-wrapper` : undefined}>
        <StyledTextAreaContentLabel
          data-testid={dataTestId ? `${dataTestId}-content` : undefined}
          htmlFor={id}
          hasError={isInvalid}
          hideLabel={hideLabel}
          isDisabled={isDisabled}
          isFocused={isFocused}
          isHighlighted={isHighlighted}
          isReadOnly={isReadOnly}
          isRequired={isRequired}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          size={size}
        >
          <StyledTextAreaLabel
            data-testid={dataTestId ? `${dataTestId}-label` : undefined}
            hasError={isInvalid}
            hideLabel={hideLabel}
            id={multilineInputLabelId}
            isDisabled={isDisabled}
            isFocused={isFocused}
            isReadOnly={isReadOnly}
            isRequired={isRequired}
            label={label}
            size={size}
          />
          <StyledTextAreaFieldWrapper>
            <StyledTextAreaField
              aria-labelledby={multilineInputLabelId}
              aria-describedby={multilineInputAriaDescribedBy}
              data-testid={dataTestId}
              disabled={isDisabled}
              hideLabel={hideLabel}
              id={id}
              isReadOnly={isReadOnly}
              maxLength={maxLength}
              minLength={minLength}
              name={name}
              onBlur={handleBlur}
              onChange={onChange}
              onClick={handleClick}
              onFocus={handleFocus}
              placeholder={placeholder}
              readOnly={isReadOnly}
              ref={multilineInputRef}
              required={isRequired}
              rows={1}
              maxRows={maxRows}
              size={size}
              value={value}
              {...other}
            />
            {hideLabel && showErrorMessage && <StyledInputErrorText id={multilineInputErrorTextId} text="" />}
          </StyledTextAreaFieldWrapper>
        </StyledTextAreaContentLabel>
        <StyledHelperTextContainer>
          {showHelperText && <InputHelperText id={multilineInputHelperTextId} text={helperText} />}
          {!hideLabel && showErrorMessage && <InputErrorText id={multilineInputErrorTextId} text={errorMessage} />}
          {!hideLabel && hasCharacterCount && <InputCharacterCount maxLength={maxLength} value={value} />}
        </StyledHelperTextContainer>
      </TextAreaWrapper>
    );
  }
);

MultilineInput.propTypes = {
  /** Id value used for testing */
  dataTestId: PropTypes.string,
  /** If true, visually applies focused input styles */
  defaultIsFocused: PropTypes.bool,
  /** If true, custom validation is being enabled instead of built in component validation */
  enableCustomValidation: PropTypes.bool,
  /** Message to be displayed when input is in error state */
  errorMessage: PropTypes.node,
  /** When true, displays character count for multiline input */
  hasCharacterCount: PropTypes.bool,
  /** When true, input is in error state */
  hasError: PropTypes.bool,
  /** Text to be displayed as a helper text near the input field */
  helperText: PropTypes.node,
  /** If true, visually hides label of the multiline input field */
  hideLabel: PropTypes.bool,
  /** Identifier of the multiline input component */
  id: PropTypes.string.isRequired,
  /** If true, multiline input is disabled and value of it cannot be edited */
  isDisabled: PropTypes.bool,
  /** If true, input is in read only state, value cannot be edited */
  isReadOnly: PropTypes.bool,
  /** If true, multiline input has to be filled or error state would be displayed */
  isRequired: PropTypes.bool,
  /** Label of the multiline input field */
  label: PropTypes.node.isRequired,
  /** Maximum amount of characters multiline input can have */
  maxLength: PropTypes.number,
  /** Maximum number of rows input can have */
  maxRows: PropTypes.number,
  /** Minimum amount of characters to be entered so the multiline input would not be in error state */
  minLength: PropTypes.string,
  /** Name of the multiline input */
  name: PropTypes.string.isRequired,
  /** Callback to be called when multiline input looses focus */
  onBlur: PropTypes.func,
  /** Callback to be called when multiline input's value is being changed by user interaction */
  onChange: PropTypes.func.isRequired,
  /** Callback to be called when multiline input is clicked */
  onClick: PropTypes.func,
  /** Callback to be called when multiline input's value validation has failed */
  onError: PropTypes.func,
  /** Callback to be called when multiline input gains focus */
  onFocus: PropTypes.func,
  /** Text to be displayed when multiline input is empty */
  placeholder: PropTypes.node,
  /** Set the size of the multiline input */
  size: PropTypes.oneOf(Object.values(INPUT_SIZES)),
  /** Current value of the multiline input */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

MultilineInput.defaultProps = {
  dataTestId: undefined,
  defaultIsFocused: false,
  enableCustomValidation: false,
  errorMessage: '',
  hasCharacterCount: false,
  hasError: false,
  helperText: '',
  hideLabel: false,
  isDisabled: false,
  isReadOnly: false,
  isRequired: false,
  maxLength: 100,
  maxRows: undefined,
  minLength: 0,
  onBlur: () => {},
  onClick: () => {},
  onError: () => {},
  onFocus: () => {},
  placeholder: '',
  size: INPUT_SIZES.STANDARD,
};

export { MultilineInput };
