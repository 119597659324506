import { focusOutlinesStyles, focusOutlineColors, getHexToRgb } from 'lib/core';
import { css } from 'styled-components';

const stepIconActiveStateStyles = {
  active: css`
    background-color: ${({ theme }) => theme.color.primary['700'].value};
  `,
  default: css`
    background-color: ${({ theme }) => theme.color.primary['500'].value};
  `,
  focus: css`
    background-color: ${({ theme }) => theme.color.primary['600'].value};
    ${focusOutlinesStyles.primary};
  `,
  hover: css`
    background-color: ${({ theme }) => theme.color.primary['600'].value};
  `,
};

export const stepIconCompleteStateStyles = {
  active: css`
    background-color: ${({ theme }) => theme.color.primary['700'].value};
    fill: ${({ theme }) => theme.color.additional.light.value};
  `,
  default: css`
    fill: ${({ theme }) => theme.color.primary['500'].value};
  `,
  focus: css`
    background-color: ${({ theme }) => theme.color.primary['600'].value};
    fill: ${({ theme }) => theme.color.additional.light.value};
    ${focusOutlinesStyles.primary};
  `,
  hover: css`
    background-color: ${({ theme }) => theme.color.primary['600'].value};
    fill: ${({ theme }) => theme.color.additional.light.value};
  `,
};

const stepIconStateStyles = {
  default: css`
    background-color: ${({ theme }) => `rgba(${getHexToRgb(theme.color.additional.dark.value)}, 0.4)`};
  `,
};

export const stepIconErrorStateStyles = {
  default: css`
    fill: ${({ theme }) => theme.color.system.negative[500].value};
  `,
  focus: css`
    box-shadow: 0 0 0 1px ${({ theme }) => theme.color.system.negative[500].value},
      0 0 0 5px ${({ theme }) => focusOutlineColors.getSystemNegative(theme)};
  `,
};

export const getStepIconStateStyles = (isActive) => {
  return isActive ? stepIconActiveStateStyles : stepIconStateStyles;
};
