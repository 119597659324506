import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { STEPPER_POSITIONS } from '../constants';

const StyledStep = styled.div`
  align-items: center;
  display: flex;
  flex-direction: ${({ position }) => (position === STEPPER_POSITIONS.BOTTOM ? 'column' : 'row')};
  ${({ isActive, isComplete }) =>
    isActive || isComplete
      ? css`
          cursor: pointer;
        `
      : css`
          cursor: not-allowed;
        `};
  ${({ position }) =>
    position === STEPPER_POSITIONS.BOTTOM &&
    css`
      text-align: center;
    `};
`;

export const Step = ({ children, isActive, isComplete, onClick, position, ...other }) => {
  const handleStepClick = () => {
    if (isActive || isComplete) {
      onClick();
    }
  };

  const attributes = isActive ? { 'aria-current': 'step' } : {};

  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { isActive: isActive, isComplete: isComplete, position: position });
    }
    return child;
  });

  return (
    <StyledStep
      position={position}
      isActive={isActive}
      isComplete={isComplete}
      onClick={handleStepClick}
      {...attributes}
      {...other}
    >
      {childrenWithProps}
    </StyledStep>
  );
};

Step.propTypes = {
  /** Any content inserted between component tags */
  children: PropTypes.node,
  /** Position of the labels. Is passed to child components. */
  position: PropTypes.string,
  /** Callback that is called on click */
  onClick: PropTypes.func,
  /** Sets the step as active. Is passed to child components. */
  isActive: PropTypes.bool,
  /** Mark the step as completed. Is passed to child components. */
  isComplete: PropTypes.bool,
};

Step.defaultProps = {
  children: undefined,
  onClick: () => {},
  position: STEPPER_POSITIONS.RIGHT,
  isActive: false,
  isComplete: false,
};
