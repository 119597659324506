import { css } from 'styled-components';

import { StyledButtonContentIcon } from '../blocks';
import { StyledButtonLoader } from '../elements';

export const getButtonContentElementsColor = (color) => css`
  color: ${color};
  ${StyledButtonContentIcon} {
    fill: ${color};
  }
  ${StyledButtonLoader} {
    stroke: ${color};
  }
`;
