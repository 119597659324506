import React from 'react';

import { IconStatusInformation } from 'lib/icons';
import { removeObjectProperties } from 'lib/utilities';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { StatusIcon } from '../elements';
import { ElementStateWrapper } from './../../core';
import { BaseIndicator } from './BaseIndicator';
import { neutralStateStyles } from './StateStyles';

const StyledStatusIcon = styled(StatusIcon)`
  fill: ${({ theme }) => theme.color.system.neutral[500].value};
`;

const StyledBaseIndicator = styled(BaseIndicator)`
  border-color: ${({ theme }) => theme.color.system.neutral[500].value};
  background-color: ${({ theme }) => theme.color.system.neutral[100].value};
`;

const InformationIndicator = React.forwardRef(({ enableStateStyles, isDisabled, showIcon, ...other }, ref) => {
  const renderIcon = () =>
    showIcon && (
      <StyledStatusIcon>
        <IconStatusInformation />
      </StyledStatusIcon>
    );

  const propsWithoutExcludedOptions = removeObjectProperties(other, 'renderBeforeContent');

  if (enableStateStyles) {
    return (
      <ElementStateWrapper isDisabled={isDisabled} stateStyles={neutralStateStyles}>
        <StyledBaseIndicator
          enableStateStyles={enableStateStyles}
          renderBeforeContent={renderIcon}
          {...propsWithoutExcludedOptions}
        />
      </ElementStateWrapper>
    );
  } else {
    return <StyledBaseIndicator renderBeforeContent={renderIcon} {...propsWithoutExcludedOptions} />;
  }
});

InformationIndicator.propTypes = {
  /** Informs screen reader users what actions they should take */
  ariaLabel: PropTypes.node,
  /** Enables hover, focus, active, disabled states on status indicator */
  enableStateStyles: PropTypes.bool,
  /** Changes status indicator to disabled state */
  isDisabled: PropTypes.bool,
  /** If true, status indicator takes full width of container */
  isFullWidth: PropTypes.bool,
  /** Status indicator label */
  label: PropTypes.node.isRequired,
  /** Shows icon inside the indicator */
  showIcon: PropTypes.bool,
};

InformationIndicator.defaultProps = {
  ariaLabel: 'Status Indicator',
  isFullWidth: undefined,
  showIcon: false,
};

export { InformationIndicator };
