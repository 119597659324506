import React, { useState, useRef } from 'react';
import classNames from 'classnames';
import { KEY_CODES, preventDefaultBehavior, useKeyDownEventListener } from 'lib/utilities';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { Panel } from '../elements';
import { getHexToRgb } from './../../core';

const StyledAccordion = styled.div.withConfig({
  shouldForwardProp: (prop) => !['showDivider'].includes(prop),
})`
  ${({ showDivider, theme }) =>
    showDivider &&
    css`
      ${Panel} {
        border-bottom: 1px solid rgba(${getHexToRgb(theme.color.additional.dark.value)}, 0.3);
        &:first-child {
          border-top: 1px solid rgba(${getHexToRgb(theme.color.additional.dark.value)}, 0.3);
        }
      }
    `}
`;

const Accordion = ({
  className,
  headerClassName,
  items,
  onPanelToggle,
  openPanels,
  panelClassName,
  panelContentClassName,
  panelContentComponent,
  panelHeaderComponent,
  renderAfterTitle,
  showDivider,
  ...other
}) => {
  const itemsRef = useRef([]);
  const [activeItemIndex, setActiveItemIndex] = useState(0);

  const hasItems = items.length > 0;
  const lastItemIndex = hasItems ? items.length - 1 : 0;
  const isActiveItemIndexFirst = activeItemIndex === 0;
  const isActiveItemIndexLast = activeItemIndex === lastItemIndex;

  useKeyDownEventListener(
    KEY_CODES.ARROW_UP,
    (event) => {
      preventDefaultBehavior(event);

      if (!hasItems) {
        return;
      }

      const previousActiveItemIndex = isActiveItemIndexFirst ? lastItemIndex : activeItemIndex - 1;

      itemsRef.current[previousActiveItemIndex].focus();
      setActiveItemIndex(previousActiveItemIndex);
    },
    itemsRef.current[activeItemIndex]
  );

  useKeyDownEventListener(
    KEY_CODES.ARROW_DOWN,
    (event) => {
      preventDefaultBehavior(event);

      if (!hasItems) {
        return;
      }

      const nextActiveItemIndex = (activeItemIndex + 1) % items.length;

      itemsRef.current[nextActiveItemIndex].focus();
      setActiveItemIndex(nextActiveItemIndex);
    },
    itemsRef.current[activeItemIndex]
  );

  useKeyDownEventListener(
    KEY_CODES.END,
    (event) => {
      preventDefaultBehavior(event);

      if (hasItems && !isActiveItemIndexLast) {
        itemsRef.current[lastItemIndex].focus();
        setActiveItemIndex(lastItemIndex);
      }
    },
    itemsRef.current[activeItemIndex]
  );

  useKeyDownEventListener(
    KEY_CODES.HOME,
    (event) => {
      preventDefaultBehavior(event);

      if (hasItems && !isActiveItemIndexFirst) {
        itemsRef.current[0].focus();
        setActiveItemIndex(0);
      }
    },
    itemsRef.current[activeItemIndex]
  );

  const PanelContent = panelContentComponent;
  const PanelHeader = panelHeaderComponent;

  return (
    <StyledAccordion className={className} showDivider={showDivider} {...other}>
      {items.map((item, index) => {
        const panelRef = (element) => {
          itemsRef.current[index] = element;
        };

        const isOpen = openPanels[item.id];
        const panelContentId = `panel-content-${item.id}`;

        const handleClick = () => {
          setActiveItemIndex(index);
          onPanelToggle(item.id);
        };

        return (
          <Panel className={classNames(panelClassName, item.panelClassName)} key={item.id}>
            <PanelHeader
              className={headerClassName}
              id={item.id}
              isOpen={isOpen}
              onClick={handleClick}
              onFocus={() => setActiveItemIndex(index)}
              panelContentId={panelContentId}
              ref={panelRef}
              renderAfterTitle={renderAfterTitle || item.renderAfterTitle}
              title={item.title}
              dataTestId={panelContentId}
            />

            {isOpen && (
              <PanelContent
                data-testid={item.id}
                aria-labelledby={item.id}
                className={panelContentClassName}
                id={panelContentId}
                role="region"
              >
                {item.content}
              </PanelContent>
            )}
          </Panel>
        );
      })}
    </StyledAccordion>
  );
};

Accordion.propTypes = {
  /** Adds additional class to the component */
  className: PropTypes.string,
  /** Adds additional class to PanelHeader component */
  headerClassName: PropTypes.string,
  /** Accordion data which gets rendered */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      /** Panel content */
      content: PropTypes.node.isRequired,
      /** Unique identifier of item */
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      /** Custom classname of item panel */
      panelClassName: PropTypes.string,
      /** Renders custom content after title */
      renderAfterTitle: PropTypes.func,
      /** Panel title */
      title: PropTypes.node.isRequired,
    })
  ).isRequired,
  /** Callback which is called when panel opens or closes */
  onPanelToggle: PropTypes.func.isRequired,
  /** Object whose keys are id's of panels. If value of a key is true then that panel is open */
  openPanels: PropTypes.shape({}),
  /** Adds additional class to the panel container element */
  panelClassName: PropTypes.string,
  /** Adds additional class to PanelContent component */
  panelContentClassName: PropTypes.string,
  /** Pass PanelContent component */
  panelContentComponent: PropTypes.elementType.isRequired,
  /** Pass PanelHeader component */
  panelHeaderComponent: PropTypes.elementType.isRequired,
  /** Renders custom content after title */
  renderAfterTitle: PropTypes.func,
  /** If true, dividers are visible */
  showDivider: PropTypes.bool,
};

Accordion.defaultProps = {
  className: '',
  headerClassName: '',
  openPanels: {},
  panelClassName: '',
  panelContentClassName: '',
  renderAfterTitle: undefined,
  showDivider: false,
};

export { Accordion };
