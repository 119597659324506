import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { getRem } from '../../core';

import { RowSummary } from '../elements';
import { PageSize } from './PageSize';
import { PageSummary } from './PageSummary';

const StyledTablePagination = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.color.additional.light.value};
  ${({ hideBorder }) =>
    !hideBorder &&
    css`
      border: 1px solid ${({ theme }) => theme.color.gray[200].value};
      border-radius: 0 0
        ${({ theme }) => `${theme.size.borderRadius.large.value}
            ${theme.size.borderRadius.large.value}`};
      border-top: none;
    `}
  display: flex;
  height: ${getRem('36px')};
  justify-content: flex-end;
  padding: ${getRem('6px')} ${({ theme }) => theme.size.spacing.medium.value};
`;

const TablePagination = ({
  ariaLabel,
  buttonLabels,
  dataTestId,
  defaultPaginationPageSize,
  gridApi,
  hideBorder,
  onTabbingIntoTable,
  pageSizeDropdownId,
  pageSummaryLabelId,
  pageSummaryText,
  rowsPerPageLabel,
  rowsPerPageOptions,
  rowsSummaryText,
  showGoToFirstLastPage,
  ...other
}) => {
  const [focusPreviousButton, setFocusPreviousButton] = useState(false);

  const lastPageRef = useRef();
  const nextPageRef = useRef();
  const pageSizeDropdownRef = useRef();
  const previousPageRef = useRef();

  const defaultPaginationDropdownOption =
    rowsPerPageOptions.filter((option) => option === defaultPaginationPageSize)[0] || rowsPerPageOptions[0];

  const [selectedPageSize, setSelectedPageSize] = useState(defaultPaginationDropdownOption);

  const currentPage = gridApi.paginationGetCurrentPage() + 1;
  const totalPages = gridApi.paginationGetTotalPages();

  const handlePageSizeChange = (selectedOption) => {
    setSelectedPageSize(selectedOption);
    gridApi.paginationSetPageSize(selectedOption.value);
  };

  const handleGoToFirstPage = () => {
    gridApi.paginationGoToFirstPage();
    pageSizeDropdownRef.current.focus();
  };

  const handleGoToNextPage = () => {
    const isNextPageLast = currentPage + 1 === totalPages;
    if (isNextPageLast) {
      setFocusPreviousButton(true);
    }

    gridApi.paginationGoToNextPage();
  };

  const handleGoToPreviousPage = () => {
    const isPreviousPageFirst = currentPage === 2;

    if (isPreviousPageFirst) {
      pageSizeDropdownRef.current.focus();
    }
    gridApi.paginationGoToPreviousPage();
  };

  const handleGoToLastPage = () => {
    gridApi.paginationGoToLastPage();
    setFocusPreviousButton(true);
  };

  useEffect(() => {
    if (focusPreviousButton) {
      previousPageRef.current.focus();
      setFocusPreviousButton(false);
    }
  }, [focusPreviousButton]);

  return (
    <StyledTablePagination data-testid={dataTestId} hideBorder={hideBorder} {...other}>
      <PageSize
        dataTestId={dataTestId}
        id={pageSizeDropdownId}
        onChange={handlePageSizeChange}
        onTabbingIntoTable={onTabbingIntoTable}
        pageSizeDropdownRef={pageSizeDropdownRef}
        rowsPerPageLabel={rowsPerPageLabel}
        rowsPerPageOptions={rowsPerPageOptions}
        value={selectedPageSize}
      />
      <RowSummary text={rowsSummaryText} />
      <PageSummary
        ariaLabel={ariaLabel}
        buttonLabels={buttonLabels}
        currentPage={currentPage}
        id={pageSummaryLabelId}
        lastPageRef={lastPageRef}
        nextPageRef={nextPageRef}
        onGoToFirstPage={handleGoToFirstPage}
        onGoToLastPage={handleGoToLastPage}
        onGoToNextPage={handleGoToNextPage}
        onGoToPreviousPage={handleGoToPreviousPage}
        previousPageRef={previousPageRef}
        showGoToFirstLastPage={showGoToFirstLastPage}
        text={pageSummaryText}
        totalPages={totalPages}
      />
    </StyledTablePagination>
  );
};

TablePagination.propTypes = {
  /** Informs screen reader users what actions they should take */
  ariaLabel: PropTypes.node.isRequired,
  /** Set of pagination button labels */
  buttonLabels: PropTypes.shape({
    /** Label for go to first page button */
    goToFirst: PropTypes.node,
    /** Label for go to last page button */
    goToLast: PropTypes.node,
    /** Label for go to next page button */
    goToNext: PropTypes.node,
    /** Label for go to previous page button */
    goToPrevious: PropTypes.node,
  }),
  /** Id value used for testing */
  dataTestId: PropTypes.string,
  /** Sets default value of how many rows to load per page */
  defaultPaginationPageSize: PropTypes.shape({
    /** Label of the dropdown option */
    label: PropTypes.node.isRequired,
    /** Value of the dropdown option */
    value: PropTypes.number.isRequired,
  }).isRequired,
  /** Used to control pagination in ag-grid */
  gridApi: PropTypes.shape({
    /** Returns the 0-based index of the page which is showing */
    paginationGetCurrentPage: PropTypes.func.isRequired,
    /** Returns the total number of pages */
    paginationGetTotalPages: PropTypes.func.isRequired,
    /** Goes to the first page of a table */
    paginationGoToFirstPage: PropTypes.func.isRequired,
    /** Goes to the last page of a table */
    paginationGoToLastPage: PropTypes.func.isRequired,
    /** Goes to the next page of a table */
    paginationGoToNextPage: PropTypes.func.isRequired,
    /** Goes to the previous page of a table */
    paginationGoToPreviousPage: PropTypes.func.isRequired,
    /** Sets how many rows are shown in a table */
    paginationSetPageSize: PropTypes.func.isRequired,
  }).isRequired,
  /** Callback function which is called on tabbing into table */
  onTabbingIntoTable: PropTypes.func,
  /** Id of the page size selection dropdown */
  pageSizeDropdownId: PropTypes.string.isRequired,
  /** Id of pagination page summary label */
  pageSummaryLabelId: PropTypes.string.isRequired,
  /** Sets page summary text of pagination bar */
  pageSummaryText: PropTypes.node.isRequired,
  /** Sets label for rows per page dropdown */
  rowsPerPageLabel: PropTypes.node,
  /** List of pagination page size available options */
  rowsPerPageOptions: PropTypes.arrayOf(
    PropTypes.shape({
      /** Label of the dropdown option */
      label: PropTypes.node.isRequired,
      /** Value of the dropdown option */
      value: PropTypes.number.isRequired,
    })
  ).isRequired,
  /** Sets rows summary text of pagination bar */
  rowsSummaryText: PropTypes.node.isRequired,
  /** If true, go to first/last page buttons are shown */
  showGoToFirstLastPage: PropTypes.bool.isRequired,
  /** If true, hides the outlining border from the table */
  hideBorder: PropTypes.bool,
};

TablePagination.defaultProps = {
  buttonLabels: {
    goToFirst: 'Go to first',
    goToLast: 'Go to last',
    goToNext: 'Go to next',
    goToPrevious: 'Go to previous',
  },
  dataTestId: undefined,
  onTabbingIntoTable: undefined,
  rowsPerPageLabel: '',
};

export { TablePagination };
