import React from 'react';
import { components } from 'react-select';
import styled from 'styled-components';

import { getRem } from '../../core';

const StyledMenu = styled.div`
  background-color: ${({ theme }) => theme.color.additional.light.value};
  border-radius: ${({ theme }) => theme.size.borderRadius.large.value};
  bottom: ${({ menuPlacement }) => menuPlacement === 'top' && '100%'};
  box-shadow: ${({ theme }) => theme.elevation[4].value};
  box-sizing: border-box;
  left: 0;
  margin-top: ${getRem(5)};
  position: absolute;
  right: 0;
  top: ${({ menuPlacement }) => menuPlacement === 'bottom' && '100%'};
  width: 100%;
  z-index: ${({ theme }) => theme.zIndex.dropdownMenu.value};
`;

/* eslint-disable react/prop-types */
export const MenuComponent = (props) => {
  const { dataTestId, placement, theme } = props;
  /* eslint-enable */
  return (
    <StyledMenu data-testid={dataTestId ? `${dataTestId}-menu` : undefined} menuPlacement={placement} theme={theme}>
      <components.Menu {...props} />
    </StyledMenu>
  );
};
