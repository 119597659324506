import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { typographyButton, visuallyHidden } from '../../core';

const StyledLabel = styled.span`
  ${({ theme }) => typographyButton(theme)}
  color: inherit;
  line-height: 1;
  margin-left: ${({ theme }) => theme.size.spacing.medium.value};
  ${({ hideLabel }) => hideLabel && visuallyHidden}
`;

export const StyledLoaderLabel = ({ hideLabel, label, ...other }) => {
  return label ? (
    <StyledLabel hideLabel={hideLabel} {...other}>
      {label}
    </StyledLabel>
  ) : null;
};

StyledLoaderLabel.propTypes = {
  /** Visually hides loader text */
  hideLabel: PropTypes.bool,
  /** Adds message on the right of the spinner */
  label: PropTypes.node.isRequired,
};

StyledLoaderLabel.defaultProps = {
  hideLabel: false,
};
