import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { DialogCloseButton, DialogTitle } from '../elements';
import { getHexToRgb, visuallyHidden } from './../../core';

const StyledDialogHeader = styled.div`
  border-bottom: 1px solid rgba(${({ theme }) => getHexToRgb(theme.color.additional.dark.value)}, 0.3);
  box-sizing: border-box;
  display: flex;
  flex-shrink: 0;
  padding: ${({ theme }) =>
    `${theme.size.spacing.medium.value} ${theme.size.spacing.medium.value} ` +
    `${theme.size.spacing.medium.value} ${theme.size.spacing.large.value}`};
  ${({ hidden }) => hidden && visuallyHidden}
  ${({ withoutCloseButton }) =>
    withoutCloseButton &&
    css`
      padding-right: ${({ theme }) => theme.size.spacing.large.value};
    `}
`;

const DialogHeader = ({
  closeLabel,
  customTag,
  customTitleTag,
  dataTestId,
  hideCloseButton,
  id,
  onClose,
  title,
  titleId,
  ...other
}) => (
  <StyledDialogHeader
    as={customTag}
    data-testid={dataTestId ? `${dataTestId}-dialog-header` : undefined}
    withoutCloseButton={hideCloseButton}
    {...other}
  >
    <DialogTitle as={customTitleTag} data-testid={dataTestId ? `${dataTestId}-dialog-title` : undefined} id={titleId}>
      {title}
    </DialogTitle>
    {!hideCloseButton && (
      <DialogCloseButton
        data-testid={dataTestId ? `${dataTestId}-dialog-header-close-button` : undefined}
        onClick={onClose}
        text={closeLabel}
      />
    )}
  </StyledDialogHeader>
);

DialogHeader.propTypes = {
  /** Label for close icon button */
  closeLabel: PropTypes.node,
  /** Ability to supply a different element instead of the default one */
  customTag: PropTypes.elementType,
  /** Ability to supply a different element instead of the default one */
  customTitleTag: PropTypes.elementType,
  /** Id value used for testing */
  dataTestId: PropTypes.string,
  /** If true, hides close button */
  hideCloseButton: PropTypes.bool,
  /** Id of the component */
  id: PropTypes.string.isRequired,
  /** Callback function for close button */
  onClose: PropTypes.func.isRequired,
  /** Displays provided value with predefined style */
  title: PropTypes.node.isRequired,
  /** Id for title element */
  titleId: PropTypes.string.isRequired,
};

DialogHeader.defaultProps = {
  closeLabel: 'Close',
  customTag: undefined,
  customTitleTag: undefined,
  dataTestId: undefined,
  hideCloseButton: false,
};

export { DialogHeader };
