import React from 'react';
import { getRem, getHexToRgb, transitionAnimation } from 'lib/core';
import { Divider } from 'lib/divider';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { CONNECTOR_VARIANTS, STEPPER_POSITIONS } from '../constants';

const StyledHorizontalDivider = styled(Divider)`
  margin: 0 ${({ theme }) => theme.size.spacing.medium.value};
  ${({ position }) =>
    position === STEPPER_POSITIONS.BOTTOM &&
    css`
      margin-top: ${getRem('12px')};
    `}
`;

const StyledVerticalDivider = styled.div`
  background-color: rgba(${({ theme }) => getHexToRgb(theme.color.additional.dark.value)}, 0.3);
  border: none;
  box-sizing: border-box;
  flex: 1;
  height: 100%;
  margin-left: ${getRem(10)};
  width: 4px;
  ${({ isActive, theme }) =>
    isActive &&
    css`
      background-color: ${theme.color.primary['500'].value};
      ${transitionAnimation('background-color')};
    `}
`;

export const StepConnector = ({ isActive, variant, ...other }) => {
  const isVertical = variant === CONNECTOR_VARIANTS.VERTICAL;
  return isVertical ? <StyledVerticalDivider isActive={isActive} {...other} /> : <StyledHorizontalDivider {...other} />;
};

StepConnector.propTypes = {
  /** Position of the labels */
  position: PropTypes.oneOf(Object.values(STEPPER_POSITIONS)),
  /** Changes the orientation of the divider */
  variant: PropTypes.oneOf(Object.values(CONNECTOR_VARIANTS)),
  /** Defines the active status **/
  isActive: PropTypes.func,
};

StepConnector.defaultProps = {
  position: STEPPER_POSITIONS.RIGHT,
  variant: CONNECTOR_VARIANTS.HORIZONTAL,
  isActive: () => {},
};
