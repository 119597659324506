import React from 'react';
import { ElementStateWrapper, typographyButton, transitionAnimation } from 'lib/core';
import { IconCheck, IconError } from 'lib/icons';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { getStepIconStateStyles, stepIconErrorStateStyles, stepIconCompleteStateStyles } from '../stepperStyles';

const StyledDiv = styled.div`
  ${({ theme }) => css`
    ${typographyButton(theme)};
    align-items: center;
    border-radius: 50%;
    color: ${theme.color.additional.light.value};
    display: flex;
    flex-shrink: 0;
    height: 24px;
    justify-content: center;
    width: 24px;
    ${transitionAnimation('background-color')};
  `}
`;

const StyledIconContainer = styled.span`
  border-radius: 50%;
  display: flex;
  height: 24px;
  width: 24px;
`;

export const StepIcon = ({ hasError, isActive, isComplete, stepNumber, ...other }) => {
  const stepIconProps = { role: 'button' };
  const isFocusable = isActive || isComplete;

  if (hasError) {
    return (
      <ElementStateWrapper
        stateStyles={stepIconErrorStateStyles}
        useFocusStyles={isFocusable}
        triggerClickEventAsKeyDown
        {...other}
      >
        <StyledIconContainer>
          <IconError {...stepIconProps}>{stepNumber}</IconError>
        </StyledIconContainer>
      </ElementStateWrapper>
    );
  }

  if (isComplete) {
    return (
      <ElementStateWrapper stateStyles={stepIconCompleteStateStyles} triggerClickEventAsKeyDown {...other}>
        <StyledIconContainer>
          <IconCheck {...stepIconProps}>{stepNumber}</IconCheck>
        </StyledIconContainer>
      </ElementStateWrapper>
    );
  }

  return (
    <ElementStateWrapper
      stateStyles={getStepIconStateStyles(isActive)}
      useFocusStyles={isFocusable}
      triggerClickEventAsKeyDown
      {...other}
    >
      <StyledDiv {...stepIconProps}>{stepNumber}</StyledDiv>
    </ElementStateWrapper>
  );
};

StepIcon.propTypes = {
  /** If true, renders icon in active state */
  isActive: PropTypes.bool,
  /** If true, renders icon in completed state  */
  isComplete: PropTypes.bool,
  /** If true, renders icon in error state  */
  hasError: PropTypes.bool,
  /** Step number which is displayed in the icon */
  stepNumber: PropTypes.number,
};

StepIcon.defaultProps = {
  isActive: false,
  isComplete: false,
  hasError: false,
  stepNumber: 1,
};
