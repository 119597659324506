import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { PREFIX_MAX_CHARACTERS_ALLOWED } from '../constants';
import { getRem } from './../../core';

import { BaseInputPrefixSuffix } from './BaseInputPrefixSuffix';

const StyledInputPrefix = styled(BaseInputPrefixSuffix)`
  width: ${getRem('10px')};
`;

const InputPrefix = ({ text, ...other }) => {
  const formattedText = text.substring(0, PREFIX_MAX_CHARACTERS_ALLOWED);

  return <StyledInputPrefix {...other}>{formattedText}</StyledInputPrefix>;
};

InputPrefix.propTypes = {
  /** Unique identifier. Necessary to have for linking with input */
  id: PropTypes.string.isRequired,
  /** If true, adjusts label styling */
  isDisabled: PropTypes.bool,
  /** Displays provided text string up to 1 character */
  text: PropTypes.node.isRequired,
};

InputPrefix.defaultProps = {
  isDisabled: false,
};

export { InputPrefix };
