import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { BaseInputField, InputErrorText } from '../../blocks';
import { BORDER_POSITIONS, DIVIDER_POSITIONS, INPUT_ICON_POSITIONS, INPUT_SIZES } from '../../constants';
import {
  BaseInput,
  InputCharacterCount,
  InputHelperText,
  InputSuffixElement,
  InputIcon,
  InputSuffix,
  InputPrefix,
  InputLabel,
} from '../../elements';
import { INPUT_ERROR_TYPES } from '../../errors';
import { ie11ReadOnlyClickHandler, moveInputCursorToStart } from '../../shared';
import { getRem } from './../../../core';
import { useOnError } from './../../../utilities';

const StyledBaseInputWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
`;

const baseInputSizes = {
  [INPUT_SIZES.SMALL]: getRem(40),
  [INPUT_SIZES.STANDARD]: getRem(48),
  [INPUT_SIZES.LARGE]: getRem(56),
};

const StyledBaseInput = styled(BaseInput)`
  flex-direction: row;
  height: ${({ size }) => baseInputSizes[size]};
`;

const StyledInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 70%;
`;

const StyledInputFieldWrapper = styled.div`
  display: flex;
`;

const StyledInputSuffix = styled(InputSuffix)`
  padding-right: ${({ theme }) => theme.size.spacing.small.value};
`;

const StyledInputField = styled(BaseInputField)`
  text-align: ${({ textalign }) => textalign};
  outline: none;
`;

const StyledHelperTextContainer = styled.div`
  display: flex;
`;

const Input = React.forwardRef(
  (
    {
      // eslint-disable-next-line react/prop-types
      className,
      customInputElement,
      dataTestId,
      enableCustomValidation,
      enableIsRequiredValidation,
      errorMessage,
      hasCharacterCount,
      hasError,
      helperText,
      hideBorder,
      icon,
      iconPosition,
      id,
      inputClassName,
      inputDivider,
      isDisabled,
      isFocused,
      isObscured,
      isReadOnly,
      isRequired,
      label,
      maxLength,
      minLength,
      name,
      onBlur,
      onChange,
      onClick,
      onError,
      onFocus,
      placeholder,
      prefixText,
      renderSuffixElement,
      size,
      suffixText,
      type,
      value,
      ...other
    },
    ref
  ) => {
    const [isBlurred, setIsBlurred] = useState(false);
    const [hasFocus, setHasFocus] = useState(isFocused);

    const handleBlur = (event) => {
      moveInputCursorToStart(event.target, value);
      onBlur(event, { setIsBlurred });
      setHasFocus(false);
      setIsBlurred(true);
    };

    const handleClick = (event) => {
      if (isReadOnly) {
        ie11ReadOnlyClickHandler(event);
      }
      onClick(event);
    };

    const handleFocus = (event) => {
      onFocus(event);
      setHasFocus(true);
    };

    const isEmpty = !value || !value.toString().length;
    const isRequiredError = enableIsRequiredValidation && isRequired && isEmpty && isBlurred;
    const minLengthError = value.length < minLength && !isEmpty && !hasFocus;

    const errors = [
      isRequiredError && INPUT_ERROR_TYPES.REQUIRED,
      minLengthError && INPUT_ERROR_TYPES.MIN_LENGTH,
    ].filter(Boolean);

    useOnError({
      errors,
      onError,
    });

    const updatedHasError = enableCustomValidation ? hasError : errors.length > 0;

    const isInvalid = !isDisabled && !isReadOnly && updatedHasError;

    const showHelperText = !isInvalid && !!helperText;
    const showErrorMessage = !!isInvalid && !!errorMessage;

    const isIconTrailing = (!!icon && iconPosition === INPUT_ICON_POSITIONS.TRAILING) || !!renderSuffixElement;
    const isIconLeading =
      (!!icon && iconPosition === INPUT_ICON_POSITIONS.LEADING) || (!!renderSuffixElement && !!icon);

    const inputLabelId = `input-${id}-label`;
    const inputPrefixId = `input-${id}-prefix`;
    const inputSuffixId = `input-${id}-suffix`;
    const inputHelperTextId = `input-${id}-help-text`;
    const inputErrorTextId = `input-${id}-error-text`;

    const inputAriaLabelledBy = classNames(inputLabelId, {
      [inputPrefixId]: !!prefixText,
      [inputSuffixId]: !!suffixText,
    });
    const inputAriaDescribedBy = classNames({
      [inputErrorTextId]: showErrorMessage,
      [inputHelperTextId]: showHelperText,
    });

    return (
      <StyledBaseInputWrapper className={className} data-testid={dataTestId ? `${dataTestId}-wrapper` : undefined}>
        <StyledBaseInput
          aria-describedby={inputAriaDescribedBy}
          aria-labelledby={inputAriaLabelledBy}
          className={inputClassName}
          data-testid={dataTestId ? `base-${dataTestId}` : undefined}
          disabled={isDisabled}
          hasError={isInvalid}
          hideBorder={hideBorder}
          htmlFor={id}
          inputDivider={inputDivider}
          isDisabled={isDisabled}
          isFocused={hasFocus}
          isReadOnly={isReadOnly}
          isRequired={isRequired}
          size={size}
        >
          {!!icon && iconPosition === INPUT_ICON_POSITIONS.LEADING && (
            <InputIcon
              data-testid={dataTestId ? `${dataTestId}-icon` : undefined}
              hasError={isInvalid}
              icon={icon}
              isFocused={hasFocus}
              position={INPUT_ICON_POSITIONS.LEADING}
            />
          )}

          <StyledInputWrapper>
            <InputLabel
              data-testid={dataTestId ? `${dataTestId}-label` : undefined}
              hasError={!!isInvalid}
              id={inputLabelId}
              isDisabled={isDisabled}
              isFocused={hasFocus}
              isReadOnly={isReadOnly}
              isRequired={isRequired}
              label={label}
              size={size}
              withPrefixElement={isIconLeading}
              withSuffixElement={isIconTrailing}
            />
            <StyledInputFieldWrapper>
              {!!prefixText && <InputPrefix id={inputPrefixId} isDisabled={isDisabled} size={size} text={prefixText} />}
              <StyledInputField
                aria-describedby={inputAriaDescribedBy}
                aria-labelledby={inputAriaLabelledBy}
                as={customInputElement}
                className={inputClassName}
                data-testid={dataTestId}
                disabled={isDisabled}
                hasError={isInvalid}
                id={id}
                isObscured={isObscured}
                maxLength={maxLength}
                minLength={minLength}
                name={name}
                onBlur={handleBlur}
                onChange={onChange}
                onClick={handleClick}
                onFocus={handleFocus}
                placeholder={placeholder}
                readOnly={isReadOnly}
                ref={ref}
                required={isRequired}
                size={size}
                type={type}
                value={value}
                {...other}
              />
              {!!suffixText && (
                <StyledInputSuffix id={inputSuffixId} isDisabled={isDisabled} size={size} text={suffixText} />
              )}
            </StyledInputFieldWrapper>
          </StyledInputWrapper>

          {!!icon && iconPosition === INPUT_ICON_POSITIONS.TRAILING && (
            <InputIcon hasError={isInvalid} icon={icon} isFocused={hasFocus} position={INPUT_ICON_POSITIONS.TRAILING} />
          )}
          {!!renderSuffixElement && (
            <InputSuffixElement hasError={isInvalid} isDisabled={isDisabled} isFocused={hasFocus}>
              {renderSuffixElement()}
            </InputSuffixElement>
          )}
        </StyledBaseInput>
        <StyledHelperTextContainer>
          {showHelperText && <InputHelperText id={inputHelperTextId} text={helperText} />}
          {showErrorMessage && <InputErrorText id={inputErrorTextId} text={errorMessage} />}
          {hasCharacterCount && <InputCharacterCount maxLength={maxLength} value={value} />}
        </StyledHelperTextContainer>
      </StyledBaseInputWrapper>
    );
  }
);

Input.propTypes = {
  /** Ability to supply a different input element instead of the default one */
  customInputElement: PropTypes.elementType,
  /** Id value used for testing */
  dataTestId: PropTypes.string,
  /** If true, custom validation is being enabled instead of built in component validation */
  enableCustomValidation: PropTypes.bool,
  /** If true, field becomes invalid when empty. It does not get invalid if false. Default value is true */
  enableIsRequiredValidation: PropTypes.bool,
  /** Message to be displayed when input is in error state */
  errorMessage: PropTypes.node,
  /** When true, displays character count for input */
  hasCharacterCount: PropTypes.bool,
  /** When true, input is in error state */
  hasError: PropTypes.bool,
  /** Text to be displayed as a helper text near the input field */
  helperText: PropTypes.node,
  /** Defines which side of input container border should be hidden */
  hideBorder: PropTypes.oneOf(Object.values(BORDER_POSITIONS)),
  /** Icon to be displayed in input field */
  icon: PropTypes.node,
  /** Sets the position of icon */
  iconPosition: PropTypes.oneOf(Object.values(INPUT_ICON_POSITIONS)),
  /** Identifier of the input component */
  id: PropTypes.string.isRequired,
  /** Adds additional class to input tag */
  inputClassName: PropTypes.string,
  /** Defines the side of vertical divider */
  inputDivider: PropTypes.oneOf(Object.values(DIVIDER_POSITIONS)),
  /** If true, input is disabled and value of it cannot be edited */
  isDisabled: PropTypes.bool,
  /** If true, visually applies focused input styles */
  isFocused: PropTypes.bool,
  /** If true, changes password mask dots to be bigger */
  isObscured: PropTypes.bool,
  /** If true, input is in read only state, value cannot be edited */
  isReadOnly: PropTypes.bool,
  /** If true, isRequired asterisk will be shown */
  isRequired: PropTypes.bool,
  /** Label of the input field */
  label: PropTypes.node.isRequired,
  /** Maximum amount of characters input field can have */
  maxLength: PropTypes.number,
  /** Minimum amount of characters to be entered so the input field would not be in error state */
  minLength: PropTypes.number,
  /** Name of the input */
  name: PropTypes.string.isRequired,
  /** Callback to be called when input looses focus */
  onBlur: PropTypes.func,
  /** Callback to be called when input's value is being changed by user interaction */
  onChange: PropTypes.func.isRequired,
  /** Callback to be called when input is clicked */
  onClick: PropTypes.func,
  /** Callback to be called when inputs validation fails */
  onError: PropTypes.func,
  /** Callback that is called when Input has been focused */
  onFocus: PropTypes.func,
  /** Text to be displayed when input is empty */
  placeholder: PropTypes.node,
  /** String to be displayed before the input value. Prefix text should be 1 character. */
  prefixText: PropTypes.node,
  /** Clickable icon in input field, which will toggle input text visibility */
  renderSuffixElement: PropTypes.func,
  /** Set the size of the input */
  size: PropTypes.oneOf(Object.values(INPUT_SIZES)),
  /** String to be displayed after the input value. Text should contain up to 5 characters, to not get cutted */
  suffixText: PropTypes.node,
  /** Set the text alignment inside Input field */
  textalign: PropTypes.string,
  /** Standard html input tag type attribute */
  type: PropTypes.string,
  /** Current value of the input field */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

Input.defaultProps = {
  customInputElement: undefined,
  dataTestId: undefined,
  enableCustomValidation: false,
  enableIsRequiredValidation: true,
  errorMessage: '',
  hasCharacterCount: false,
  hasError: false,
  helperText: '',
  hideBorder: BORDER_POSITIONS.NONE,
  icon: undefined,
  iconPosition: INPUT_ICON_POSITIONS.TRAILING,
  inputClassName: '',
  inputDivider: DIVIDER_POSITIONS.NONE,
  isDisabled: false,
  isFocused: false,
  isObscured: false,
  isReadOnly: false,
  isRequired: false,
  maxLength: 100,
  minLength: 0,
  onBlur: () => {},
  onClick: () => {},
  onError: () => {},
  onFocus: () => {},
  placeholder: '',
  prefixText: '',
  renderSuffixElement: undefined,
  size: INPUT_SIZES.STANDARD,
  suffixText: '',
  textalign: 'unset',
  type: 'text',
};

export { Input };
