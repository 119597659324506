import React from 'react';

import { BUTTON_BACKGROUND_APPEARANCES, BUTTON_ICON_COLORS, BUTTON_SIZES, BUTTON_VARIANTS } from 'lib/button';
import { ICON_SIZE } from 'lib/icons';
import { Portal } from 'lib/portal';
import { KEY_CODES, useKeyDownEventListener } from 'lib/utilities';
import PropTypes from 'prop-types';
import { FocusOn } from 'react-focus-on';

import { DialogHeader, DialogFooter } from '../blocks';
import { DialogContent, DialogOverlay, DialogWrapper } from '../elements';

const Dialog = ({
  buttonsProps,
  children,
  closeLabel,
  customFooterTag,
  customHeaderTag,
  customTitleTag,
  dataTestId,
  disableFocusTrap,
  disableOverlayClicked,
  hideCloseButton,
  hideHeader,
  id,
  isFullScreen,
  isOpen,
  leftButtonProps,
  onClose,
  renderCustomFooterComponent,
  title,
  ...other
}) => {
  const showButtons = buttonsProps && buttonsProps.length > 0;
  const showContent = !!children;

  const titleId = `${id}-title`;

  const handleOverlayClick = () => {
    if (!disableOverlayClicked) {
      onClose();
    }
  };

  useKeyDownEventListener(KEY_CODES.ESCAPE, () => {
    if (isOpen && !disableOverlayClicked) {
      onClose();
    }
  });

  if (!isOpen) {
    return null;
  }

  return (
    <Portal id={id}>
      <FocusOn enabled={!disableFocusTrap}>
        <DialogWrapper
          aria-labelledby={titleId}
          data-testid={dataTestId}
          id={id}
          isFullScreen={isFullScreen}
          {...other}
        >
          {!hideHeader && (
            <DialogHeader
              id={id}
              closeLabel={closeLabel}
              customTag={customHeaderTag}
              customTitleTag={customTitleTag}
              dataTestId={dataTestId}
              hideCloseButton={hideCloseButton}
              onClose={onClose}
              title={title}
              titleId={titleId}
            />
          )}
          {showContent && (
            <DialogContent dataTestId={dataTestId} hasExtraPadding={hideHeader} isFullScreen={isFullScreen}>
              {children}
            </DialogContent>
          )}
          {showButtons && !renderCustomFooterComponent && (
            <DialogFooter
              as={customFooterTag}
              buttonsProps={buttonsProps}
              dataTestId={dataTestId}
              id={id}
              leftButtonProps={leftButtonProps}
            />
          )}
          {!!renderCustomFooterComponent && renderCustomFooterComponent()}
        </DialogWrapper>
        <DialogOverlay
          data-testid={dataTestId ? `${dataTestId}-dialog-overlay` : undefined}
          onClick={handleOverlayClick}
        />
      </FocusOn>
    </Portal>
  );
};

Dialog.propTypes = {
  /** Array of action elements. Action element contains Button component properties */
  buttonsProps: PropTypes.arrayOf(
    PropTypes.shape({
      /** Will adjust button styles accordingly to background it is represented on */
      backgroundAppearance: PropTypes.oneOf(Object.values(BUTTON_BACKGROUND_APPEARANCES)),
      /** Replaces default tag: button or anchor with new value */
      customTagElement: PropTypes.string,
      /** If true, will visually hide text */
      hideText: PropTypes.bool,
      /** When URL is provided, element changes from button to hyperlink <a> */
      href: PropTypes.string,
      /** If not loading, will render specified icon before text */
      icon: PropTypes.node,
      /** Adds new class for icon element */
      iconClassName: PropTypes.string,
      /** Will change icon color */
      iconColor: PropTypes.oneOf(Object.values(BUTTON_ICON_COLORS)),
      /** Will set size of the icon */
      iconSize: PropTypes.oneOf(Object.values(ICON_SIZE)),
      /** Id of component */
      id: PropTypes.string.isRequired,
      /** Disallows user to interact with the button and adjusts appearance */
      isDisabled: PropTypes.bool,
      /** If true, will display loader and adjust width to better accommodate spinner */
      isLoading: PropTypes.bool,
      /** Loader text which will be read for screen reader users */
      loaderText: PropTypes.node,
      /** Callback that is called on click */
      onClick: PropTypes.func,
      /** Callback that is called on key down */
      onKeyDown: PropTypes.func,
      /** If true, will add vertical margins to the component */
      preserveClickableArea: PropTypes.bool,
      /** Changes button height */
      size: PropTypes.oneOf(Object.values(BUTTON_SIZES)),
      /** Will display text inside button */
      text: PropTypes.node.isRequired,
      /** Default html button 'type' attribute values when button component is used */
      type: PropTypes.string,
      /** Changes button style depending on variant */
      variant: PropTypes.oneOf([BUTTON_VARIANTS.TEXT, BUTTON_VARIANTS.PRIMARY]),
    })
  ),
  /** Any content inserted between component tags */
  children: PropTypes.node,
  /** Label for close icon button */
  closeLabel: PropTypes.node,
  /** Ability to supply a custom footer element */
  customFooterTag: PropTypes.elementType,
  /** Ability to supply a custom header element */
  customHeaderTag: PropTypes.elementType,
  /** Ability to supply a custom title element */
  customTitleTag: PropTypes.elementType,
  /** Id value used for testing */
  dataTestId: PropTypes.string,
  /** If true, focus trap is disabled */
  disableFocusTrap: PropTypes.bool,
  /** If true, clicking the overlay or pressing escape button will not fire the onClose callback. */
  disableOverlayClicked: PropTypes.bool,
  /** If true, close button is hidden */
  hideCloseButton: PropTypes.bool,
  /** If true, header is visually hidden */
  hideHeader: PropTypes.bool,
  /** Id of the component */
  id: PropTypes.string.isRequired,
  /** If true, dialog will occupy entire viewport */
  isFullScreen: PropTypes.bool,
  /** If true, component is visible */
  isOpen: PropTypes.bool.isRequired,
  /** Left aligned action element. Action element contains Button component properties */
  leftButtonProps: PropTypes.shape({
    /** Will adjust button styles accordingly to background it is represented on */
    backgroundAppearance: PropTypes.oneOf(Object.values(BUTTON_BACKGROUND_APPEARANCES)),
    /** Replaces default tag: button or anchor with new value */
    customTagElement: PropTypes.string,
    /** If true, will visually hide text */
    hideText: PropTypes.bool,
    /** When URL is provided, element changes from button to hyperlink <a> */
    href: PropTypes.string,
    /** If not loading, will render specified icon before text */
    icon: PropTypes.node,
    /** Adds new class for icon element */
    iconClassName: PropTypes.string,
    /** Will change icon color */
    iconColor: PropTypes.oneOf(Object.values(BUTTON_ICON_COLORS)),
    /** Will set size of the icon */
    iconSize: PropTypes.oneOf(Object.values(ICON_SIZE)),
    /** Id of component */
    id: PropTypes.string.isRequired,
    /** Disallows user to interact with the button and adjusts appearance */
    isDisabled: PropTypes.bool,
    /** If true, will display loader and adjust width to better accommodate spinner */
    isLoading: PropTypes.bool,
    /** Loader text which will be read for screen reader users */
    loaderText: PropTypes.node,
    /** Callback that is called on click */
    onClick: PropTypes.func,
    /** Callback that is called on key down */
    onKeyDown: PropTypes.func,
    /** If true, will add vertical margins to the component */
    preserveClickableArea: PropTypes.bool,
    /** Changes button height */
    size: PropTypes.oneOf(Object.values(BUTTON_SIZES)),
    /** Will display text inside button */
    text: PropTypes.node.isRequired,
    /** Default html button 'type' attribute values when button component is used */
    type: PropTypes.string,
    /** Changes button style depending on variant */
    variant: PropTypes.oneOf([BUTTON_VARIANTS.TEXT, BUTTON_VARIANTS.PRIMARY]),
  }),
  /** Callback that is called when component should close */
  onClose: PropTypes.func,
  /** Ability to supply any component as custom footer component */
  renderCustomFooterComponent: PropTypes.func,
  /** Primary title of the component */
  title: PropTypes.node.isRequired,
};

Dialog.defaultProps = {
  buttonsProps: undefined,
  children: undefined,
  closeLabel: 'Close',
  customFooterTag: undefined,
  customHeaderTag: undefined,
  customTitleTag: undefined,
  dataTestId: undefined,
  disableFocusTrap: false,
  disableOverlayClicked: false,
  hideCloseButton: false,
  hideHeader: false,
  isFullScreen: false,
  leftButtonProps: undefined,
  onClose: () => {},
  renderCustomFooterComponent: undefined,
};

export { Dialog };
