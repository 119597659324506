import React, { useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import classNames from 'classnames';

import { Loader, LOADER_VARIANTS } from 'lib/loader';
import { classNamePrefix, preventDefaultBehavior, useWindowSize } from 'lib/utilities';
import PropTypes from 'prop-types';

import { CustomNoRowsOverlay, focusLastCell, refreshCellsOnRowSelect } from '../agGridUtils';
import { ICONS } from '../assets/tableIcons';
import { TableHeader, TableHeaderCustomContent, TablePagination } from '../blocks';
import { ButtonRenderer, IconRenderer, LinkRenderer, RatingRenderer, TextRenderer } from '../cellRenderers';
import { DEFAULT_ROWS_PER_PAGE_OPTIONS, ROW_SELECTION, ROW_SIZES, TABLE_LAYOUT_OPTIONS } from '../constants';
import { TableColumnDndContainer, TableContainer, TableContent, TableFooter } from '../elements';
import { addCellEventHandlers, usePreventTableScrollingRef } from '../utilities';
import { isMobileScreen } from './../../core';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';

const getButtonRendererComponent = (rowSize) => (props) => <ButtonRenderer {...props} rowSize={rowSize} />;
const getCustomLoadingOverlay = (label, loaderProps) => () => <Loader label={label} {...loaderProps} />;
const getCustomNoRowsOverlay = (message, showEnteredValue) => (props) => (
  <CustomNoRowsOverlay {...props} message={message} showEnteredValue={showEnteredValue} />
);
const getIconRendererComponent = (rowSize) => (props) => <IconRenderer {...props} rowSize={rowSize} />;
const getRatingRendererComponent = (rowSize) => (props) => <RatingRenderer {...props} rowSize={rowSize} />;

const Table = React.forwardRef(
  (
    {
      className,
      columns,
      customCellEventHandlers,
      customDownloadHandler,
      customHeaderContent,
      customPrintHandler,
      customSearchHandler,
      data,
      dataTestId,
      defaultPaginationPageSize,
      disableColumnsMove,
      downloadButtonText,
      enableFilter,
      footerClassName,
      frameworkComponents,
      getPageSummaryText,
      getRowsSummaryText,
      hasPagination,
      headerClassName,
      headerTitle,
      hideBorder,
      hideTableHeader,
      hideTableTitle,
      id,
      isAlwaysExpanded,
      isDownloadable,
      isLarge,
      isPrintable,
      isSearchable,
      loaderProps,
      loadingMessage,
      noRowsFoundMessage,
      onGridReady,
      onSearchInputChange,
      overflowButtonProps,
      pageNavigationAriaLabel,
      paginationButtonLabels,
      paginationClassName,
      printButtonText,
      quickFilterText,
      resizableHeaders,
      rowSelection,
      rowsPerPageLabel,
      rowsPerPageOptions,
      searchInputName,
      searchInputValue,
      searchPlaceholder,
      showCheckboxSelection,
      showFooter,
      showGoToFirstLastPage,
      showSearchInputValueInNoRowsFoundMessage,
      tableLayout,
      ...other
    },
    ref
  ) => {
    const [gridApi, setGridApi] = useState();
    const [currentPage, setCurrentPage] = useState();
    const [totalPages, setTotalPages] = useState();
    const [totalRows, setTotalRows] = useState();
    const [showPagination, setShowPagination] = useState(true);

    const windowSize = useWindowSize();
    const isMobile = isMobileScreen(windowSize.width);
    const rowHeight = isMobile || isLarge ? ROW_SIZES.MOBILE : ROW_SIZES.STANDARD;
    const isAgGridReady = !!gridApi;
    const paginationPageSize = isAgGridReady ? gridApi.paginationGetPageSize() : defaultPaginationPageSize;
    const hasNoRows = totalRows === 0;

    const componentMainClass = `${classNamePrefix}-table`;
    const agGridThemeClass = 'ag-theme-balham';
    const agViewportClass = 'ag-body-viewport';

    const tableClass = classNames(componentMainClass, agGridThemeClass, className);

    const pageSizeDropdownId = `page-size-dropdown-${id}`;
    const paginationPageSummaryLabelId = `pagination-summary-label-${id}`;

    const tableIcons = {
      columnMovePin: ICONS.PIN,
      sortAscending: ICONS.SORT_ASC,
      sortDescending: ICONS.SORT_DESC,
      sortUnSort: ICONS.UNSORT,
    };

    const loadingOverlayComponent = 'customLoadingOverlay';
    const noRowsOverlayComponent = 'customNoRowsOverlay';

    const frameworkComponentsExtended = {
      buttonRenderer: getButtonRendererComponent(rowHeight),
      customLoadingOverlay: getCustomLoadingOverlay(loadingMessage, loaderProps),
      customNoRowsOverlay: getCustomNoRowsOverlay(noRowsFoundMessage, showSearchInputValueInNoRowsFoundMessage),
      iconRenderer: getIconRendererComponent(rowHeight),
      linkRenderer: LinkRenderer,
      ratingRenderer: getRatingRendererComponent(rowHeight),
      textRenderer: TextRenderer,
      ...frameworkComponents,
    };

    const handleFirstDataRendered = (gridParams) => {
      setGridApi(gridParams.api);
      gridParams.api.sizeColumnsToFit();
      if (gridApi) gridApi.resetRowHeights();
      addCellEventHandlers(customCellEventHandlers);
    };

    const handleGridReady = (gridParams) => {
      if (document) {
        const agGridViewportElement = document.querySelector(`.${componentMainClass} .${agViewportClass}`);

        agGridViewportElement.tabIndex = -1;
      }

      setGridApi(gridParams.api);
      setCurrentPage(gridParams.api.paginationGetCurrentPage() + 1);
      setTotalPages(gridParams.api.paginationGetTotalPages());
      setTotalRows(gridParams.api.getDisplayedRowCount());
      gridParams.api.sizeColumnsToFit();
      onGridReady(gridParams);
    };

    const handleDownloadClick = () => {
      gridApi.exportDataAsCsv();
    };

    const handlePrintClick = () => {
      const isSafari = navigator.userAgent.toLowerCase().indexOf('safari/') > -1;

      gridApi.setDomLayout(TABLE_LAYOUT_OPTIONS.PRINT);
      setShowPagination(gridApi.gridOptionsWrapper.gridOptions.domLayout !== TABLE_LAYOUT_OPTIONS.PRINT);
      setTimeout(() => {
        /* In case any column width adjustments are needed - as per documentation - pinned columns
                must be unpinned and all width/height data cleared before doing it */
        if (isSafari) {
          // Safari print
          document.execCommand('print', false, null);
        } else {
          // eslint-disable-next-line no-restricted-globals
          print();
        }
        gridApi.setDomLayout(tableLayout);
        setShowPagination(true);
      }, 500);
    };

    const handleSearchInputChange = (searchInput) => {
      onSearchInputChange(searchInput);
      if (customSearchHandler) {
        customSearchHandler(searchInput);
      }
      if (gridApi && !customSearchHandler) {
        gridApi.setQuickFilter(searchInput);
        // Refresh is needed for filtered cells highlighting to work properly
        gridApi.refreshCells({ force: true });

        const displayedRowsCount = gridApi.getDisplayedRowCount();
        setTotalRows(displayedRowsCount);
        if (searchInput && displayedRowsCount === 0) {
          gridApi.showNoRowsOverlay();
        } else {
          gridApi.hideOverlay();
        }
      }
    };

    const handlePaginationChanged = () => {
      if (!isAgGridReady) return;

      setCurrentPage(gridApi.paginationGetCurrentPage() + 1);
      setTotalPages(gridApi.paginationGetTotalPages());
      setTotalRows(gridApi.getDisplayedRowCount());
    };

    const handleTabbingFromPagination = (event) => {
      const hasRowsToDisplay = gridApi.rowModel.rowsToDisplay.length > 0;

      if (hasRowsToDisplay) {
        preventDefaultBehavior(event);
        focusLastCell(gridApi);
      }
    };

    const tableContentRef = usePreventTableScrollingRef();

    const defaultColumnDefinition = {
      filter: enableFilter,
      resizable: resizableHeaders,
      suppressMovable: disableColumnsMove,
    };

    const headerProps = {
      className: headerClassName,
      dataTestId: dataTestId ? `${dataTestId}-header` : undefined,
      disableDownloadButton: hasNoRows,
      disablePrintButton: hasNoRows,
      downloadButtonText,
      headerTitle,
      hideBorder,
      hideTableTitle,
      id: id ? `${id}-header` : undefined,
      isAlwaysExpanded,
      isDownloadable,
      isPrintable,
      isSearchable,
      onDownloadClick: customDownloadHandler || handleDownloadClick,
      onPrintClick: customPrintHandler || handlePrintClick,
      onSearchInputChange: handleSearchInputChange,
      overflowButtonProps,
      printButtonText,
      searchInputName,
      searchInputValue,
      searchPlaceholder,
    };

    const paginationProps = {
      ariaLabel: pageNavigationAriaLabel,
      buttonLabels: paginationButtonLabels,
      className: paginationClassName,
      dataTestId: dataTestId ? `${dataTestId}-pagination` : undefined,
      defaultPaginationPageSize,
      gridApi,
      hideBorder,
      onTabbingIntoTable: handleTabbingFromPagination,
      pageSizeDropdownId,
      pageSummaryLabelId: paginationPageSummaryLabelId,
      pageSummaryText: getPageSummaryText(totalPages === 0 ? 0 : currentPage, totalPages),
      rowsPerPageLabel,
      rowsPerPageOptions,
      rowsSummaryText: getRowsSummaryText(currentPage, paginationPageSize, totalRows),
      showGoToFirstLastPage,
    };

    return (
      <TableContainer className={tableClass} isMobile={isMobile} tableLayout={tableLayout} hideBorder={hideBorder}>
        {!hideTableHeader && <TableHeader {...headerProps} />}
        {!!customHeaderContent && <TableHeaderCustomContent customHeaderContent={customHeaderContent} />}
        <TableContent data-testid={dataTestId} ref={tableContentRef}>
          <AgGridReact
            columnDefs={columns}
            defaultColDef={defaultColumnDefinition}
            domLayout={tableLayout}
            frameworkComponents={frameworkComponentsExtended}
            icons={tableIcons}
            loadingOverlayComponent={loadingOverlayComponent}
            noRowsOverlayComponent={noRowsOverlayComponent}
            onFirstDataRendered={handleFirstDataRendered}
            onGridReady={handleGridReady}
            onRowSelected={refreshCellsOnRowSelect}
            onPaginationChanged={handlePaginationChanged}
            pagination={hasPagination}
            paginationPageSize={defaultPaginationPageSize.value}
            quickFilterText={quickFilterText}
            ref={ref}
            rowData={data}
            rowDeselection
            rowHeight={rowHeight}
            rowSelection={rowSelection}
            suppressDragLeaveHidesColumns
            suppressPaginationPanel
            suppressRowClickSelection={showCheckboxSelection}
            {...other}
          />
        </TableContent>
        {!hasPagination && showFooter && <TableFooter className={footerClassName} hideBorder={hideBorder} />}
        {isAgGridReady && hasPagination && showPagination && <TablePagination {...paginationProps} />}
        <TableColumnDndContainer />
      </TableContainer>
    );
  }
);

Table.propTypes = {
  /** Adds className to main wrapper */
  className: PropTypes.string,
  /** Table column headers. For correct header structure look into story examples or ag-grid documentation */
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  /** Adds custom event handlers to table cells */
  customCellEventHandlers: PropTypes.arrayOf(
    PropTypes.shape({
      /** Adds handlers to cells of provided column */
      columnId: PropTypes.string.isRequired,
      /** Adds event listener that triggers handler */
      eventListener: PropTypes.string.isRequired,
      /** Event handler function */
      handleEvent: PropTypes.func.isRequired,
    })
  ),
  /** Custom callback that is called on download icon click */
  customDownloadHandler: PropTypes.func,
  /** Custom callback content to render below table header */
  customHeaderContent: PropTypes.node,
  /** Custom callback that is called on print icon click */
  customPrintHandler: PropTypes.func,
  /** Custom callback that is called on search input change */
  customSearchHandler: PropTypes.func,
  /** Table rows data. For correct data structure look into story examples or ag-grid documentation */
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  /** Id value used for testing */
  dataTestId: PropTypes.string,
  /** Sets default value of how many rows to load per page */
  defaultPaginationPageSize: PropTypes.shape({
    /** Label of the dropdown option */
    label: PropTypes.node.isRequired,
    /** Value of the dropdown option */
    value: PropTypes.number.isRequired,
  }),
  /** If true, column reorder is disabled. Can be overridden by setting each column property separately */
  disableColumnsMove: PropTypes.bool,
  /** Sets text for table header download button */
  downloadButtonText: PropTypes.node,
  /** If true, enables column filtering */
  enableFilter: PropTypes.bool,
  /** Adds className to table footer */
  footerClassName: PropTypes.string,
  /** Adds frameworkComponents to table */
  frameworkComponents: PropTypes.shape({}),
  /** Callback function to return page summary text of pagination bar */
  getPageSummaryText: PropTypes.func,
  /** Callback function to return rows summary text of pagination bar */
  getRowsSummaryText: PropTypes.func,
  /** If true, enables pagination */
  hasPagination: PropTypes.bool,
  /** Adds className to table header */
  headerClassName: PropTypes.string,
  /** Sets table title */
  headerTitle: PropTypes.node.isRequired,
  /** If true, table header is hidden */
  hideTableHeader: PropTypes.bool,
  /** If true, hides table border */
  hideBorder: PropTypes.bool,
  /** If true, table title is visually hidden */
  hideTableTitle: PropTypes.bool,
  /** Identifier of the table component */
  id: PropTypes.string.isRequired,
  /** If true, search input is always expanded */
  isAlwaysExpanded: PropTypes.bool,
  /** If true, table data can be downloaded */
  isDownloadable: PropTypes.bool,
  /** If true, renders table as large on all screens.
   * Should only be used when inputs/buttons are needed in table cells */
  isLarge: PropTypes.bool,
  /** If true, table data can be printed */
  isPrintable: PropTypes.bool,
  /** If true, quick filter can be used to search table data */
  isSearchable: PropTypes.bool,
  /** Loader, used in loading overlay, props */
  loaderProps: PropTypes.shape({
    /** Visually hides loader text */
    hideLabel: PropTypes.bool,
    /** Adds additional class to spinner */
    spinnerClassName: PropTypes.string,
    /** Loader variation */
    variant: PropTypes.oneOf(Object.values(LOADER_VARIANTS)),
  }),
  /** Message of loading data overlay  */
  loadingMessage: PropTypes.node,
  /** Sets message for no rows found overlay */
  noRowsFoundMessage: PropTypes.node,
  /** Callback that is called on grid ready event */
  onGridReady: PropTypes.func,
  /** Callback that is called on search input change */
  onSearchInputChange: PropTypes.func,
  /** Props for overflow button */
  overflowButtonProps: PropTypes.shape({
    /** Context menu options. For correct data structure refer to component documentation */
    contextMenuOptions: PropTypes.arrayOf(
      PropTypes.shape({
        /** Renders icon before label */
        icon: PropTypes.node,
        /** Unique identifier for option */
        id: PropTypes.string,
        /** If true, disables option */
        isDisabled: PropTypes.bool,
        /** Option label */
        label: PropTypes.string,
      })
    ),
    /** Id value used for testing */
    dataTestId: PropTypes.string,
    /** Sets text for overflow button */
    text: PropTypes.node,
  }),
  /** Informs screen reader users what actions they should take */
  pageNavigationAriaLabel: PropTypes.node,
  /** Set of button labels used in pagination bar */
  paginationButtonLabels: PropTypes.shape({
    /** Label for go to first page button */
    goToFirst: PropTypes.node,
    /** Label for go to last page button */
    goToLast: PropTypes.node,
    /** Label for go to next page button */
    goToNext: PropTypes.node,
    /** Label for go to previous page button */
    goToPrevious: PropTypes.node,
  }),
  /** Adds className to table pagination */
  paginationClassName: PropTypes.string,
  /** Sets text for table header print button */
  printButtonText: PropTypes.node,
  /** Sets a text for a quick filter. Rows are filtered based on a text value */
  quickFilterText: PropTypes.node,
  /** If true, column headers width is adjustable */
  resizableHeaders: PropTypes.bool,
  /** Changes row selection mode */
  rowSelection: PropTypes.oneOf(Object.values(ROW_SELECTION)),
  /** Sets label for rows per page dropdown */
  rowsPerPageLabel: PropTypes.node,
  /** List of pagination page size available options */
  rowsPerPageOptions: PropTypes.arrayOf(
    PropTypes.shape({
      /** Label of the dropdown option */
      label: PropTypes.node.isRequired,
      /** Value of the dropdown option */
      value: PropTypes.number.isRequired,
    })
  ),
  /** Sets name for table header search input */
  searchInputName: PropTypes.string.isRequired,
  /** Sets initial search input value */
  searchInputValue: PropTypes.string,
  /** Sets a placeholder for table header search input */
  searchPlaceholder: PropTypes.node,
  /** Adds checkbox selection for the table */
  showCheckboxSelection: PropTypes.bool,
  /** If true, shows table footer */
  showFooter: PropTypes.bool,
  /** If true, go to first/last page buttons are shown in pagination bar */
  showGoToFirstLastPage: PropTypes.bool,
  /** If true, shows search input value in noRowsFound message */
  showSearchInputValueInNoRowsFoundMessage: PropTypes.bool,
  /** Sets dom layout of the table */
  tableLayout: PropTypes.oneOf(Object.values(TABLE_LAYOUT_OPTIONS)),
};

Table.defaultProps = {
  className: '',
  customCellEventHandlers: [],
  customDownloadHandler: undefined,
  customHeaderContent: undefined,
  customPrintHandler: undefined,
  customSearchHandler: undefined,
  dataTestId: undefined,
  defaultPaginationPageSize: DEFAULT_ROWS_PER_PAGE_OPTIONS[0],
  disableColumnsMove: false,
  downloadButtonText: 'Download button',
  enableFilter: false,
  footerClassName: '',
  frameworkComponents: undefined,
  getPageSummaryText: (currentPage, totalPages) => {
    return `Page ${currentPage} of ${totalPages}`;
  },
  getRowsSummaryText: (currentPage, pageSize, totalRows) => {
    if (totalRows === 0) {
      return `${totalRows}-${totalRows} of ${totalRows}`;
    }
    const rowsFrom = (currentPage - 1) * pageSize + 1;
    const rowsTo = currentPage * pageSize;

    return `${rowsFrom}-${rowsTo > totalRows ? totalRows : rowsTo} of ${totalRows}`;
  },
  hasPagination: false,
  headerClassName: '',
  hideBorder: false,
  hideTableHeader: false,
  hideTableTitle: false,
  isAlwaysExpanded: false,
  isDownloadable: true,
  isLarge: false,
  isPrintable: true,
  isSearchable: true,
  loaderProps: undefined,
  loadingMessage: 'Loading...',
  noRowsFoundMessage: 'No results found, please try using a different search term.',
  onGridReady: () => {},
  onSearchInputChange: () => {},
  overflowButtonProps: undefined,
  pageNavigationAriaLabel: 'Pagination',
  paginationButtonLabels: {
    goToFirst: 'Go to first',
    goToLast: 'Go to last',
    goToNext: 'Go to next',
    goToPrevious: 'Go to previous',
  },
  paginationClassName: '',
  printButtonText: 'Print button',
  quickFilterText: '',
  resizableHeaders: true,
  rowSelection: ROW_SELECTION.SINGLE,
  rowsPerPageLabel: 'Rows per page:',
  rowsPerPageOptions: DEFAULT_ROWS_PER_PAGE_OPTIONS,
  searchInputValue: '',
  searchPlaceholder: 'Search all columns',
  showCheckboxSelection: false,
  showFooter: true,
  showGoToFirstLastPage: true,
  showSearchInputValueInNoRowsFoundMessage: false,
  tableLayout: TABLE_LAYOUT_OPTIONS.NORMAL,
};

export { Table };
