import React, { useState } from 'react';

import { BUTTON_BACKGROUND_APPEARANCES, SecondaryButton } from 'lib/button';
import { ContextMenu, MENU_HORIZONTAL_POSITIONS, MENU_VERTICAL_POSITIONS } from 'lib/context-menu';
import { IconMoreHoriz } from 'lib/icons';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { SCREEN_SIZES } from '../constants';
import { getRem } from './../../core';

const StyledContextMenu = styled(ContextMenu)`
  max-width: ${getRem('164px')};
`;

const StyledWrapper = styled.span`
  margin-bottom: ${({ theme }) => theme.size.spacing.medium.value};
  ${({ screenSize }) =>
    screenSize === SCREEN_SIZES.SMALL &&
    css`
      margin-left: auto;
    `}
`;

const PageHeaderButtonOverflowMenu = ({
  closeMenuText,
  dataTestId,
  defaultOptions,
  options,
  screenSize,
  showMenuText,
  ...other
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => setIsOpen(false);
  const handleOpen = () => setIsOpen(true);
  const handleSelect = () => {
    setIsOpen(false);
  };

  const hasDefaultOptions = !!defaultOptions && defaultOptions.length > 0;
  const hasDynamicOptions = !!options && options.length > 0;
  const hasOptions = hasDefaultOptions || hasDynamicOptions;

  const getOptions = () => {
    const dynamicOptions = options.map((option) => ({ id: option.id, label: option.text, onClick: option.onClick }));

    if (!hasDynamicOptions && hasDefaultOptions) {
      return defaultOptions;
    }

    return hasDefaultOptions
      ? [
          { id: 'dynamic-options', label: 'dynamic', options: dynamicOptions },
          { id: 'default-options', label: 'default', options: defaultOptions },
        ]
      : dynamicOptions;
  };

  const overflowMenuButtonContent = (
    <SecondaryButton
      backgroundAppearance={BUTTON_BACKGROUND_APPEARANCES.PRIMARY}
      data-testid={dataTestId ? `${dataTestId}-button` : undefined}
      hideText
      icon={<IconMoreHoriz />}
      onClick={isOpen ? handleClose : handleOpen}
      text={isOpen ? closeMenuText : showMenuText}
    />
  );

  return (
    <StyledWrapper screenSize={screenSize}>
      {hasOptions && (
        <StyledContextMenu
          data-testid={dataTestId}
          hideGroupLabel
          isOpen={isOpen}
          onClose={handleClose}
          onOpen={handleOpen}
          onSelect={handleSelect}
          options={getOptions()}
          positionHorizontal={
            screenSize === SCREEN_SIZES.SMALL ? MENU_HORIZONTAL_POSITIONS.LEFT : MENU_HORIZONTAL_POSITIONS.RIGHT
          }
          positionVertical={MENU_VERTICAL_POSITIONS.TOP}
          wrappedComponentContent={overflowMenuButtonContent}
          {...other}
        />
      )}
    </StyledWrapper>
  );
};

PageHeaderButtonOverflowMenu.propTypes = {
  /** Text for screen reader when context menu is being closed  */
  closeMenuText: PropTypes.node,
  /** Id value used for testing */
  dataTestId: PropTypes.string,
  /** List options provided for context menu */
  defaultOptions: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string,
      icon: PropTypes.node,
      id: PropTypes.string,
      isDisabled: PropTypes.bool,
      isSelected: PropTypes.bool,
      label: PropTypes.node.isRequired,
      onClick: PropTypes.func,
    })
  ),
  /** Button props */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      /** Unique identifier for the button */
      id: PropTypes.string,
      /** Callback that is called on click */
      onClick: PropTypes.func,
      /** Will display text inside button */
      text: PropTypes.node.isRequired,
    })
  ).isRequired,
  /** Screen size for which the component has to render */
  screenSize: PropTypes.oneOf(Object.values(SCREEN_SIZES)),
  /** Text for screen reader when context menu is being opened  */
  showMenuText: PropTypes.node,
};

PageHeaderButtonOverflowMenu.defaultProps = {
  closeMenuText: 'Close Menu',
  dataTestId: undefined,
  defaultOptions: undefined,
  screenSize: SCREEN_SIZES.LARGE,
  showMenuText: 'Show Menu',
};

export { PageHeaderButtonOverflowMenu };
