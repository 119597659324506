import styled from 'styled-components';

const ShellLayout = styled.div`
  width: 100%;
`;

const ShellContainer = styled.div`
  /* stylelint-disable */
  -ms-grid-columns: auto 1fr;
  display: -ms-grid;
  /* stylelint-enable */
  display: grid;
  grid-template-areas:
    'nav header'
    'nav main'
    'nav footer';
  grid-template-columns: fit-content(100%);
  grid-template-rows: auto 1fr auto;
`;

const NavigationSection = styled.nav`
  grid-area: nav;
  /* stylelint-disable */
  -ms-grid-column: 1;
  -ms-grid-row: 1;
  -ms-grid-row-span: 3;
  /* stylelint-enable */
`;

const HeaderSection = styled.header`
  grid-area: header;
  /* stylelint-disable */
  -ms-grid-column: 2;
  -ms-grid-row: 1;
  /* stylelint-enable */
`;

const MainSection = styled.section.attrs(() => ({ role: 'main' }))`
  grid-area: main;
  /* stylelint-disable */
  -ms-grid-column: 2;
  -ms-grid-row: 2;
  /* stylelint-enable */
`;

const FooterSection = styled.footer`
  grid-area: footer;
  /* stylelint-disable */
  -ms-grid-column: 2;
  -ms-grid-row: 3;
  /* stylelint-enable */
`;

export { ShellContainer, ShellLayout, FooterSection, HeaderSection, MainSection, NavigationSection };
