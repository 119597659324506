import React from 'react';

import { IconCheck } from 'lib/icons';
import { ListItem } from 'lib/list';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { getStateStyles } from '../contextMenuItemStateStyles';
import {
  ContextMenuListItemCheckbox,
  ContextMenuListItemGraphic,
  ContextMenuListItemPrimaryText,
  ContextMenuListItemText,
} from '../elements';
import { focusOutlineColors, getRem } from './../../core';

const StyledListItem = styled(ListItem).withConfig({
  shouldForwardProp: (prop) => !['isIndeterminate', 'isKeyboardFocused', 'isMulti', 'isSelected'].includes(prop),
})`
  overflow: visible;
  ${({ isMulti, isSelected }) => css`
    ${!isMulti &&
    !isSelected &&
    css`
      padding-right: ${getRem(38)};
    `}
    ${isMulti ? getStateStyles(false).default : getStateStyles(isSelected).default};
  `};

  ${ContextMenuListItemCheckbox} {
    margin-left: ${({ isMulti }) => isMulti && getRem(2)};
    padding-right: 0;
  }

  ${ContextMenuListItemPrimaryText} {
    color: ${({ isDisabled, theme }) => isDisabled && theme.color.text.light.disabled.value};
  }

  ${({ isDisabled, isKeyboardFocused, isMulti, isSelected, theme }) =>
    !isDisabled &&
    css`
      cursor: pointer;
      &:hover {
        ${/* sc-selector */ ContextMenuListItemCheckbox}::after {
          background-color: ${isSelected
            ? theme.color.state.primary.hover.value
            : theme.color.state.default.hover.value};
        }

        ${!isMulti && getStateStyles(isSelected).hover}
      }

      &:focus {
        ${/* sc-selector */ ContextMenuListItemCheckbox}::after {
          background-color: ${isSelected
            ? theme.color.state.primary.focus.value
            : theme.color.state.default.focus.value};
          ${isKeyboardFocused &&
          css`
            box-shadow: 0 0 0 4px
              ${isSelected ? focusOutlineColors.getPrimary(theme) : focusOutlineColors.getDark(theme)};
          `}
        }
      }

      &:active {
        ${/* sc-selector */ ContextMenuListItemCheckbox}::after {
          background-color: ${isSelected
            ? theme.color.state.primary.pressed.value
            : theme.color.state.default.pressed.value};
          box-shadow: none;
        }

        ${!isMulti && getStateStyles(isSelected).active}
      }

      ${!isMulti && isKeyboardFocused && getStateStyles(isSelected).focus}
    `}
  ${({ isDisabled }) =>
    isDisabled &&
    css`
      cursor: not-allowed;
    `}
`;

const StyledIconCheck = styled(IconCheck)`
  ${({ theme }) => css`
    align-self: flex-start;
    fill: ${theme.color.primary[500].value};
    flex-shrink: 0;
    height: 100%;
    padding-left: ${theme.size.spacing.medium.value};
    width: ${getRem(18)};
  `}
`;

const ContextMenuListItem = React.forwardRef(
  ({ dataTestId, isKeyboardFocused, isMulti, isSelected, onClick, option, size, ...other }, ref) => (
    <StyledListItem
      data-testid={dataTestId}
      hasIcon={!!option.icon && !isMulti}
      hideStateVisibility={isMulti}
      href={isMulti ? null : option.href}
      id={option.id}
      isDisabled={option.isDisabled}
      isKeyboardFocused={isKeyboardFocused}
      isMulti={isMulti}
      isSelected={isMulti ? option.isSelected : isSelected}
      onClick={!option.isDisabled ? onClick : undefined}
      ref={ref}
      role="option"
      size={size}
      tabIndex={option.isDisabled ? -1 : 0}
      {...other}
    >
      {!isMulti && !!option.icon && <ContextMenuListItemGraphic>{option.icon}</ContextMenuListItemGraphic>}
      {isMulti && (
        <ContextMenuListItemCheckbox
          checked={!!option.isSelected}
          data-testid={dataTestId && `${dataTestId}-checkbox`}
          indeterminate={option.isIndeterminate}
          isDisabled={option.isDisabled}
          isKeyboardFocused={isKeyboardFocused}
          size={size}
        />
      )}
      <ContextMenuListItemText data-testid={dataTestId && `${dataTestId}-label`}>
        <ContextMenuListItemPrimaryText size={size}>{option.label}</ContextMenuListItemPrimaryText>
      </ContextMenuListItemText>
      {!isMulti && isSelected && <StyledIconCheck />}
    </StyledListItem>
  )
);

ContextMenuListItem.propTypes = {
  /** Id value used for testing */
  dataTestId: PropTypes.string,
  /** Applies keyboard focused styling if true */
  isKeyboardFocused: PropTypes.bool,
  /** Sets the Menu to select Multiple Options */
  isMulti: PropTypes.bool,
  /** Applies selected option styling if true (used in single select context menu) */
  isSelected: PropTypes.bool,
  /** Function call executed on option click */
  onClick: PropTypes.func.isRequired,
  /** Context menu option */
  option: PropTypes.shape({
    /** Specifies a location URL to redirect to */
    href: PropTypes.string,
    /** Will render specified icon */
    icon: PropTypes.node,
    /** Option id */
    id: PropTypes.string.isRequired,
    /** Applies disabled option styling if true */
    isDisabled: PropTypes.bool,
    /** Applies indeterminate state */
    isIndeterminate: PropTypes.bool,
    /** Applies selected option styling if true */
    isSelected: PropTypes.bool,
    /** Specifies the option label */
    label: PropTypes.node.isRequired,
  }).isRequired,
  /** Changes list item height */
  size: PropTypes.string.isRequired,
};

ContextMenuListItem.defaultProps = {
  dataTestId: undefined,
  isKeyboardFocused: false,
  isMulti: false,
  isSelected: false,
};

export { ContextMenuListItem };
