import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { getRem, typographyCaption, wordBreak } from './../../core';

const paddingSize = getRem('12px');

const StyledInputHelperText = styled.div`
  ${({ theme }) => typographyCaption(theme)}
  ${wordBreak}
    align-items: center;
  box-sizing: border-box;
  color: ${({ theme }) => theme.color.text.light.secondary.value};
  display: flex;
  margin-top: ${({ theme }) => theme.size.spacing.small.value};
  padding-left: ${paddingSize};
  padding-right: ${paddingSize};
`;

const StyledInputHelperTextContent = styled.span`
  width: 100%;
`;

// Component should be used with Input/TextArea fields
const InputHelperText = ({ id, isDisabled, text, ...other }) => (
  <StyledInputHelperText id={id} {...other}>
    <StyledInputHelperTextContent>{text}</StyledInputHelperTextContent>
  </StyledInputHelperText>
);

InputHelperText.propTypes = {
  /** Unique identificator. Necessary to have for linking with other components */
  id: PropTypes.string.isRequired,
  /** If true, adjusts label styling */
  isDisabled: PropTypes.bool,
  /** Displays provided text */
  text: PropTypes.node.isRequired,
};

InputHelperText.defaultProps = {
  isDisabled: false,
};

export { InputHelperText };
